import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {getTranslate, Translate} from "react-localize-redux";
import LazyImage from "../../components/LazyImage/LazyImage";
import {Link, withRouter} from "react-router-dom";
import Icofont from "../../components/Icofont/Icofont";
import ModelSearchActions from "../../stores/modelSearch/actions";
import './Favourites.styles.scss'
import ChatButton from "../../components/ChatButton/ChatButton";

class Favourites extends Component {

    constructor(props) {
        super(props);

        this.state = {
            models: [],
            listID: 0,
            showShare: false,
            email: '',
            emailError: '',
            emailErrorBool: true,
            message: '',
            messageToSend: '',
            loading: true,
            error: ''
        }

        this.fetchModels = this.fetchModels.bind(this)
        this.toggleModelFavourites = this.toggleModelFavourites.bind(this)
        this.shareList = this.shareList.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handleMessageChange = this.handleMessageChange.bind(this)
    }

    handleEmailChange(e) {
        let text = e.target.value;
        this.setState({email: text});
        let emailValidation = text.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
        if (emailValidation === null) {
            this.setState({emailError: <Translate id="error.emailError"/>, emailErrorBool: true});
        } else {
            this.setState({emailError: '', emailErrorBool: false});
        }
    }

    handleMessageChange(e) {
        let text = e.target.value;
        this.setState({
            message: text,
            messageToSend: encodeURIComponent(text.replace(/(?:\r\n|\r|\n)/g, '<br/>')),
        });

    }

    componentDidMount() {
        this.fetchModels()
    }

    shareList() {
        if (this.state.listID) {
            ApiService.authorizedClient(this.props.authKey).get(
                'TopModelList/' + this.state.listID + '/shareList?Email='+this.state.email+'&Message='+this.state.messageToSend,
            )
                .then((response) => {
                    this.setState({
                        showShare: false,
                        email: '',
                        emailError: '',
                        emailErrorBool: true,
                        message: '',
                        messageToSend: '',
                    });
                    toastr.success(this.props.translate("toastr.success"), this.props.translate("sharedList.success"))
                }).catch((error) => {
                let errorMsg = this.props.translate("error.unknown");
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                toastr.error(this.props.translate("toastr.error"), errorMsg)
            })
        }

    }

    fetchModels() {
        let d = new Date;
        ApiService.authorizedClient(this.props.authKey).get('Customer/' + this.props.memberID + '/getFavorites?t='+d.getTime())
            .then((response) => {
                if (typeof response.data !== "undefined") {
                    this.setState({
                        models: response.data.Models ?? [],
                        listID: response.data.ID,
                        error: '',
                        loading: false
                    })
                } else {
                    this.setState({
                        error: 'No MODEL DATA SHOULD NOT HAPPEN',
                        loading: false
                    })
                }
            }).catch((error) => {
            let errorMsg = this.props.translate("error.unknown");
            if (error.response && error.response.data && error.response.data.message) {
                errorMsg = error.response.data.message
            }
            toastr.error(this.props.translate("toastr.error"), errorMsg)
        })
    }

    toggleModelFavourites(modelID) {
        ApiService
            .authorizedClient(this.props.authKey)
            .post('/TopModelList/toggleModel/',
                {ModelID: modelID}
            )
            .then(r => {
                if (typeof r.data !== "undefined") {
                    let models = [];
                    let add = true;
                    this.props.models.map((model) => {
                        if (model.ID === modelID) {
                            model.Favourit = r.data.Favourit
                            add = r.data.Favourit
                        }
                        models.push(model);
                        return null;
                    })
                    if (add) {
                        toastr.success(this.props.translate("toastr.success"), this.props.translate("toastr.modelAdded"))
                    } else {
                        toastr.success(this.props.translate("toastr.success"), this.props.translate("toastr.modelRemoved"))
                    }
                    this.setState({loading: true}, () => {
                        this.fetchModels();
                        this.props.setModels(models)
                    });
                }
            })
            .catch(error => {
                let errorMsg = this.props.translate("error.unknown");
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                this.setState({
                    error: errorMsg,
                    loading: false
                });
            });
    }

    render() {
        return (
            <Container fluid className="py-4">
                <div className="text-center">
                    <h1 className="pb-4">
                        <Translate id="favourites.header"/>
                        <span className="share-icon" onClick={() => this.setState({showShare: true})}>
                            <Icofont icon="share"></Icofont>
                            <Translate id="sharedList.share"/>
                        </span></h1>
                </div>
                {this.state.showShare ?
                    <Row className="justify-content-center">
                        <Col lg={6} className="share-container my-5 py-3">
                            <div className="close-icon text-right mb-3">
                                <Icofont icon="close" onClick={() => this.setState({showShare: false})}></Icofont>
                            </div>
                            <Form.Group className='from-label-group'>
                                <Form.Label htmlFor='inputEmail'><Translate id="sharedList.email"/></Form.Label>
                                <Form.Control
                                    type="email"
                                    id="inputEmail"
                                    onChange={this.handleEmailChange}
                                    disabled={this.state.loading}
                                    value={this.state.email}
                                    onKeyDown={this.onKeyDownMain}
                                    className='input-foreground'
                                />
                                <p className='text-danger m-0'>{this.state.emailError}</p>
                            </Form.Group>
                            <Form.Group className='from-label-group no-border-textarea'>
                                <Form.Label htmlFor='inputDesc'><Translate id="sharedList.message"/></Form.Label>
                                <Form.Control as="textarea" rows="4"
                                              id="inputDesc"
                                              onChange={this.handleMessageChange}
                                              disabled={this.state.loading}
                                              value={this.state.desc}
                                              className='input-foreground'
                                />
                            </Form.Group>
                            <div className="text-right">
                                <Button
                                    onClick={this.shareList}
                                    disabled={this.state.emailErrorBool}
                                >
                                    <Translate id="sharedList.shareListe"/>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    :
                    null
                }

                <Row>
                    {!this.state.loading ?
                        this.state.models.length ?
                            this.state.models.map((model, index) => {
                                return (
                                    <Col key={model.ID} lg={6} className="mb-5 favourite-padding">
                                        <div className="model-favourit-card w-100">
                                            <Row className="m-lg-0">
                                                <Col xs={6} className="p-lg-0"><LazyImage src={model.PreviewImage}
                                                                                          fluid/></Col>
                                                <Col xs={6} className="p-lg-0">
                                                    <div
                                                        className="h-100 favourites-card-inner d-flex flex-column justify-content-between">
                                                        <div>
                                                            <p className="model-title m-0 mb-3">{model.FirstName}</p>
                                                            <small>{model.BookingCode}</small>
                                                            <p className="model-location m-0">
                                                                <b>{model.AddressTitle}</b></p>
                                                        </div>
                                                        <div
                                                            className="model-teaser-actions d-md-flex flex-wrap justify-content-between d-none">
                                                            <Link
                                                                className="model-action d-flex flex-column justify-content-center"
                                                                to={"/model/" + model.ID}
                                                            >
                                                                <div className="text-center"><Icofont icon="search-job"
                                                                /></div>
                                                                <div className="action-title">
                                                                    <Translate id="modelSearch.showProfile"/>
                                                                </div>
                                                            </Link>


                                                            <div
                                                                className="model-action d-flex flex-column justify-content-center"
                                                            >
                                                                <ChatButton
                                                                    modelID={model.ID}
                                                                    modelBookingCode={model.BookingCode}
                                                                    fallbackCall={() => this.props.history.push("/chats/new/" + model.BookingCode)}
                                                                >
                                                                    <div className="text-center"><Icofont
                                                                        icon="ui-text-chat"
                                                                    /></div>
                                                                    <div className="action-title">
                                                                        <Translate id="modelSearch.startChat"/>
                                                                    </div>
                                                                </ChatButton>
                                                            </div>
                                                            <div
                                                                className="model-action d-flex flex-column justify-content-center text-danger"
                                                                onClick={() => this.toggleModelFavourites(model.ID)}
                                                            >
                                                                <div className="text-center">
                                                                    <Icofont icon="ui-rate-remove"/></div>
                                                                <div className="action-title">
                                                                    <Translate id="modelSearch.remove"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={12} className="d-block d-md-none">
                                                    <div
                                                        className="model-teaser-actions d-flex flex-wrap justify-content-between py-3">
                                                        <Link
                                                            className="model-action d-flex flex-column justify-content-center"
                                                            to={"/model/" + model.ID}
                                                        >
                                                            <div className="text-center"><Icofont icon="search-job"
                                                            /></div>
                                                            <div className="action-title text-center">
                                                                <Translate id="modelSearch.showProfile"/>
                                                            </div>
                                                        </Link>


                                                        <div
                                                            className="model-action d-flex flex-column justify-content-center"
                                                        >
                                                            <ChatButton
                                                                modelID={model.ID}
                                                                modelBookingCode={model.BookingCode}
                                                                fallbackCall={() => this.props.history.push("/chats/new/" + model.BookingCode)}
                                                            >
                                                                <div className="text-center"><Icofont
                                                                    icon="ui-text-chat"
                                                                /></div>
                                                                <div className="action-title text-center">
                                                                    <Translate id="modelSearch.startChat"/>
                                                                </div>
                                                            </ChatButton>
                                                        </div>
                                                        <div
                                                            className="model-action d-flex flex-column justify-content-center text-danger"
                                                            onClick={() => this.toggleModelFavourites(model.ID)}
                                                        >
                                                            <div className="text-center">
                                                                <Icofont icon="ui-rate-remove"/></div>
                                                            <div className="action-title text-center">
                                                                <Translate id="modelSearch.remove"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                    </Col>
                                )
                            })
                            :
                            <Col xs={12}>
                                <p><Translate id="favourites.noModels"/></p>
                            </Col>
                        :
                        <Col xs={12}>
                            <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </Col>

                    }
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    authKey: state.user.authKey,
    user: state.user.user,
    memberID: state.user.memberID,
    models: state.modelSearch.models,
    translate: getTranslate(state.localize)
});
const mapDispatchToProps = (dispatch) => ({
    setModels: (models) => dispatch(ModelSearchActions.setModels(models)),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Favourites));
