import React, {Component} from "react";
import LazyImage from "../../components/LazyImage/LazyImage";

export default class ModelSwipeContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            translateX: 0
        }
    }

    componentDidMount() {
        this.updatetranslateX()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((JSON.stringify(prevProps.sizing) !== JSON.stringify(this.props.sizing)) || prevProps.position !== this.props.position) {
            this.updatetranslateX();
        }
    }

    updatetranslateX() {
        let translateX = (-1 * (this.props.sizing.modelImageSize + (this.props.sizing.modelImageSize - ((this.props.sizing.vw - this.props.sizing.modelImageSize) / 2))));

        translateX -= this.props.sizing.modelImageSize * this.props.position;

        this.setState({
            translateX: translateX
        })
    }

    render() {

        return (
            <div className="model-swipe-container">
                <div className="model-swipe-container-inner"
                     style={{
                         width: this.props.sizing.modelImageSize * this.props.models.length,
                         height: this.props.sizing.modelImageSize,
                         transform: "translateX(" + this.state.translateX + "px)"
                     }}>
                    {this.props.models.map((model, index) => (
                            <div className="model-img"
                                 key={index}
                                 style={{
                                     width: this.props.sizing.modelImageSize,
                                     height: this.props.sizing.modelImageSize
                                 }}>
                                {typeof model.PreviewImage !== "undefined" && ((index - this.props.position) <= 4 && (index - this.props.position) >= -3) ?
                                    <LazyImage
                                        src={model.PreviewImage}
                                        fluid
                                    />
                                    :
                                    null
                                }
                            </div>
                        )
                    )
                    }
                </div>
            </div>
        )
    }
}
