import React, {Component} from 'react';
import {Image, Spinner} from "react-bootstrap";
import {Translate} from "react-localize-redux";


export default class LazyImage extends Component {
    state = {
        loading: true
    }

    render() {
        return (
            <>
                {this.state.loading ?
                    <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                        <span className="sr-only"><Translate id="lazyImage.loading"/></span>
                    </Spinner>
                    : null
                }
                <Image
                    {...this.props}
                    className={this.state.loading ? 'd-none' : this.props.className ?? 'mx-auto d-block'}
                    onLoad={() => this.setState({loading: false})}
                />
            </>
        )
    }
}
