import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import LazyImage from "../../components/LazyImage/LazyImage";
import ErrorImage from "../../assets/img/error_404_topmodelcast-min.png"
import {Translate} from "react-localize-redux";

export default class NotFound extends Component {

    render() {
        return (
            <section className="section pt-5 pb-5">
                <Container fluid className="text-center">
                    <h2 className="text-primary"><Translate id="notFound.header"/></h2>
                    <Row className="justify-content-center">
                        <Col lg={4}>
                            <LazyImage src={ErrorImage} fluid className="d-block mx-auto"/>
                        </Col>
                    </Row>
                    <p style={{fontSize: "1.5rem"}}><Translate id="notFound.desc"/></p>
                    <Link to="/" style={{fontSize: "1.5rem"}}><Translate id="notFound.link"/></Link>
                </Container>
            </section>
        );
    }
}
