import React, {Component} from "react";
import {Alert, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import UploadField from "../../components/UploadField/UploadField";
import 'rsuite/dist/styles/rsuite-default.css'
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {withRouter} from "react-router-dom";
import {getTranslate, Translate} from "react-localize-redux";
import LocationField from "../../components/LocationField/LocationField";

class EditModelProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            mainImg: {},
            firstName: '',
            firstNameError: '',
            surname: '',
            surnameError: '',
            company: '',
            companyError: '',
            companyWeb: '',
            companyWebError: '',
            companyAddress: {},
            companyAddressID: 0,
            companyAddressError: '',
            addressTitle: '',

        }

        this.fetchScoutData = this.fetchScoutData.bind(this);
        this.handleMainImgStatusChange = this.handleMainImgStatusChange.bind(this);
        this.handleMainImgDelete = this.handleMainImgDelete.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleSurnameChange = this.handleSurnameChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleCompanyWebChange = this.handleCompanyWebChange.bind(this);
        this.handleCompanyAddressChange = this.handleCompanyAddressChange.bind(this);
        this.saveAddress = this.saveAddress.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {
        if (this.props.memberID) {
            this.fetchScoutData()
        } else {
            this.props.history.push('/');
        }

    }

    fetchScoutData() {
        ApiService.authorizedClient(this.props.authKey).get('Customer/' + this.props.memberID)
            .then((response) => {
                this.setState({
                    mainImg: response.data.MainImageImage ?? {},
                    firstName: response.data.FirstName ?? '',
                    surname: response.data.Surname ?? '',
                    company: response.data.Company ?? '',
                    companyWeb: response.data.CompanyWebsite ?? '',
                    addressTitle: response.data.FullAddressTitle,
                    companyAddress: response.data.Address,
                    companyAddressID: response.data.AddressID,
                    loading: false
                })

            }).catch((error) => {
            let errorMsg = this.props.translate("error.unknown");
            if (error.response && error.response.data && error.response.data.message) {
                errorMsg = error.response.data.message
            }
            toastr.error(this.props.translate('toastr.error'), errorMsg)
        })
    }


    saveMainImage() {
        if (typeof this.state.mainImg.ID !== 'undefined') {
            ApiService.authorizedClient(this.props.authKey).put(
                'Customer/' + this.props.memberID + '/saveMainImage/',
                {ImageID: this.state.mainImg.ID}
            ).then(
                () => {
                    toastr.success(this.props.translate('toastr.success'), this.props.translate('toastr.imageSaved'))
                    this.fetchScoutData();
                },
                (error) => {
                    let errorMsg = this.props.translate("error.unknown");
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error(this.props.translate('toastr.error'), errorMsg)
                }
            );
        }
    }

    deleteMainImage() {
        ApiService.authorizedClient(this.props.authKey).put(
            'Customer/' + this.props.memberID + '/saveMainImage/',
            {ImageID: 'DELETE'}
        ).then(
            () => {
                toastr.success(this.props.translate('toastr.success'), this.props.translate('toastr.imageDeleted'))
            },
            (error) => {
                let errorMsg = this.props.translate("error.unknown");
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                toastr.error(this.props.translate('toastr.error'), errorMsg)
            }
        );
    }

    handleMainImgStatusChange = (currentFiles) => {
        this.setState({mainImg: currentFiles[0]}, () => this.saveMainImage());
    }

    handleMainImgDelete(file, currentFiles) {
        this.setState({mainImg: {}}, () => this.deleteMainImage());
    }


    handleFirstNameChange(e) {
        let text = e.target.value;
        this.setState({firstName: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({firstNameError: <Translate id="error.wrongFirstName"/>, disableButton: true,});
        } else {
            this.setState({firstNameError: ''});
        }
    }

    handleSurnameChange(e) {
        let text = e.target.value;
        this.setState({surname: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({surnameError: <Translate id="error.wrongSurname"/>, disableButton: true,});
        } else {
            this.setState({surnameError: ''});
        }
    }

    handleCompanyChange(e) {
        this.setState({company: e.target.value});
    }

    handleCompanyWebChange(e) {
        this.setState({companyWeb: e.target.value});
    }

    handleCompanyAddressChange(address) {
        let addressError = "";
        if (
            address.City === "" ||
            address.Street === "" ||
            address.Country === "" ||
            address.PostalCode === "" ||
            address.Number === ""
        ) {
            addressError = <Translate id="model.addressError"/>
        }
        this.setState({
            companyAddress: address,
            companyAddressError: addressError
        })
    }

    saveAddress() {
        let data = this.state.companyAddress;
        data.MemberID = this.props.memberID;
        if (this.state.companyAddressID) {
            ApiService.authorizedClient(this.props.authKey).put(
                'Address/' + this.state.companyAddressID,
                data
            ).then(
                (response) => {
                },
                (error) => {
                    let errorMsg = this.props.translate("error.unknown");
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message;
                    }
                    toastr.error(this.props.translate("toastr.error"), errorMsg)
                }
            );
        }
    }


    handleSubmit() {
        this.setState({error: ''});
        if (
            this.state.firstNameError.length > 0 ||
            this.state.surnameError.length > 0 ||
            this.state.companyError
        ) {
            this.setState({error: <Translate id="error.checkInput"/>});
            return;
        }
        this.saveAddress();
        ApiService.authorizedClient(this.props.authKey).put(
            'Customer/' + this.props.memberID,
            {
                FirstName: this.state.firstName,
                Surname: this.state.surname,
                Company: this.state.company,
                CompanyWebsite: this.state.companyWeb,
            }
        ).then(
            () => {
                toastr.success(this.props.translate('toastr.success'), this.props.translate('toastr.dataSaved'))
            },
            (error) => {
                let errorMsg = this.props.translate("error.unknown");
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                toastr.error(this.props.translate('toastr.error'), errorMsg)
            }
        );
    }


    render() {
        return (

            <Container>
                <section className="section py-4">
                    <div className="text-center">
                        <h1 className="pb-4"><Translate id="scout.header"/></h1>
                    </div>
                    <Row>
                        {this.state.loading ?
                            <Spinner animation="border" role="status"
                                     className='mx-auto d-block align-self-center'>
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            :
                            <Form className="col-12">
                                <h4 className='mt-0 mb-4'>
                                    <Translate id="scout.profilePicture"/>
                                </h4>
                                <Form.Group>
                                    {typeof this.state.mainImg !== 'undefined' && this.state.mainImg.ID ?
                                        <UploadField
                                            onChange={this.handleMainImgStatusChange}
                                            inputContent='Ziehe dein Hauptbild hierher oder klicke zum Durchsuchen'
                                            maxFiles={1}
                                            onDelete={this.handleMainImgDelete}
                                            files={[this.state.mainImg]}
                                            alloweEdit={true}
                                        />
                                        :
                                        <UploadField
                                            onChange={this.handleMainImgStatusChange}
                                            onDelete={this.handleMainImgDelete}
                                            inputContent='Ziehe dein Hauptbild hierher oder klicke zum Durchsuchen'
                                            maxFiles={1}
                                        />
                                    }
                                </Form.Group>
                                <div className="border-bottom my-5"/>
                                <h4 className="mb-4">
                                    <Translate id="scout.myData"/>
                                </h4>
                                <Form.Group className='from-label-group'>
                                    <Form.Label htmlFor='inputLastName'><Translate id="scout.company"/></Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="inputLastName"
                                        onChange={this.handleCompanyChange}
                                        disabled={this.state.loading}
                                        value={this.state.company}
                                        className='input-foreground'
                                    />
                                    <p className='text-danger m-0'>{this.state.companyError}</p>
                                </Form.Group>
                                <Form.Group className='from-label-group'>
                                    <Form.Label htmlFor='inputFirstName'><Translate id="scout.firstName"/></Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="inputFirstName"
                                        onChange={this.handleFirstNameChange}
                                        disabled={this.state.loading}
                                        value={this.state.firstName}
                                        className='input-foreground'
                                    />
                                    <p className='text-danger m-0'>{this.state.firstNameError}</p>
                                </Form.Group>
                                <Form.Group className='from-label-group'>
                                    <Form.Label htmlFor='inputLastName'><Translate id="scout.surname"/></Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="inputLastName"
                                        onChange={this.handleSurnameChange}
                                        disabled={this.state.loading}
                                        value={this.state.surname}
                                        className='input-foreground'
                                    />
                                    <p className='text-danger m-0'>{this.state.surnameError}</p>
                                </Form.Group>
                                <Form.Group className='from-label-group'>
                                    <Form.Label htmlFor='inputCompanyWebsite'><Translate
                                        id="register.companyWebsite"/>*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="inputCompanyWebsite"
                                        onChange={this.handleCompanyWebChange}
                                        disabled={this.state.loading}
                                        value={this.state.companyWeb}
                                        className='input-foreground'
                                    />
                                    <p className='text-danger m-0'>{this.state.companyError}</p>
                                </Form.Group>
                                <Form.Group className={'from-label-group ' + this.state.addressShowRequired}>
                                    <Form.Label htmlFor='inputLocationColor'><Translate
                                        id="register.companyAddress"/>*</Form.Label>
                                    <LocationField
                                        setAddress={address => this.handleCompanyAddressChange(address)}
                                        address={this.state.addressTitle}
                                    />
                                    {!this.state.companyAddressError ?
                                        <p className='text-muted m-0'><Translate id="model.addressError"/></p>
                                        :
                                        <p className='text-danger m-0'>{this.state.companyAddressError}</p>
                                    }

                                </Form.Group>
                                {this.state.error ?
                                    <Alert variant="danger">{this.state.error}</Alert>
                                    : null
                                }
                                <Row>
                                    <Col xs={12} lg={12} className="text-center">
                                        <Button
                                            className='w-100'
                                            onClick={this.handleSubmit}
                                        >
                                            <Translate id="scout.save"/>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        }
                    </Row>
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    user: state.user.user,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    translate: getTranslate(state.localize)
});
const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EditModelProfile));
