import React, {Component} from "react";
import {ApiService} from "../../services/ApiService";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import "./ContactTreeItem.style.scss"
import {HTMLService} from "../../services/HTMLService";
import {getTranslate, Translate} from "react-localize-redux";
import {toastr} from "react-redux-toastr";
import {AnimatePresence, motion} from "framer-motion";
import Icofont from "../Icofont/Icofont";
import {connect} from "react-redux";

class ContactTreeItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            level: this.props.level ?? 0,
            showChildren: false,
            showContent: false,
            activeItemID: 0,
            activeItem: {},
            items: [],
            bookingCode: "",
            name: "",
            email: "",
            phone: "",
            message: "",
            formError: "",
            phoneformError: "",
            locale: 'en_US'
        }

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleBookingCodeChange = this.handleBookingCodeChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePhoneSubmit = this.handlePhoneSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({locale:this.props.locale})
        let name = '';
        if (this.props.user) {
            if (typeof this.props.user.FirstName !== "undefined") {
                name += this.props.user.FirstName + ' '
            }
            if (typeof this.props.user.Surname !== "undefined") {
                name += this.props.user.Surname;
            }
            this.setState({
                name: name,
                bookingCode: this.props.user.BookingCode ?? '',
                email: this.props.user.Email ?? '',
            })
        }
    }

    fetchContactItems(holderId, item, level) {
        ApiService.clientLocalized(this.state.locale).get(
            'ContactTreeItem',
            {
                params: {
                    "filter[HolderID]": holderId,
                    "locale": this.state.locale,
                }
            }
        )
            .then(
                (response) => {
                    let showContent = false;
                    if (!response.data.length) {
                        showContent = true;
                    }
                    this.setState({
                        items: response.data,
                        activeItemID: holderId,
                        activeItem: item,
                        showChildren: true,
                        showContent: showContent,
                    }, () => {
                        let id = "contact-tree-item-" + holderId;
                        let offset = 80;
                        let useParenNode = true;
                        if (window.innerWidth < 991) {
                            id = "contact-tree-item-content-mobile-" + level
                            useParenNode = false;
                        } else if (document.getElementById("contact-tree-item-content-" + level)) {
                            id = "contact-tree-item-content-" + level
                            useParenNode = false;
                        }
                        if (
                            useParenNode &&
                            document.getElementById(id) &&
                            document.getElementById(id).parentNode &&
                            (document.getElementById(id).parentNode.offsetTop)
                        ) {
                            document.documentElement.scrollTop = document.getElementById(id).parentNode.offsetTop + offset;
                        } else if (document.getElementById(id) && document.getElementById(id).offsetTop) {
                            document.documentElement.scrollTop = document.getElementById(id).offsetTop + offset;
                        } else {
                            document.documentElement.scrollTop = document.documentElement.scrollHeight;
                        }

                    })
                    ApiService.client.put('ContactTreeItem/' + holderId + '/addCounter').catch(err => console.log(err))
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.locale !== this.props.locale){
            this.setState({locale:this.props.locale})
        }
        if (JSON.stringify(prevProps.items) !== JSON.stringify(this.props.items)) {
            this.setState({
                items: [],
                showChildren: false,
                showContent: false,
                activeItemID: 0,
                activeItem: {},
            })
        }
    }

    handleNameChange(e) {
        this.setState({
            name: e.target.value
        })
    }

    handleBookingCodeChange(e) {
        this.setState({
            bookingCode: e.target.value
        })
    }

    handlePhoneChange(e) {
        this.setState({
            phone: e.target.value
        })
    }

    handleEmailChange(e) {
        this.setState({
            email: e.target.value
        })
    }

    handleMessageChange(e) {
        this.setState({
            message: e.target.value
        })
    }

    handleSubmit() {
        if (
            this.state.name.length <= 0 ||
            this.state.email.length <= 0 ||
            this.state.message.length <= 0
        ) {
            this.setState({formError: <Translate id="error.enterAllFields"/>});
            return;
        }
        ApiService.clientLocalized(this.state.locale).post(
            'ContactTreeSubmission/create',
            {
                Name: this.state.name,
                Email: this.state.email,
                Message: this.state.message,
                ContactTreeItemID: this.state.activeItem.ID,
                MemberID: this.props.memberID ?? 0,
                Locale: this.props.locale ?? '',
                Phone: this.state.phone ?? '',
                BookingCode: this.state.bookingCode ?? '',
            }
        ).then((result) => {
            let name = '';
            if (typeof this.props.user.FirstName !== "undefined") {
                name += this.props.user.FirstName + ' '
            }
            if (typeof this.props.user.Surname !== "undefined") {
                name += this.props.user.Surname;
            }
            this.setState({
                name: name,
                email: this.props.user.Email ?? '',
                message: '',
                bookingCode: this.props.user.BookingCode ?? '',
            })
            toastr.success(this.props.translate('toastr.success'), this.props.translate('toastr.messageSend'))
        }).catch(err => console.log(err))
    }

    handlePhoneSubmit() {
        if (
            this.state.phone.length <= 0
        ) {
            this.setState({phoneformError: <Translate id="error.enterAllFields"/>});
            return;
        }
        ApiService.clientLocalized(this.state.locale).post(
            'ContactTreeSubmission/create',
            {
                ContactTreeItemID: this.state.activeItem.ID,
                MemberID: this.props.memberID ?? 0,
                Locale: this.props.locale ?? '',
                Phone: this.state.phone ?? '',
            }
        ).then((result) => {
            this.setState({
                phone: ''
            })
            toastr.success(this.props.translate('toastr.success'), this.props.translate('toastr.messageSend'))
        }).catch(err => console.log(err))
    }


    render() {
        return (
            <>
                <Row className={"justify-content-center py-3 contact-tree-row " + this.props.extraClass}>
                    {this.props.items.map((item, index) => {
                        return (
                            <div
                                className="col-lg-3 my-2"
                                key={index}
                            >
                                <div
                                    id={"contact-tree-item-" + item.ID}
                                    className={
                                        "contact-item h-100 d-flex flex-column align-items-center justify-content-center "
                                        + (this.state.activeItemID ? (this.state.activeItemID == item.ID ? "active" : "inactive") : '')
                                    }
                                    onClick={() => this.fetchContactItems(item.ID, item, this.state.level)}>
                                    <div className="contact-item-header">
                                        {item.Title}
                                        {item.Description ?
                                            <div className="contact-item-desc">
                                                {item.Description}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Row>
                <div id={"contact-tree-item-content-mobile-" + this.state.level}/>
                {this.state.showContent ?
                    <Container>
                        <Row className={"contact-tree-row pb-3 " + this.props.extraClass}
                             id={"contact-tree-item-content-" + this.state.level}>
                            <div className="col-12">
                                <div className=" contact-item-content">
                                    <div className="content">
                                        <h3 className="text-center text-primary">{this.state.activeItem.Title}</h3>
                                        {this.state.activeItem.Description ?
                                            <p className="text-center"><small><b>{this.state.activeItem.Description}</b></small>
                                            </p>
                                            :
                                            null
                                        }
                                        <div

                                            dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.activeItem.FAQContent)}}
                                        />
                                    </div>

                                    {this.state.activeItem.ShowContactForm ?
                                        <div className="contact-form">
                                            <div
                                                className="header d-flex flex-column justify-content-center align-center">
                                                <Icofont icon="envelope"/>
                                                <h4><Translate id="contactTree.supportHeader"/></h4>
                                                <p><Translate id="contactTree.supportDesc2"/></p>
                                                <p><Translate id="contactTree.supportDesc"/></p>
                                            </div>
                                            <Form>
                                                <div className="form-group">
                                                    <Form.Control
                                                        type="text"
                                                        id="inputName"
                                                        placeholder={this.props.translate("contactTree.name")}
                                                        onChange={this.handleNameChange}
                                                        value={this.state.name}
                                                        className='input-foreground'
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <Form.Control
                                                        type="text"
                                                        id="inputEmail"
                                                        placeholder={this.props.translate("contactTree.email")}
                                                        onChange={this.handleEmailChange}
                                                        value={this.state.email}
                                                        className='input-foreground'
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <Form.Control
                                                        type="text"
                                                        id="inputBookingCode"
                                                        placeholder={this.props.translate("contactTree.bookingCode")}
                                                        onChange={this.handleBookingCodeChange}
                                                        value={this.state.bookingCode}
                                                        className='input-foreground'
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <Form.Control as="textarea" rows="4"
                                                                  id="inputMessage"
                                                                  placeholder={this.props.translate("contactTree.message")}
                                                                  onChange={this.handleMessageChange}
                                                                  value={this.state.message}
                                                                  className='input-foreground'
                                                    />
                                                </div>
                                                {this.state.formError ?
                                                    <Alert variant="danger">
                                                        {this.state.formError}
                                                    </Alert>
                                                    :
                                                    null
                                                }
                                                <div className="d-flex justify-content-center">
                                                    <Button variant="secondary" onClick={this.handleSubmit}
                                                            disabled={this.state.loading}>
                                                        <Translate id="contactTree.submitForm"/>
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>

                                        :
                                        null
                                    }
                                    {this.state.activeItem.ShowPhone && this.state.activeItem.Phone ?
                                        <div className="contact-form text-center">
                                            <div
                                                className="header d-flex flex-column justify-content-center align-center">
                                                <Icofont icon="live-support"/>
                                                <h4><Translate id="contactTree.phoneHeader"/></h4>
                                                {/*<p><Translate id="contactTree.phoneDesc"/></p>*/}
                                            </div>
                                            <Row className="justify-content-center">
                                                <Col lg={6}>
                                                    <Form>
                                                        <div className="form-group">
                                                            <Form.Control
                                                                type="text"
                                                                id="inputPhone"
                                                                placeholder={this.props.translate("contactTree.phone")}
                                                                onChange={this.handlePhoneChange}
                                                                value={this.state.phone}
                                                                className='input-foreground'
                                                            />
                                                        </div>
                                                        {this.state.phoneformError ?
                                                            <Alert variant="danger">
                                                                {this.state.phoneformError}
                                                            </Alert>
                                                            :
                                                            null
                                                        }
                                                        <div className="d-flex justify-content-center">
                                                            <Button variant="secondary" onClick={this.handlePhoneSubmit}
                                                                    disabled={this.state.loading}>
                                                                <Translate id="contactTree.submitPhoneForm"/>
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Col>
                                            </Row>

                                            {/*<div className="callInfo">*/}
                                            {/*    <div className="number">{this.state.activeItem.Phone}</div>*/}
                                            {/*    <div className="supportHours">*/}
                                            {/*        <Translate id="contactTree.phoneSupportHours"/>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<a className="btn btn-secondary"*/}
                                            {/*   href={"tel:" + this.state.activeItem.Phone}>*/}
                                            {/*    <Translate id="contactTree.callNow"/>*/}
                                            {/*</a>*/}
                                        </div>
                                        :
                                        null
                                    }
                                </div>

                            </div>
                        </Row>
                    </Container>
                    :
                    null
                }
                {this.state.showChildren ?
                    this.state.items.length ?
                        <AnimatePresence>
                            <motion.div
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                                transition={{duration: 0.4}}
                            >
                                <ContactTreeItem
                                    {...this.props}
                                    items={this.state.items}
                                    extraClass={this.props.extraClass === 'odd' ? 'even' : 'odd'}
                                    user={this.props.user}
                                    memberID={this.props.memberID}
                                    level={this.state.level + 1}
                                />
                            </motion.div>

                        </AnimatePresence>

                        :
                        null

                    :
                    null
                }

            </>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    memberID: state.user.memberID,
    user: state.user.user,
    locale: state.locale.locale,
    translate: getTranslate(state.localize)
});


export default connect(
    mapStateToProps,
    null,
)(ContactTreeItem);
