import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";
import Login from "../../components/Login/Login";
import UserActions from '../../stores/user/actions';
import {Translate} from "react-localize-redux";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import ModelDashboard from "../../components/ModelDashboard/ModelDashboard";
import CustomerDashboard from "../../components/CustomerDashboard/CustomerDashboard";
import queryString from 'query-string';

import Logo from "../../assets/img/logo_navi_topmodelcast_worldwide-min.png";
import LazyImage from "../../components/LazyImage/LazyImage";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRegisterButton: false,
        }
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search)
        if (typeof params.registration !== 'undefined' && parseInt(params.registration) === 1) {
            this.setState({showRegisterButton: true})
        }
    }

    render() {
        return (
            <section className="section pt-5 pb-5">
                <Container>
                    {this.props.isLoggedIn ?
                        <>
                            {this.props.user.AdminPermission ?
                                <AdminDashboard/>
                                :
                                <>
                                    {this.props.user.ClassName === "CIC\\TopModelCast\\TopModel\\TopModel" ?
                                        <ModelDashboard/>
                                        :
                                        null
                                    }
                                    {this.props.user.ClassName === "CIC\\TopModelCast\\Customer\\Customer" ?
                                        <CustomerDashboard/>
                                        :
                                        null
                                    }
                                </>
                            }
                        </>
                        :
                        <div className="full-height-center d-flex align-items-center">
                            <AnimatePresence exitBeforeEnter={true}>
                                {!this.state.showRegisterButton ?
                                    <motion.div
                                        key={1}
                                        initial={{height: 0}}
                                        animate={{height: 'auto'}}
                                        exit={{height: 0}}
                                        transition={{duration: 0.5}}
                                        className='w-100 overflow-hidden'
                                    >
                                        <Row>
                                            <Col xs={12}>
                                                <Login/>
                                            </Col>
                                            <Col xs={12} className="text-center mt-5 ">
                                                <p>
                                                    <Translate id="register.not-a-member"/>&nbsp;
                                                    <Button
                                                        variant="link"
                                                        onClick={() => this.setState({showRegisterButton: !this.state.showRegisterButton})}
                                                    >
                                                        <Translate id="register.register-now"/>
                                                    </Button>
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="justify-content-center py-5">
                                            <Col xs={12} lg={7}>
                                                <a href={process.env.REACT_APP_BASE_URL} target="_blank">
                                                    <LazyImage src={Logo} fluid/>
                                                    <p className="text-center pt-5" style={{color: '#000'}}>
                                                        <Translate id="home.backToLandingPage"/>
                                                    </p>
                                                </a>
                                            </Col>
                                        </Row>


                                    </motion.div>
                                    :
                                    <motion.div
                                        key={2}
                                        initial={{height: 0}}
                                        animate={{height: 'auto'}}
                                        exit={{height: 0}}
                                        transition={{duration: 0.5}}
                                        className='w-100 overflow-hidden'
                                    >
                                        <Row>
                                            <Col xs={12} className="text-center">
                                                <h1 className="pb-4"><Translate id="home.registerHeader"/></h1>
                                                <p className="pb-4">
                                                    <Translate
                                                        id="home.registerIntro"
                                                        options={{renderInnerHtml: true}}
                                                    />
                                                </p>
                                            </Col>
                                            <Col xs={12} className="d-flex justify-content-center">
                                                <Button as={Link} to='register-model'
                                                        className='w-100 mb-3'>
                                                    <Translate id="home.registerAsModel"/>
                                                </Button>
                                            </Col>
                                            <Col xs={12} className="d-flex justify-content-center">
                                                <Button as={Link} to='register-scout' className='w-100'>
                                                    <Translate id="home.registerAsScout"/>
                                                </Button>
                                            </Col>
                                            <Col xs={12} className="text-center mt-5">
                                                <p>
                                                    <Button
                                                        variant="link"
                                                        onClick={() => this.setState({showRegisterButton: !this.state.showRegisterButton})}
                                                    >
                                                        <Translate id="home.back"/>
                                                    </Button>
                                                </p>
                                            </Col>
                                        </Row>
                                    </motion.div>
                                }
                            </AnimatePresence>
                        </div>

                    }
                </Container>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    user: state.user.user,
});
const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(UserActions.logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Home));
