import React, {Component} from "react";
import {UnitConvertService} from "../../services/UnitConvertionService";

export default class DisplayModelDataField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ?? '',
            gender: this.props.gender ?? 'male',
        }

        this.convertToImperial = this.convertToImperial.bind(this)
    }

    componentDidMount() {
        if (this.props.unit && this.props.value) {
            let newVal = this.props.value;
            if(this.props.unitType === "Shoe"){
                newVal = UnitConvertService.convertShoeSize(this.props.value,"EU",this.props.unit,this.state.gender)
            }else{
                newVal = this.convertToImperial(this.props.value, this.props.unit);
            }
            this.setState({
                value: newVal
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.unit !== this.props.unit) {
            let newVal = this.props.value
            if(this.props.unitType === "Shoe"){
                newVal = UnitConvertService.convertShoeSize(this.state.value,prevProps.unit,this.props.unit,this.state.gender)
            }else{
                newVal = this.convertToImperial(this.props.value, this.props.unit);
            }
            this.setState({
                value: newVal
            })
        }
    }

    convertToImperial(val, convertToType) {
        if (convertToType === 'inch') {
            return (val / 2.54).toFixed(2);
        }
        if (convertToType === 'lbs') {
            return (val * 2.205).toFixed(2);
        }

        if (convertToType === 'mi') {
            return (val / 1.60934).toFixed(2);
        }
        return val;
    }

    render() {
        return (
            <div className="model-data-field">{this.state.value} {this.props.unit}</div>
        )
    }
}
