import React, {Component} from "react";
// RCE CSS
import 'react-chat-elements/dist/main.css';
// MessageBox component
import {MessageList} from 'react-chat-elements';
import './ChatsWindow.style.scss'
import {Container, Form, Spinner} from "react-bootstrap";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import {Translate} from "react-localize-redux";
import CustomChatItem from "../../components/Chat/CustomChatItem";
import Icofont from "../../components/Icofont/Icofont";
import {toastr} from "react-redux-toastr";
import {CheckPicker} from "rsuite";
import {HTMLService} from "../../services/HTMLService";

class ChatWindow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            multiModelMode: false,
            chat: {},
            message: '',
            model: {},
            favourites: [],
            receiverID: 0,
            messages: [],
            progress: 100,
        }

        this.fetchMessages = this.fetchMessages.bind(this)
        this.checkIfChatsExists = this.checkIfChatsExists.bind(this)
        this.fetchModel = this.fetchModel.bind(this)
        this.handleMessageChange = this.handleMessageChange.bind(this)
        this.sendMessage = this.sendMessage.bind(this)
        this.fetchFavouritesModels = this.fetchFavouritesModels.bind(this)
        this.handleModelSelect = this.handleModelSelect.bind(this)

    }

    componentDidMount() {
        let id = this.props.match.params.id;
        let code = this.props.match.params.bookingCode;
        if (id !== "new") {
            this.fetchChat()
            this.chatinterval = setInterval(() => {
                this.fetchChat()
                this.setState({progress: 110})
            }, 10000)
            this.loadingInterval = setInterval(() => {
                this.setState({progress: this.state.progress - 10})
            }, 1000)
        } else {
            if (typeof code === "undefined") {
                this.setState({multiModelMode: true}, () => this.fetchFavouritesModels())
            }

        }

        if (typeof code !== "undefined") {
            this.fetchModel()
        }

    }

    componentWillUnmount() {
        clearInterval(this.chatinterval)
        clearInterval(this.loadingInterval)
    }

    fetchChat() {
        let id = this.props.match.params.id;
        ApiService.authorizedClient(this.props.authKey)
            .get("Chat/?filter[Hash]=" + id)
            .then((response) => {
                if (typeof response.data[0] !== "undefined") {
                    this.setState({
                        chat: response.data[0],
                    }, () => this.fetchMessages())
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    scrollToBottom() {
        const scrollHeight = this.chatwindow.scrollHeight;
        const height = this.chatwindow.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.chatwindow.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    fetchMessages() {
        let id = this.props.match.params.id;
        ApiService.authorizedClient(this.props.authKey)
            .get("Chat/history/" + id)
            .then((response) => {

                if (typeof response.data !== "undefined") {
                    let messages = [];
                    response.data.map((message) => {
                        message.date = new Date(message.date);
                        messages.push(message)
                    })
                    this.setState({
                        loading: false,
                        messages: messages
                    }, () => {
                        if(this.state.chat.Blocked ){
                            clearInterval(this.chatinterval)
                            clearInterval(this.loadingInterval)
                            this.setState({progress: 100})
                        }
                        this.scrollToBottom()
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    fetchModel() {
        let code = this.props.match.params.bookingCode;
        ApiService.authorizedClient(this.props.authKey)
            .get("TopModel/?filter[BookingCode]=" + code)
            .then((response) => {
                if (typeof response.data[0] !== "undefined") {
                    this.setState({
                        loading: false,
                        model: response.data[0],
                        receiverID: response.data[0].ID
                    })
                    this.checkIfChatsExists(response.data[0].ID, this.props.memberID);
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    checkIfChatsExists(modelID, customerID) {
        ApiService.authorizedClient(this.props.authKey).get('/Chat', {
            params: {
                "filter[ModelID]": modelID,
                "filter[CustomerID]": customerID,
            }
        }).then((response) => {
            if (typeof response.data[0] !== "undefined") {
                this.props.history.push('/chats/' + response.data[0].Hash)
            }

        }).catch((error) => {
            console.log(error)
        })
    }

    handleMessageChange(e) {
        let text = e.target.value;
        this.setState({message: text});
    }

    sendMessage() {
        let id = this.props.match.params.id;
        if (id === "new" && !this.state.receiverID) {
            return false;
        }
        if (this.state.message.length) {
            ApiService.authorizedClient(this.props.authKey).post(
                'Message/new',
                {
                    ChatHash: id,
                    Message: this.state.message,
                    SenderID: this.props.memberID,
                    ReceiverID: this.state.receiverID
                }
            )
                .then((response) => {
                    if (id === "new") {
                        this.props.history.push('/chats/')
                    } else {
                        this.setState({message: ''}, () => this.fetchMessages())
                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    fetchFavouritesModels() {
        ApiService.authorizedClient(this.props.authKey).get('Customer/' + this.props.memberID + '/getFavorites')
            .then((response) => {
                if (typeof response.data !== "undefined" && typeof response.data.Models !== "undefined") {
                    this.setState({
                        favourites: response.data.Models,
                        error: '',
                        loading: false
                    })
                } else {
                    this.setState({
                        error: 'No MODEL DATA SHOULD NOT HAPPEN',
                        loading: false
                    })
                }
            }).catch((error) => {
            let errorMsg = this.props.translate("error.unknown");
            if (error.response && error.response.data && error.response.data.message) {
                errorMsg = error.response.data.message
            }
            toastr.error(this.props.translate('toastr.error'), errorMsg)
        })
    }

    handleModelSelect(values, arg2, arg3) {
        let recivers = values.join(';');
        this.setState({
            receiverID: recivers,
        })
    }

    render() {
        return (
            <Container>
                <section className="py-4">
                    <div className="text-center">
                        <h1 className="pb-4"><Translate id="chatwindow.header"/></h1>
                    </div>
                    {this.state.loading ?
                        <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                        :
                        null
                    }
                    {typeof this.state.chat.ID !== "undefined" ?
                        <>
                            <div>
                                {this.state.chat.Reported ?
                                    <div className="text-center pb-3">
                                        <b><Translate id="chat.reportedFull"/></b>
                                    </div>
                                    :
                                    null
                                }
                                {this.state.chat.Blocked ?
                                    <div className="text-center pb-3">
                                        <b><Translate id="chat.blockedFull"/></b>
                                    </div>
                                    :
                                    null
                                }
                            </div>

                            <CustomChatItem
                                key={this.state.chat.Hash}
                                chatID={this.state.chat.ID}
                                avatar={this.state.chat.Avatar}
                                alt={this.state.chat.ChatTitle}
                                title={<>
                                    <span className="model-name">{this.state.chat.ChatTitle}</span>
                                    {this.state.chat.ChatTitleSecondLine ? <span className="model-name-subtext text-muted" dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.chat.ChatTitleSecondLine)}}></span> : ''}
                                    <small>{this.state.chat.ChatSubtitle}</small></>}
                                chatWindow={true}
                                reported={this.state.chat.Reported}
                                blocked={this.state.chat.Blocked}
                                onClick={() => this.props.history.push('/chats/' + this.state.chat.Hash)}
                                reloadChats={() => {
                                    this.props.history.push("/chats")
                                }}
                            />
                        </>
                        : null
                    }
                    <div className="chat-window">
                        <div className="message-list-holder"
                             ref={ref => this.chatwindow = ref}
                        >
                            {!this.state.loading ?
                                this.state.messages.length ?
                                    <MessageList
                                        className='message-list'
                                        lockable={true}
                                        toBottomHeight={'100%'}
                                        dataSource={this.state.messages}/>
                                    :
                                    !this.state.multiModelMode ?
                                        <p>
                                            <Translate id="chat.newMessage"/>
                                            &nbsp;<b>{this.state.model.FirstName}</b>
                                        </p>
                                        :
                                        null

                                : null
                            }
                            {!this.state.loading ?
                                this.state.multiModelMode ?
                                    <div className="multi-model-selection">
                                        {this.state.favourites.length ?
                                            <CheckPicker
                                                className="w-100"
                                                data={this.state.favourites}
                                                labelKey="DisplayNameCustomer"
                                                valueKey="ID"
                                                placeholder={<Translate id={"select.pleaseChoose"}/>}
                                                onSelect={this.handleModelSelect}

                                            />
                                            :
                                            <p><Translate id="favourites.noModels"/></p>
                                        }
                                    </div>
                                    :
                                    null
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="loading-holder">
                        <div className="bar" style={{width: this.state.progress + '%'}}></div>
                    </div>
                    <div className="newMessage-container">
                        <div className="d-flex">
                            <Form.Group className='from-label-group'>
                                <Form.Label htmlFor='inputMessage'><Translate id="chatwindow.message"/></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    id="inputMessage"
                                    onChange={this.handleMessageChange}
                                    disabled={this.state.loading || this.state.chat.Blocked}
                                    value={this.state.message}
                                    className='input-foreground'
                                />
                            </Form.Group>
                        </div>
                    </div>
                    {!this.state.chat.Blocked && !this.state.loading ?
                        <div className="send-action d-flex flex-column align-items-center justify-content-center"
                             onClick={this.sendMessage}
                        >
                            <Icofont icon="paper-plane"/>
                            <span className="action-title"><Translate id="chat.sendMessage"/></span>
                        </div>
                        :
                        null
                    }

                </section>
            </Container>
        )
    }

}

const mapStateToProps = (state) => ({
    memberID: state.user.memberID,
    authKey: state.user.authKey,
    user: state.user.user,
});


export default connect(
    mapStateToProps,
    null
)(withRouter(ChatWindow));
