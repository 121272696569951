import React, {Component} from "react";
import 'react-chat-elements/dist/main.css';
import './Chats.style.scss'
import {Button, Container, Spinner} from "react-bootstrap";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import CustomChatItem from "../../components/Chat/CustomChatItem";
import {ApiService} from "../../services/ApiService";
import {Translate} from "react-localize-redux";
import Icofont from "../../components/Icofont/Icofont";
import {HTMLService} from "../../services/HTMLService";

class Chats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            chats: []
        }
    }

    componentDidMount() {
        this.fetchChats()
        this.chatinterval = setInterval(() => {
            this.fetchChats()
        }, 30000)
    }

    componentWillUnmount() {
        clearInterval(this.chatinterval)
    }

    addChats(chats, response, offset, callback) {
        response.data.map((chat) => {
            chat.ChatDate = new Date(chat.ChatDate);
            chats.push(chat)
        })

        if (response.data.length === 0) {
            let newState = {
                loading: false,
            };

            chats.sort(function (a, b) {
                return new Date(b.ChatDate) - new Date(a.ChatDate);
            });

            if (JSON.stringify(this.state.chats) !== JSON.stringify(chats)) {
                newState.chats = chats;
            }

            this.setState(newState);
        } else {
            callback(chats);
        }
    }

    fetchNext(archivedFilter, memberID, offset = 0, chats = []) {
        ApiService.authorizedClient(this.props.authKey)
            .get('/Chat', {
                params: {
                    "filterAny[ModelID]": memberID,
                    "filterAny[CustomerID]": memberID,
                    [archivedFilter]: 0,
                    "limit": 100,
                    "offset": offset
                }
            })
            .then((response) => {
                this.addChats(chats, response, offset, (prevChats) => {
                    this.fetchNext(archivedFilter, memberID, offset + 100, prevChats)
                });
            })
            .catch((error) => {
                console.log(error)
            })
    }

    fetchChats() {
        let archivedFilter = 'filter[ArchivedModel]';
        if (this.props.user.ClassName === "CIC\\TopModelCast\\Customer\\Customer") {
            archivedFilter = 'filter[ArchivedCustomer]';
        }
        this.fetchNext(archivedFilter, this.props.memberID);
    }

    render() {
        return (
            <Container>
                <section className="py-4">
                    <div className="text-center">
                        <h1 className="pb-4"><Translate id="chats.header"/></h1>
                    </div>
                    {this.props.user.ClassName === "CIC\\TopModelCast\\Customer\\Customer" ?
                        <section className="pb-4 text-right">
                            <Button as={Link} to="/chats/new" className="small-btn">
                                <Icofont icon="plus" className="mr-1"/>
                                <Translate id="chat.newMultiModelChat"/>
                            </Button>
                        </section>
                        :
                        null
                    }
                    {!this.state.loading ?
                        this.state.chats.length ?
                            this.state.chats.map((chat, index) => {
                                return (
                                    <CustomChatItem
                                        key={chat.Hash}
                                        chatID={chat.ID}
                                        avatar={chat.Avatar}
                                        alt={chat.ChatTitle}
                                        title={<>
                                            <span className="model-name">{chat.ChatTitle}</span>
                                            {chat.ChatTitleSecondLine ? <span className="model-name-subtext text-muted"
                                                                              dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(chat.ChatTitleSecondLine)}}></span> : ''}
                                            <small>{chat.ChatSubtitle}</small></>}
                                        subtitle={
                                            chat.Reported ? <Translate id="chat.reportedSingleLine"/> : (chat.Blocked ?
                                                <Translate id="chat.blockedFull"/> : chat.LastMessage)}
                                        date={chat.ChatDate}
                                        unread={chat.UnreadCount}
                                        reported={chat.Reported}
                                        blocked={chat.Blocked}
                                        onClick={() => this.props.history.push('/chats/' + chat.Hash)}
                                        reloadChats={() => {
                                            this.setState({loading: true}, () => this.fetchChats())
                                        }}
                                    />
                                );
                            })
                            :
                            <p><Translate id="chat.noChats"/></p>
                        :
                        <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    }
                </section>
            </Container>
        )
    }

}

const mapStateToProps = (state) => ({
    memberID: state.user.memberID,
    authKey: state.user.authKey,
    user: state.user.user,
});

export default connect(
    mapStateToProps,
    null
)(withRouter(Chats));
