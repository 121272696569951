import React, {Component} from "react";
import {UnitConvertService} from "../../services/UnitConvertionService";
import {getTranslate, Translate} from "react-localize-redux";
import {SelectPicker} from "rsuite";
import {Col, Row} from "react-bootstrap";
import UserActions from "../../stores/user/actions";
import LocaleActions from "../../stores/locale/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class ClothingsizeDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 'EU',
            clothingSizesForDropdown: [...UnitConvertService.clothingSizeEUAdult,{value:0,label:this.props.translate("skintype.st_other")}],
        }
    }

    componentDidMount() {
        if (this.props.age >= (14 * 12) && this.state.type === "EU") {
            this.setState({
                clothingSizesForDropdown: [...UnitConvertService.clothingSizeEUAdult,{value:0,label:this.props.translate("skintype.st_other")}]
            })
        } else {
            this.setState({
                clothingSizesForDropdown: [...UnitConvertService.clothingSizeEUMinor,{value:0,label:this.props.translate("skintype.st_other")}]
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.type !== this.state.type) {
            if (this.state.type !== "EU") {
                this.setState({
                    clothingSizesForDropdown: [...UnitConvertService.clothingSizeOther,{value:0,label:this.props.translate("skintype.st_other")}]
                })
            } else {
                if (this.props.age >= (14 * 12)) {
                    this.setState({
                        clothingSizesForDropdown: [...UnitConvertService.clothingSizeEUAdult,{value:0,label:this.props.translate("skintype.st_other")}]
                    })
                } else {
                    this.setState({
                        clothingSizesForDropdown: [...UnitConvertService.clothingSizeEUMinor,{value:0,label:this.props.translate("skintype.st_other")}]
                    })
                }
            }
        }
        if (prevProps.age !== this.props.age) {
            if (this.props.age >= (14 * 12) && this.state.type === "EU") {
                this.setState({
                    clothingSizesForDropdown: [...UnitConvertService.clothingSizeEUAdult,{value:0,label:this.props.translate("skintype.st_other")}]
                })
            } else {
                this.setState({
                    clothingSizesForDropdown: [...UnitConvertService.clothingSizeEUMinor,{value:0,label:this.props.translate("skintype.st_other")}]
                })
            }
        }
    }

    render() {
        return (
            <Row className="clothing-size-dropdown">
                <Col xs={6}>
                    <SelectPicker
                        block
                        id='inputClothingSize'
                        placeholder={<Translate id="model.pleaseChoose"/>}
                        searchable={false}
                        cleanable={false}
                        data={this.state.clothingSizesForDropdown}
                        value={this.props.value}
                        onChange={(val) => this.props.handleClothingSizeChange(val)}
                    />
                </Col>
                <Col xs={6}>
                    <SelectPicker
                        block
                        id='inputClothingSizeType'
                        searchable={false}
                        cleanable={false}
                        data={[
                            {value: "EU", label: "EU"},
                            {value: "US", label: "US"},
                            {value: "UK", label: "UK"}
                        ]
                        }
                        value={this.state.type}
                        onChange={(val) => this.setState({type: val})}
                    />
                </Col>
            </Row>
        )
    }

}

const mapStateToProps = (state) => ({
    translate: getTranslate(state.localize)
});


export default connect(
    mapStateToProps,
    null
)(ClothingsizeDropdown);
