import de_DE from './de_DE.json';
import en_GB from './en_GB.json';
import en_US from './en_US.json';
import es_ES from './es_ES.json';
import fr_FR from './fr_FR.json';
import ar_AE from './ar_AE.json';
import zh_CN from './zh_CN.json';
import it_IT from './it_IT.json';
import ru_RU from './ru_RU.json';
import pt_PT from './pt_PT.json';
import tr_TR from './tr_TR.json';
import sv_SE from './sv_SE.json';

export default {
    de_DE,
    en_GB,
    en_US,
    es_ES,
    fr_FR,
    ar_AE,
    zh_CN,
    it_IT,
    ru_RU,
    pt_PT,
    tr_TR,
    sv_SE
}
