import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL + '/api/v1';
const baseURLNoApi = process.env.REACT_APP_BASE_URL + '/api/';
const baseUrlpayment = process.env.REACT_APP_BASE_URL + '/payment';
const geoUrl = process.env.REACT_APP_BASE_URL + '/geocode';
const vatUrl = process.env.REACT_APP_BASE_URL + '/vat';


const client = axios.create({
    baseURL: baseURL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 25000
});

const clientLocalized = (customLocale) => axios.create({
    baseURL: baseURL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Fluent-Locale': customLocale,
    },
    timeout: 25000
});

const baseClient = axios.create({
    baseURL: baseURLNoApi,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 25000
});

const authorizedClient = (key) => axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorize': key,
    },
    timeout: 25000
});

const authorizedClientLocalized = (key, customLocale) => axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorize': key,
        'X-Fluent-Locale': customLocale,
    },
    timeout: 25000
});

const vatClient = axios.create({
    baseURL: vatUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 25000
});

const geocodeFromLatLng = (lat, lng) => {
    const client = axios.create({
        baseURL: geoUrl,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        timeout: 25000
    })
    return client.get('?latlng=' + lat + ',' + lng);
}

const getAddressObject = (data) => {
    let address = {};
    data.map((item) => {
        address[item.types[0]] = item;
        return null;
    })

    return address;
}

const geocodeFromAddress = (query) => {
    const client = axios.create({
        baseURL: "https://maps.google.com/maps/api/geocode/",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        timeout: 25000
    })
    return client.get('json?key=AIzaSyC-YmV4eklJf4pBChz8pfSfDp6-CJhI6o0&sensor=true&address=' + query);

}

const serialize = (obj, prefix) => {
    let str = [],
        p;

    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + '[' + p + ']' : p,
                v = obj[p];

            str.push((v !== null && typeof v === 'object') ?
                serialize(v, k) :
                encodeURIComponent(k) + '=' + encodeURIComponent(v));
        }
    }

    return str.join('&');
};
const paymentClient = axios.create({
    baseURL: baseUrlpayment,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 25000
});

export const ApiService = {
    baseClient,
    client,
    clientLocalized,
    vatClient,
    authorizedClient,
    authorizedClientLocalized,
    serialize,
    geocodeFromLatLng,
    geocodeFromAddress,
    getAddressObject,
    baseURL,
    paymentClient
};

