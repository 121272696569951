import React, {Fragment} from 'react';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import './LocationField.scss';
import {Form} from "react-bootstrap";
import {ApiService} from "../../services/ApiService";
import Icofont from "../Icofont/Icofont";


export default class LocationField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: this.props.address ?? '',
            city: '',
            street: '',
            country: '',
            postalCode: '',
            number: '',
            lat: 0,
            lng: 0,
            sessionToken:null
        };
        this.setPropAddress = this.setPropAddress.bind(this);
    }


    handleChange = address => {
        this.setState({address});
    };

    setPropAddress(){
        if (typeof this.props.setAddress === 'function') {
            this.props.setAddress({
                Title: this.state.address,
                City: this.state.city,
                Street: this.state.street,
                Country: this.state.country,
                PostalCode: this.state.postalCode,
                Number: this.state.number,
                Latitude: this.state.lat,
                Longitude: this.state.lng,
            })
        }
    }
    componentDidMount() {
        this.setSessionToken();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevState.city !== this.state.city ||
            prevState.street !== this.state.street ||
            prevState.country !== this.state.country ||
            prevState.postalCode !== this.state.postalCode ||
            prevState.number !== this.state.number ||
            prevState.lat !== this.state.lat ||
            prevState.lng !== this.state.lng
        ) {
            this.setPropAddress();
        }
        if (prevProps.address !== this.props.address) {
            this.setState({
                address: this.props.address
            })
            if(!this.props.address){
                this.setState({
                    address: '',
                    city: '',
                    street: '',
                    country: '',
                    postalCode: '',
                    number: '',
                    lat: 0,
                    lng: 0
                })
            }
        }
    }

    handleSelect = address => {
        this.handleChange(address);
        geocodeByAddress(address)
            .then((results) => {
                let city = ""
                let street = ""
                let country = ""
                let number = ""
                let postalCode = ""
                let address = ApiService.getAddressObject(results[0].address_components);
                if (typeof address.locality !== 'undefined') {
                    city = address.locality.long_name
                }
                if (!city && typeof address.administrative_area_level_2 !== 'undefined') {
                    city = address.administrative_area_level_2.long_name;
                }
                if (!city && typeof address.administrative_area_level_1 !== 'undefined') {
                    city = address.administrative_area_level_1.long_name;
                }
                if (typeof address.street_number !== 'undefined') {
                    number = address.street_number.long_name
                }
                if (typeof address.country !== 'undefined') {
                    country = address.country.short_name
                }

                if (typeof address.postal_code !== 'undefined') {
                    postalCode = address.postal_code.long_name
                }
                if (typeof address.route !== 'undefined') {
                    street = address.route.long_name
                }
                this.setState({
                    city: city,
                    street: street,
                    country: country,
                    postalCode: postalCode,
                    number: number,
                })
                getLatLng(results[0])
                    .then(latLng => {
                        this.setState(latLng,() => this.setSessionToken())
                    })
                    .catch(error => console.error('Error', error));
            })
    };

    setSessionToken(){
        let token = new window.google.maps.places.AutocompleteSessionToken();
        this.setState({
            sessionToken: token
        })
    }

    render() {
        const searchOptions = {};
        if (this.props.onlyCitys) {
            searchOptions.types = ['(cities)'];
        } else {
            searchOptions.types = ['address']
        }
        searchOptions.sessionToken = this.state.sessionToken;
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={searchOptions}
            >
                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                    <Fragment>
                        <Form.Group className={this.props.className + ' location-field mb-0'}>
                            <Form.Control {...getInputProps({
                                placeholder: '',
                                className: 'location',
                            })} value={this.state.address} autoComplete="off"/>
                            {this.props.onlyCitys ?
                                <Icofont
                                    className={"clearLocation"}
                                    icon="close-line"
                                    size={2}
                                    onClick={() => {
                                        this.setState({
                                            address: '',
                                            city: '',
                                            street: '',
                                            country: '',
                                            postalCode: '',
                                            number: '',
                                            lat: 0,
                                            lng: 0
                                        },() => this.setPropAddress())
                                    }
                                    }
                                />
                                :
                                null
                            }
                            <div className="autocomplete-dropdown-container">
                                {loading && <div className={"suggestion-item"}>Lade...</div>}
                                {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                        ? 'suggestion-item active'
                                        : 'suggestion-item';
                                    return (
                                        <div key={'map_' + index} {...getSuggestionItemProps(suggestion, {
                                            className,
                                        })}>
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </Form.Group>
                    </Fragment>
                )}
            </PlacesAutocomplete>
        );
    }
}


