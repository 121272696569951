import React, {Component} from "react";
import "./ContactTree.style.scss"
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Container, Spinner} from "react-bootstrap";
import {Translate} from "react-localize-redux";
import {ApiService} from "../../services/ApiService";
import ContactTreeItem from "../../components/ContactTreeItem/ContactTreeItem";

class ContactTree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            contactItems: [],
            locale: this.props.locale ?? 'de_DE'
        }
        this.fetchFirstContactItems = this.fetchFirstContactItems.bind(this)
    }

    componentDidMount() {
        this.fetchFirstContactItems()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.locale !== this.state.locale) {
            this.setState({
                locale: this.props.locale
            }, () => this.fetchFirstContactItems())
        }
    }

    fetchFirstContactItems() {
        ApiService.clientLocalized(this.state.locale).get(
            'ContactTreeItem',
            {
                params: {
                    "filter[HolderID]": 0,
                    "locale": this.state.locale,
                }
            }
        )
            .then(
                (response) => {
                    this.setState({
                        contactItems: response.data,
                        loading: false,
                    })

                },
                (error) => {
                    console.log(error)
                }
            );
    }

    render() {
        return (
            <Container fluid>
                <section className="py-4">
                    <div className="text-center">
                        <h1 className="pb-4"><Translate id="contactTree.header"/></h1>
                    </div>
                    {!this.state.loading ?
                        <ContactTreeItem items={this.state.contactItems} locale={this.state.locale} extraClass='odd'/>
                        :
                        <Spinner animation="border" role="status"
                                 className='mx-auto d-block align-self-center'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    }
                </section>
            </Container>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    memberID: state.user.memberID,
    authKey: state.user.authKey,
    user: state.user.user,
    locale: state.locale.locale
});


export default connect(
    mapStateToProps,
    null,
)(withRouter(ContactTree));
