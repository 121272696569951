/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
    basketID: 0,
    billingAddressID: 0,
    shippingAddressID: 0,
    remarks: '',
    checkoutPreFill: false
};
