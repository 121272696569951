import React, {Component} from "react";
import ModelTab from "./ModelTab";
import ScoutAdmin from "../ScoutAdmin/ScoutAdmin";
import {Tab, Tabs} from "react-bootstrap";
import ExpiredModelTab from "./ExpiredModelTab";
import AllreadySentModelTab from "./AllreadySentModelTab";


export default class AdminDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            key: 'models'
        }
    }

    render() {
        return (
            <section>
                <Tabs defaultActiveKey="model">
                    <Tab eventKey="model" title="Models">
                        <ModelTab/>
                    </Tab>
                    <Tab eventKey="scouts" title="Kunden">
                        <ScoutAdmin/>
                    </Tab>
                    <Tab eventKey="expired-models" title="Abgelaufene Verträge">
                        <ExpiredModelTab/>
                    </Tab>
                    <Tab eventKey="sent-models" title="Bereits gesendet">
                        <AllreadySentModelTab/>
                    </Tab>
                </Tabs>

            </section>
        )
    }
}
