import React, {Component} from 'react'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import Icofont from "../Icofont/Icofont";
import {Button, Col, Row} from "react-bootstrap";
import './UploadField.style.scss'
import {List} from "rsuite";
import {ApiService} from "../../services/ApiService";
import isTouchDevice from "is-touch-device"
import {Translate} from "react-localize-redux";

export default class VideoUploadField extends Component {
    static defaultProps = {
        files: [],
    };

    state = {
        currentFiles: [],
        message: '',
        showCropField: false,
        imgAbsoluteURL: '',
        topModelImgID: 0,
        isScout: false,
        oldFileID: 0,
        allowSort: true,
    }

    constructor(props) {
        super(props);

        this.handleDelete = this.handleDelete.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.updateFileList = this.updateFileList.bind(this);
        this.handleSortEnd = this.handleSortEnd.bind(this);
    }

    // specify upload params and url for your files
    getUploadParams = ({meta}) => {
        return {url: process.env.REACT_APP_BASE_URL + '/api/v1/File/uploadModelVideo'}
    }

    componentDidMount() {
        this.setState({currentFiles: this.props.files});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.files) !== JSON.stringify(this.props.files)) {
            this.setState({
                currentFiles: this.props.files,
                allowSort: true,
            });
        }
    }

    // called every time a file's `status` changes
    handleChangeStatus = (fileWithMeta, status, files) => {
        if (status === 'done') {
            let newFiles = [...this.state.currentFiles, (JSON.parse(fileWithMeta.xhr.response))];

            if (this.props.maxFiles <= this.state.currentFiles.length) {
                newFiles.shift();
            }

            this.setState({currentFiles: newFiles}, () => {
                if (typeof this.props.onChange === 'function') {
                    this.props.onChange(this.state.currentFiles);
                }
            })
            fileWithMeta.remove();
        }

        if (status === 'error_upload') {
            if (fileWithMeta.xhr.readyState !== 4) {
                fileWithMeta.xhr.onloadend = () => {
                    this.setState(JSON.parse(fileWithMeta.xhr.response));
                }
            } else {
                this.setState(JSON.parse(fileWithMeta.xhr.response));
            }
            fileWithMeta.remove();
        }

        if (status === 'preparing') {
            this.setState({message: ''});
        }

        if (status === 'rejected_file_type') {
            this.setState({message: <Translate id="upload.fileNotAllowed"/>});
        }

        if (status === 'error_file_size') {
            this.setState({message: <Translate id="upload.maxFileSize"/>});
        }

        if (status === 'error_upload_params' || status === 'exception_upload') {
            this.setState({message: <Translate id="error.unknown"/>});
        }
        this.setState({
            allowSort: false,
        })
    }

    handleDelete(file) {
        if (typeof this.props.deleteAdminComment === 'function') {
            let type = this.props.adminCommentType;
            if (type === 'IMG_') {
                type += file.ID;
            }
            this.props.deleteAdminComment(type);
        }
        this.setState({
                currentFiles: this.state.currentFiles.filter((item, index) => {
                    return file.ID !== item.ID;
                }),
            },
            () => {
                if (typeof this.props.onDelete === 'function') {
                    this.props.onDelete(file, this.state.currentFiles);
                }
            }
        )
    }

    updateFileList(file) {
        let currentFiles = [];
        const me = this;
        this.state.currentFiles.map((oldFile) => {
            if (oldFile.ID === me.state.oldFileID) {
                oldFile = file
            }
            currentFiles.push(oldFile);
            return null;
        })
        this.setState({currentFiles: currentFiles});
    }

    handleSortEnd({oldIndex, newIndex}) {
        this.setState(({currentFiles}) => {
            const moveData = currentFiles.splice(oldIndex, 1);
            const newData = [...currentFiles];
            newData.splice(newIndex, 0, moveData[0]);
            return {
                currentFiles: newData
            };
        }, () => {
            this.state.currentFiles.map((file, index) => {
                if (file.SortOrder !== index) {
                    file.SortOrder = index;
                    if (file.ID) {
                        ApiService.client.put(
                            `/TopModelVideo/${file.ID}/`,
                            {SortOrder: index}
                        ).then().catch((error) => {
                            console.error(error);
                        })
                    }
                }
                return null;
            })
        });
    }

    render() {
        return (
            <>
                <div className="my-uploadfield">
                    {this.state.message ? <p className="text-center text-danger">{this.state.message}</p> : null}
                    {this.props.maxFiles === 1 && typeof this.props.files !== 'undefined' && this.props.files.length ?
                        null
                        :
                        <Dropzone
                            getUploadParams={this.getUploadParams}
                            canRestart={false}
                            canCancel={false}
                            canRemove={false}
                            submitButtonContent={null}
                            maxSizeBytes={(1024 * 1024 * 50)}
                            inputContent={<Translate id="upload.descDefault" key="translation"/>}
                            accept="video/*"
                            classNames={{
                                inputLabelWithFiles: 'd-none',
                                inputLabel: 'dzu-inputLabel text-center text-muted',
                                dropzone: 'dzu-dropzone overflow-auto'
                            }}
                            {...this.props}
                            onChangeStatus={this.handleChangeStatus}
                        />
                    }

                    <div className="dzu-currentFiles">
                        <List
                            sortable={this.state.allowSort && this.state.currentFiles.length > 1}
                            onSort={this.handleSortEnd}
                        >
                            {this.state.currentFiles.map((file, index) => {
                                return (
                                    <List.Item
                                        key={file.ID}
                                        index={index}
                                    >
                                        <div key={file.ID} className="dzu-previewContainer flex-wrap">
                                            {this.state.allowSort && this.state.currentFiles.length > 1 && index > 0 && isTouchDevice() ?
                                                <div className="reorderArrow w-100"
                                                     onClick={() => this.handleSortEnd({
                                                         oldIndex: index,
                                                         newIndex: index - 1
                                                     })}
                                                >
                                                    <Icofont icon="arrow-up"/>
                                                </div>
                                                :
                                                null
                                            }
                                            <video width="200" controls>
                                                <source src={file.AbsoluteURL} type={file.MimeType}/>
                                            </video>
                                            <div className="dzu-previewStatusContainer">
                                                <span className="dzu-title">{file.Video.Title}</span>
                                                <div className="d-flex justify-content-center align-items-center ml-2">
                                                    <Button variant="link"
                                                            className="dzu-previewButton dzu-previewButton-delete"
                                                            onClick={() => this.handleDelete(file)}>
                                                        <Icofont icon='trash'/>
                                                        <div className="button-text">
                                                            <Translate id="upload.deleteImage"/>
                                                        </div>
                                                    </Button>
                                                </div>

                                            </div>
                                            {typeof this.props.adminComment !== "undefined" && typeof this.props.adminComment['Video_' + file.ID] !== 'undefined' ?
                                                <div className="img-comment w-100">
                                                    <Row className="text-danger">
                                                        <Col xs={6} lg={2}>
                                                            <b><Translate id="model.comment"/></b>
                                                        </Col>
                                                        <Col xs={6} lg={10}>
                                                            {this.props.adminComment['Video_' + file.ID].message}
                                                        </Col>
                                                    </Row>
                                                </div>
                                                :
                                                null
                                            }
                                            {this.state.allowSort && this.state.currentFiles.length > 1 && index < this.state.currentFiles.length - 1 && isTouchDevice() ?
                                                <div className="reorderArrow w-100"
                                                     onClick={() => this.handleSortEnd({
                                                         oldIndex: index,
                                                         newIndex: index + 1
                                                     })}
                                                >
                                                    <Icofont icon="arrow-down"/>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </List.Item>
                                )
                            })}
                        </List>
                    </div>
                </div>
            </>
        )
    }
}
