import {all, takeLatest} from 'redux-saga/effects';
import {UserActionTypes} from '../stores/user/actions';
import {fetchUser} from './UserSaga';

export default function* root() {
    yield all([
        /**
         * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
         */
        // Call `fetchUser()` when a `FETCH_USER` action is triggered
        takeLatest(UserActionTypes.FETCH_USER, fetchUser),
        takeLatest(UserActionTypes.LOGIN, fetchUser),
    ]);
}
