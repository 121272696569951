import React, {Component} from "react";
import RegsiterForm from "./RegsiterForm";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import {getTranslate} from "react-localize-redux";

class ScoutRegisterRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            homeText: '',
            locale: this.props.locale ?? 'de_DE'
        }
    }

    componentDidMount() {
        this.fetchHomePageData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.locale !== this.state.locale) {
            this.setState({
                locale: this.props.locale
            }, () => this.fetchHomePageData())
        }
    }

    fetchHomePageData() {
        ApiService.clientLocalized(this.state.locale).get('/SiteConfig/1')
            .then((response) => {
                this.setState({
                    homeText: response.data.AppHomeTextScout ?? '',
                })

            })
            .catch((error) => {
                let errorMsg = this.props.translate("error.unknown");
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                toastr.error(this.props.translate("toastr.error"), errorMsg)
            })
    }

    render() {
        return <RegsiterForm type={'Scout'} homeText={this.state.homeText}/>
    }
}

const mapStateToProps = (state) => ({
    locale: state.locale.locale,
    translate: getTranslate(state.localize)
});

export default connect(
    mapStateToProps,
    null,
)(ScoutRegisterRegister);
