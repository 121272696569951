import React, {Component} from "react";
import './CustomerDashboard.style.scss'
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {Translate} from "react-localize-redux";
import {Button} from "react-bootstrap";


class CustomerDashboard extends Component {
    render() {
        return (
            <section>
                <h1><Translate
                    id="user.loggedInAs"/> {(this.props.user.FirstName ?? '') + ' ' + (this.props.user.Surname ?? '')}
                </h1>
                <p>Hier wird gerade gebaut!</p>
                <Button onClick={this.props.logout}>
                    <Translate id="user.logOut"/>
                </Button>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});
const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(UserActions.logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerDashboard);
