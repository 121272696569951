import React, {Component} from "react";
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import {Alert, Button, Container, Form} from "react-bootstrap";
import {getTranslate, Translate} from "react-localize-redux";
import {toastr} from "react-redux-toastr";

class PasswordReset extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showFields:true,
            error: '',
            errorPWD: ''
        }

        this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePasswordRepeatChange = this.handlePasswordRepeatChange.bind(this);
    }

    handlePasswordChange(e) {
        let text = e.target.value;
        this.setState({password: text});
        if (text.length < 8) {
            this.setState({passwordError: <Translate id="error.passwordLength"/>});
        } else {
            this.setState({passwordError: ''});
        }
    }

    handlePasswordRepeatChange(e) {
        let text = e.target.value;
        this.setState({passwordRepeat: text});
        if (text !== this.state.password) {
            this.setState({passwordRepeatError: <Translate id="error.passwordRepeat"/>});
        } else {
            this.setState({passwordRepeatError: ''});
        }

    }

    handlePasswordSubmit() {
        if (
            this.state.passwordError ||
            this.state.passwordRepeatError
        ) {
            this.setState({errorPWD: <Translate id="error.checkInput"/>});
            return;
        }
        if (
            this.state.password.length <= 0 ||
            this.state.passwordRepeat.length <= 0
        ) {
            this.setState({errorPWD: <Translate id="error.enterAllFields"/>});
            return;
        }

        const me = this;
        me.setState({loading: true});
        ApiService.client.post(
            '/Member/savePassword',
            {
                'PasswordResetCode': me.props.match.params.resetCode,
                'Password': me.state.password,
            }
        ).then(
            () => {
                me.setState({errorPWD: ''});
                toastr.success(this.props.translate('toastr.success'), this.props.translate('settings.passwordSaved'))
                this.props.history.push('/')
            },
            (error) => {
                let errorMsg = this.props.translate("error.unknown");
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                me.setState({loading: false});
                this.setState({errorPWD: ''});
                toastr.error(this.props.translate("toastr.error"), errorMsg)
            },
        );
    }


    componentDidMount() {
        if (!this.props.match.params.resetCode) {
            this.setState({showFields:false,error:'No Code'});
        }
    }


    render() {
        return (
            <Container>
                {this.state.showFields ?
                    <>
                        <h4 className="mb-4"><Translate id="settings.changePassword"/></h4>
                        <Form>
                            <Form.Group className='from-label-group'>
                                <Form.Label htmlFor='inputEmail'><Translate id="settings.password"/></Form.Label>
                                <Form.Control
                                    type="password"
                                    id="inputPassword"
                                    onChange={this.handlePasswordChange}
                                    disabled={this.state.loading}
                                    value={this.state.password}
                                    className='input-foreground'
                                />
                                <p className='text-danger m-0'>{this.state.passwordError}</p>
                            </Form.Group>
                            <Form.Group className='from-label-group'>
                                <Form.Label htmlFor='inputEmail'><Translate
                                    id="settings.passwordRepeat"/></Form.Label>
                                <Form.Control
                                    type="password"
                                    id="inputPasswordRepeat"
                                    onChange={this.handlePasswordRepeatChange}
                                    disabled={this.state.loading}
                                    value={this.state.passwordRepeat}
                                    className='input-foreground'
                                    onKeyDown={this.onKeyDownPWD}
                                />
                                <p className='text-danger m-0'>{this.state.passwordRepeatError}</p>
                            </Form.Group>
                            {this.state.errorPWD ?
                                <Alert variant="danger" className='mt-3'>{this.state.errorPWD}</Alert>
                                : null
                            }
                            <Button onClick={this.handlePasswordSubmit} className="w-100">
                                <Translate id="settings.savePassword"/>
                            </Button>
                        </Form>
                    </>
                    :
                    null
                }
                {this.state.error ?
                    <p className="text-center text-danger">{this.state.error}</p>
                    :
                    null
                }
            </Container>)
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    translate: getTranslate(state.localize)
});

const mapDispatchToProps = (dispatch) => ({
    login: (authKey, validUntil, memberID) => dispatch(UserActions.login(authKey, validUntil, memberID)),
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(PasswordReset));
