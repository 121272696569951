/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import {createReducer} from 'reduxsauce';
import {INITIAL_STATE} from './initialState';
import {LocaleActionTypes} from './actions';

export const setModels = (state, {models}) => {
    return {
        ...state,
        models: models
    };
};

export const setPosition = (state, {position}) => {
    return {
        ...state,
        position: position
    };
};
export const setOffset = (state, {offset}) => {
    return {
        ...state,
        offset: offset
    };
};
export const setShowSlider = (state, {mode}) => {
    return {
        ...state,
        showSlider: mode
    };
};


export const clearModelSearch = (state) => {
    return {
        ...state,
        models: [],
        position: 0
    };
};

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
    [LocaleActionTypes.SET_MODELS]: setModels,
    [LocaleActionTypes.SET_POSITION]: setPosition,
    [LocaleActionTypes.SET_OFFSET]: setOffset,
    [LocaleActionTypes.SET_SHOW_SLIDER]: setShowSlider,
    [LocaleActionTypes.CLEAR_MODEL_SEARCH]: clearModelSearch,
});
