import React, {Component} from "react";
import Cropper from "react-easy-crop";
import './CropField.style.scss';
import {Button, Col, Row, Spinner} from "react-bootstrap";
import getCroppedImg from './cropImage'
import {toastr} from "react-redux-toastr";
import {ApiService} from "../../services/ApiService";
import {connect} from "react-redux";
import {getTranslate, Translate} from "react-localize-redux";
import ReactSlider from 'react-slider'


class CropField extends Component {

    constructor(props) {
        super(props);

        this.state = {
            crop: {x: 0, y: 0},
            croppedAreaPixels: {},
            croppedImage: {},
            zoom: 1,
            rotation: 0,
            loading: false,
        }
        this.setCrop = this.setCrop.bind(this);
        this.setZoom = this.setZoom.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.setRotation = this.setRotation.bind(this);
        this.setCropImage = this.setCropImage.bind(this);
    }

    setCrop(crop) {
        this.setState({crop: crop})
    }

    setZoom(zoom) {
        this.setState({zoom: zoom})
    }

    setRotation(rotation) {
        this.setState({rotation: rotation})
    }

    onCropComplete(croppedArea, croppedAreaPixels) {
        this.setState({
            croppedAreaPixels: croppedAreaPixels
        })
    }

    async setCropImage() {
        try {
            this.setState({
                loading: true
            })
            const croppedImage = await getCroppedImg(
                this.props.imgURL,
                this.state.croppedAreaPixels,
                this.state.rotation
            )
            let base64data = croppedImage;
            let modelClass = "EditableImage";
            this.setState({croppedImage}, () => {
                ApiService.client.post(
                    modelClass + "/" + this.props.topModelImgID + '/saveCroppedImage/?m=' + Date.now(),
                    {CroppedImage: base64data}
                ).then(
                    (response) => {
                        if (response.data && typeof this.props.updateFileList === "function") {
                            let newFile = response.data;
                            newFile.TopModelImgID = this.props.topModelImgID;
                            this.props.updateFileList(newFile);
                        }
                        toastr.success(this.props.translate('toastr.success'), this.props.translate('toastr.imageSaved'))
                        if (typeof this.props.closeFunction === "function") {
                            this.props.closeFunction();
                        }
                    },
                    (error) => {
                        let errorMsg = this.props.translate("error.unknown");
                        errorMsg += ' '+error.toString();
                        if (error.response && error.response.data && error.response.data.message) {
                            errorMsg = error.response.data.message
                        }
                        toastr.error(this.props.translate("toastr.error"), errorMsg)
                    }
                );
            })

        } catch (e) {
            console.error(e)
            toastr.error(this.props.translate("toastr.error"), e)
        }
    }

    render() {
        return (
            <div className='crop-container-holder d-flex flex-column h-100'>
                <div className="crop-container">
                    <Cropper
                        image={this.props.imgURL}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        rotation={this.state.rotation}
                        aspect={1}
                        onCropChange={this.setCrop}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.setZoom}
                        onRotationChange={this.setRotation}
                    />
                </div>
                <div className="controls d-flex flex-grow-1 align-items-center w-100 flex-column">
                    <Row className='mb-5 w-100 align-items-center'>
                        <Col sm={4}>
                            <label><Translate
                                id="cropField.zoom"/><small>({this.state.zoom.toFixed(2)})x</small></label>
                        </Col>
                        <Col sm={8}>
                            <ReactSlider
                                className="horizontal-slider"
                                thumbClassName="small-thumb"
                                trackClassName="normal-track"
                                value={this.state.zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                onChange={(zoom) => this.setZoom(zoom)}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-5 w-100 align-items-center'>
                        <Col sm={4}>
                            <label><Translate id="cropField.rotation"/>
                                <small>({this.state.rotation.toFixed(2)}°)</small></label>
                        </Col>
                        <Col sm={8}>
                            <ReactSlider
                                className="horizontal-slider"
                                thumbClassName="small-thumb"
                                trackClassName="normal-track"
                                value={this.state.rotation}
                                min={0}
                                max={360}
                                step={10}
                                onChange={(rotation) => this.setRotation(rotation)}
                            />
                        </Col>
                    </Row>
                    <Button onClick={this.setCropImage} disabled={this.state.loading} style={{minWidth: 140}}>
                        {this.state.loading ?
                            <Spinner animation="border" role="status"
                                     className='mx-auto d-block align-self-center'>
                                <span className="sr-only"><Translate id="cropField.loading"/></span>
                            </Spinner>
                            :
                            <><Translate id="cropField.save"/></>
                        }
                    </Button>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    translate: getTranslate(state.localize)
});

export default connect(
    mapStateToProps,
    null
)(CropField);
