import React, {Component} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {SelectPicker} from "rsuite";
import {Translate} from "react-localize-redux";
import axios from 'axios';
import {UnitConvertService} from "../../services/UnitConvertionService";

export default class UnitNumberInputField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            currencyBase: '',
            currencyRates: [],
            unitType: this.props.unitType,
            valueType: this.props.valueType,
            adminCommentType: this.props.adminCommentType ?? 'NONE',
            adminComment: this.props.adminComment ?? {},
            error: ''
        }

        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleValueTypeChange = this.handleValueTypeChange.bind(this);
        this.convertToSE = this.convertToSE.bind(this);
        this.fetchLatestCurrencies = this.fetchLatestCurrencies.bind(this);
        this.convertCurrency = this.convertCurrency.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            this.setState({
                value: this.props.value
            })
        }
        if (prevProps.valueType !== this.props.valueType && this.props.valueType !== this.state.valueType) {
            this.setState({
                valueType: this.props.valueType
            })
        }
    }

    fetchLatestCurrencies(base, to) {
        if (this.state.currencyBase !== base) {
            let url = "https://api.exchangerate.host/latest?base=" + base;
            axios.get(url).then((result) => {
                if (typeof result.data.rates !== "undefined") {
                    this.setState({
                        currencyBase: base,
                        currencyRates: result.data.rates
                    }, () => this.convertCurrency(to))
                }
            })
        } else {
            this.convertCurrency(to);
        }
    }

    convertCurrencySEVal(val, from) {
        if (this.state.currencyBase != from) {
            if (typeof this.state.currencyRates[from] !== "undefined") {
                val = val / this.state.currencyRates[from]
            }

        }
        return val.toFixed(2);
    }

    convertCurrency(to) {
        if (this.state.currencyBase != to) {
            let old = this.state.value;
            let val = this.state.value;
            if (typeof this.state.currencyRates[to] !== "undefined") {
                val = val * this.state.currencyRates[to]
                this.setState({value: val.toFixed(2)});
                //console.log(val);
                this.props.handelValueSet(val.toFixed(0), '', old)
            }
            if (typeof this.props.handleTypeSet === "function") {
                this.props.handleTypeSet(this.state.valueType)
            }
        }

    }

    handleValueChange(e) {
        let text = e.target.value.replace(',', '.');
        if (this.props.unitType === "Size" && this.state.valueType == "cm") {
            text = text.replace(/\./g, '');
        }
        if (this.props.unitType === "Size" || this.props.unitType === "Weight") {
            if (text.includes('.', 0)) {
                text = text.substring(0, 4);
            } else {
                text = text.substring(0, 3);
            }
        }

        if (this.props.unitType === "Shoe") {
            if (text.includes('.', 0)) {
                text = text.substring(0, 4);
            } else {
                text = text.substring(0, 2);
            }
        }
        this.setState({value: text});

        let inputError = false;
        if (isNaN(text)) {
            this.setState(
                {
                    error: this.props.errorMessage
                }
            )
            inputError = true;
        } else {
            this.setState(
                {
                    error: ''
                }
            )
        }
        if (typeof this.props.handelValueSet === "function") {
            if (this.props.unitType === "Shoe") {
                let valnew = UnitConvertService.convertShoeSize(parseInt(text), this.state.valueType, "EU", this.props.gender ?? 'male') + ''
                this.props.handelValueSet(text, false, valnew);
            } else {
                if (this.props.unitType === "Currency") {
                    let seVal = text;
                    if (this.state.valueType !== 'EUR') {
                        seVal = this.convertCurrencySEVal(parseFloat(text), this.state.valueType);
                    }
                    this.props.handelValueSet(text, inputError, seVal)
                } else {
                    let seVal = this.convertToSE(text, this.state.valueType);
                    this.props.handelValueSet(text, inputError, seVal)
                }

            }

        }
    }


    handleValueTypeChange(val) {
        let beforeValType = this.state.valueType;

        this.setState({valueType: val}, () => {
            if (this.props.unitType === "Currency") {
                this.fetchLatestCurrencies(beforeValType, val)
            } else if (this.props.unitType === "Shoe") {
                let valnew = UnitConvertService.convertShoeSize(this.state.value, beforeValType, val, this.props.gender ?? 'male') + ''
                this.setState({value: valnew});
                this.props.handelValueSet(valnew, false, this.state.value);
            } else {
                if (
                    val === 'inch' ||
                    val === 'lbs' ||
                    val === 'mi'
                ) {
                    let valImp = this.convertToImperial(this.state.value, val)
                    if (!isNaN(valImp)) {
                        valImp = parseFloat(valImp).toFixed(0);
                    }
                    this.props.handelValueSet(valImp, false, this.state.value);
                    this.setState({value: valImp});
                }
                if (
                    val === 'cm' ||
                    val === 'kg' ||
                    val === 'km'
                ) {
                    let valSE = this.convertToSE(this.state.value, beforeValType)
                    if (!isNaN(valSE)) {
                        valSE = parseFloat(valSE).toFixed(0);
                    }
                    this.props.handelValueSet(valSE, false, valSE);
                    this.setState({value: valSE});
                }
            }

        });
        if (this.props.unitType !== "Currency") {
            if (typeof this.props.handleTypeSet === "function") {
                this.props.handleTypeSet(val)
            }
        }
    }

    convertToImperial(val, convertToType) {
        if (convertToType === 'inch') {
            return (val / 2.54).toFixed(2);
        }
        if (convertToType === 'lbs') {
            return (val * 2.205).toFixed(2);
        }
        if (convertToType === 'mi') {
            return (val / 1.60934).toFixed(2);
        }
        return val;
    }

    convertToSE(val, unitType) {
        if (unitType === 'inch') {
            return (val * 2.54).toFixed(2);
        }
        if (unitType === 'lbs') {
            return (val / 2.205).toFixed(2);
        }
        if (unitType === 'mi') {
            return (val * 1.60934).toFixed(2);
        }

        return val;
    }

    getDropDownData() {
        if (this.state.unitType === 'Weight') {
            return [
                {value: 'kg', label: 'kg'},
                {value: 'lbs', label: 'lbs'}
            ];
        }

        if (this.state.unitType === 'Shoe') {
            return [
                {value: 'EU', label: 'EU'},
                {value: 'US', label: 'US'},
                {value: 'UK', label: 'UK'},
                {value: 'JAPAN', label: 'JAPAN'},
            ];
        }
        if (this.state.unitType === 'Currency') {
            return [
                {value: 'EUR', label: 'EUR'},
                {value: 'USD', label: 'USD'},
                {value: 'CAD', label: 'CAD'},
                {value: 'HKD', label: 'HKD'},
                {value: 'ISK', label: 'ISK'},
                {value: 'PHP', label: 'PHP'},
                {value: 'DKK', label: 'DKK'},
                {value: 'HUF', label: 'HUF'},
                {value: 'CZK', label: 'CZK'},
                {value: 'GBP', label: 'GBP'},
                {value: 'RON', label: 'RON'},
                {value: 'SEK', label: 'SEK'},
                {value: 'IDR', label: 'IDR'},
                {value: 'INR', label: 'INR'},
                {value: 'BRL', label: 'BRL'},
                {value: 'RUB', label: 'RUB'},
                {value: 'AED', label: 'AED'},
                {value: 'AFN', label: 'AFN'},
                {value: 'ALL', label: 'ALL'},
                {value: 'AMD', label: 'AMD'},
                {value: 'ANG', label: 'ANG'},
                {value: 'AOA', label: 'AOA'},
                {value: 'ARS', label: 'ARS'},
                {value: 'AUD', label: 'AUD'},
                {value: 'AWG', label: 'AWG'},
                {value: 'AZN', label: 'AZN'},
                {value: 'BAM', label: 'BAM'},
                {value: 'BBD', label: 'BBD'},
                {value: 'BDT', label: 'BDT'},
                {value: 'BGN', label: 'BGN'},
                {value: 'BHD', label: 'BHD'},
                {value: 'BIF', label: 'BIF'},
                {value: 'BMD', label: 'BMD'},
                {value: 'BND', label: 'BND'},
                {value: 'BOB', label: 'BOB'},
                {value: 'BSD', label: 'BSD'},
                {value: 'BTC', label: 'BTC'},
                {value: 'BTN', label: 'BTN'},
                {value: 'BWP', label: 'BWP'},
                {value: 'BYN', label: 'BYN'},
                {value: 'BZD', label: 'BZD'},
                {value: 'CDF', label: 'CDF'},
                {value: 'CHF', label: 'CHF'},
                {value: 'CLF', label: 'CLF'},
                {value: 'CLP', label: 'CLP'},
                {value: 'CNH', label: 'CNH'},
                {value: 'CNY', label: 'CNY'},
                {value: 'COP', label: 'COP'},
                {value: 'CRC', label: 'CRC'},
                {value: 'CUC', label: 'CUC'},
                {value: 'CUP', label: 'CUP'},
                {value: 'CVE', label: 'CVE'},
                {value: 'DJF', label: 'DJF'},
                {value: 'DOP', label: 'DOP'},
                {value: 'DZD', label: 'DZD'},
                {value: 'EGP', label: 'EGP'},
                {value: 'ERN', label: 'ERN'},
                {value: 'ETB', label: 'ETB'},
                {value: 'FJD', label: 'FJD'},
                {value: 'FKP', label: 'FKP'},
                {value: 'GEL', label: 'GEL'},
                {value: 'GGP', label: 'GGP'},
                {value: 'GHS', label: 'GHS'},
                {value: 'GIP', label: 'GIP'},
                {value: 'GMD', label: 'GMD'},
                {value: 'GNF', label: 'GNF'},
                {value: 'GTQ', label: 'GTQ'},
                {value: 'GYD', label: 'GYD'},
                {value: 'HNL', label: 'HNL'},
                {value: 'HRK', label: 'HRK'},
                {value: 'HTG', label: 'HTG'},
                {value: 'ILS', label: 'ILS'},
                {value: 'IMP', label: 'IMP'},
                {value: 'IQD', label: 'IQD'},
                {value: 'IRR', label: 'IRR'},
                {value: 'JEP', label: 'JEP'},
                {value: 'JMD', label: 'JMD'},
                {value: 'JOD', label: 'JOD'},
                {value: 'JPY', label: 'JPY'},
                {value: 'KES', label: 'KES'},
                {value: 'KGS', label: 'KGS'},
                {value: 'KHR', label: 'KHR'},
                {value: 'KMF', label: 'KMF'},
                {value: 'KPW', label: 'KPW'},
                {value: 'KRW', label: 'KRW'},
                {value: 'KWD', label: 'KWD'},
                {value: 'KYD', label: 'KYD'},
                {value: 'KZT', label: 'KZT'},
                {value: 'LAK', label: 'LAK'},
                {value: 'LBP', label: 'LBP'},
                {value: 'LKR', label: 'LKR'},
                {value: 'LRD', label: 'LRD'},
                {value: 'LSL', label: 'LSL'},
                {value: 'LYD', label: 'LYD'},
                {value: 'MAD', label: 'MAD'},
                {value: 'MDL', label: 'MDL'},
                {value: 'MGA', label: 'MGA'},
                {value: 'MKD', label: 'MKD'},
                {value: 'MMK', label: 'MMK'},
                {value: 'MNT', label: 'MNT'},
                {value: 'MOP', label: 'MOP'},
                {value: 'MRO', label: 'MRO'},
                {value: 'MRU', label: 'MRU'},
                {value: 'MUR', label: 'MUR'},
                {value: 'MVR', label: 'MVR'},
                {value: 'MWK', label: 'MWK'},
                {value: 'MXN', label: 'MXN'},
                {value: 'MYR', label: 'MYR'},
                {value: 'MZN', label: 'MZN'},
                {value: 'NAD', label: 'NAD'},
                {value: 'NGN', label: 'NGN'},
                {value: 'NIO', label: 'NIO'},
                {value: 'NOK', label: 'NOK'},
                {value: 'NPR', label: 'NPR'},
                {value: 'NZD', label: 'NZD'},
                {value: 'OMR', label: 'OMR'},
                {value: 'PAB', label: 'PAB'},
                {value: 'PEN', label: 'PEN'},
                {value: 'PGK', label: 'PGK'},
                {value: 'PKR', label: 'PKR'},
                {value: 'PLN', label: 'PLN'},
                {value: 'PYG', label: 'PYG'},
                {value: 'QAR', label: 'QAR'},
                {value: 'RSD', label: 'RSD'},
                {value: 'RWF', label: 'RWF'},
                {value: 'SAR', label: 'SAR'},
                {value: 'SBD', label: 'SBD'},
                {value: 'SCR', label: 'SCR'},
                {value: 'SDG', label: 'SDG'},
                {value: 'SGD', label: 'SGD'},
                {value: 'SHP', label: 'SHP'},
                {value: 'SLL', label: 'SLL'},
                {value: 'SOS', label: 'SOS'},
                {value: 'SRD', label: 'SRD'},
                {value: 'SSP', label: 'SSP'},
                {value: 'STD', label: 'STD'},
                {value: 'STN', label: 'STN'},
                {value: 'SVC', label: 'SVC'},
                {value: 'SYP', label: 'SYP'},
                {value: 'SZL', label: 'SZL'},
                {value: 'THB', label: 'THB'},
                {value: 'TJS', label: 'TJS'},
                {value: 'TMT', label: 'TMT'},
                {value: 'TND', label: 'TND'},
                {value: 'TOP', label: 'TOP'},
                {value: 'TRY', label: 'TRY'},
                {value: 'TTD', label: 'TTD'},
                {value: 'TWD', label: 'TWD'},
                {value: 'TZS', label: 'TZS'},
                {value: 'UAH', label: 'UAH'},
                {value: 'UGX', label: 'UGX'},
                {value: 'UYU', label: 'UYU'},
                {value: 'UZS', label: 'UZS'},
                {value: 'VES', label: 'VES'},
                {value: 'VND', label: 'VND'},
                {value: 'VUV', label: 'VUV'},
                {value: 'WST', label: 'WST'},
                {value: 'XAF', label: 'XAF'},
                {value: 'XAG', label: 'XAG'},
                {value: 'XAU', label: 'XAU'},
                {value: 'XCD', label: 'XCD'},
                {value: 'XDR', label: 'XDR'},
                {value: 'XOF', label: 'XOF'},
                {value: 'XPD', label: 'XPD'},
                {value: 'XPF', label: 'XPF'},
                {value: 'XPT', label: 'XPT'},
                {value: 'YER', label: 'YER'},
                {value: 'ZAR', label: 'ZAR'},
                {value: 'ZMW', label: 'ZMW'},
                {value: 'ZWL', label: 'ZWL'}
            ];
        }

        if (this.state.unitType === 'Distance') {
            return [
                {value: 'km', label: 'km'},
                {value: 'mi', label: 'mi'}
            ];
        }

        return [
            {value: 'cm', label: 'cm'},
            {value: 'inch', label: 'inch'}
        ];
    }

    render() {
        return (
            <Form.Group className='from-label-group allow-border'>
                <Row>
                    <Col xs={6}>
                        <Form.Label
                            htmlFor='inputBirthday'>{this.props.placeholder}{this.props.required ? '*' : ''}</Form.Label>
                        <Form.Control
                            type={typeof this.props.onlyNumber !== 'undefined' && this.props.onlyNumber ? 'number' : "text"}
                            id={this.props.id}
                            onChange={this.handleValueChange}
                            disabled={this.props.loading}
                            value={this.state.value}
                            className='input-foreground'
                        />
                    </Col>
                    <Col xs={6}>
                        <Form.Label><Translate id="model.chooseUnit"/></Form.Label>
                        <SelectPicker
                            block
                            searchable={false}
                            cleanable={false}
                            data={this.getDropDownData()}
                            value={this.state.valueType}
                            onChange={this.handleValueTypeChange}
                        />
                    </Col>
                    <Col xs={12}>
                        {this.state.error ?
                            <p className='text-danger m-0'>{this.state.error}</p>
                            :
                            null
                        }

                    </Col>
                </Row>
                {typeof this.state.adminComment[this.state.adminCommentType] !== 'undefined' ?
                    <Row className="text-danger">
                        <Col xs={6} lg={2}>
                            <b><Translate id="model.comment"/></b>
                        </Col>
                        <Col xs={6} lg={10}>
                            {this.state.adminComment[this.props.adminCommentType].message}
                        </Col>
                    </Row>
                    :
                    null
                }
            </Form.Group>

        );

    }
}
