import React, {Component} from "react";
import SedCard from "../../components/SedCard/SedCard";
import {Button, Container, Spinner} from "react-bootstrap";
import {connect} from "react-redux";
import {PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";
import Icofont from "../../components/Icofont/Icofont";
import {Translate} from "react-localize-redux";

class SedCardExaplePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showSedCardDownload: false,
            showSedCardLocale: this.props.user.Locale !== "undefined" && this.props.user.Locale ? this.props.user.Locale : 'de_DE'
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({loading: false})
        }, 5000)
    }

    directPDFDownload = () => {
        if (
            document.getElementById("download-sed-card") &&
            document.getElementById("download-sed-card").children[0]
        ) {
            if (!this.state.instaDownloadDone) {
                this.setState({instaDownloadDone: true}, () => {
                    setTimeout(function () {
                        document.getElementById("download-sed-card").children[0].click();
                    }, 100)
                })
            }

        }
    }

    render() {
        return (
            <Container>
                <div id='download-sed-card' className="d-flex justify-content-center mb-4">
                    {this.state.showSedCardDownload ?
                        <PDFDownloadLink
                            className="btn btn-primary small-btn ml-2"
                            document={<SedCard locale={this.state.showSedCardLocale}
                                               model={this.props.user}/>}
                            fileName={
                                "SedCard-" +
                                this.props.user.BookingCode + '-' +
                                Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8) +
                                ".pdf"
                            }
                        >
                            {({blob, url, loading, error}) => (
                                loading ?
                                    <div
                                        className="d-flex justify-content-center align-items-center">
                                        <Spinner animation="border" role="status"
                                                 className='mx-auto d-block align-self-center'>
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                        <Icofont icon="download" className="ml-2 mr-1"/>
                                        <Translate id="model.downloadSedCard"/>
                                    </div>
                                    :
                                    <>
                                        {url ?
                                            this.directPDFDownload()
                                            :
                                            null
                                        }
                                        <Icofont icon="download" className="mr-1"/>
                                        <Translate id="model.downloadSedCard"/>
                                    </>
                            )
                            }
                        </PDFDownloadLink>
                        :
                        <Button
                            variant="primary"
                            onClick={() => this.setState({showSedCardDownload: true})}
                            className="small-btn ml-2"
                        >
                            <Icofont icon="download" className="mr-1"/>
                            <Translate id="model.downloadSedCard"/>
                        </Button>
                    }
                </div>
                <div className="pdf-iframe-holder">
                    <PDFViewer>
                        <SedCard model={this.props.user}/>
                    </PDFViewer>
                </div>
            </Container>
        )
    }

}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    memberID: state.user.memberID,
    authKey: state.user.authKey,
    user: state.user.user,
});


export default connect(
    mapStateToProps,
    null,
)(SedCardExaplePage);
