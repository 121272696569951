import React, {Component} from "react";
import {Alert, Button, Col, Container, Image, Row, Spinner} from "react-bootstrap";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import {getTranslate, Translate} from "react-localize-redux";
import CreditCardImage from "../../assets/img/payment_creditcard.png"
import CreditCardImagePlatin from "../../assets/img/payment_creditcard_platin.png"
import SofortImage from "../../assets/img/payment_sofort.png"
import SofortImagePlatin from "../../assets/img/payment_sofort_platin.png"
import EPSImage from "../../assets/img/payment_eps.png"
import EPSImagePlatin from "../../assets/img/payment_eps_platin.png"
import ApplePayImage from "../../assets/img/payment_applepay.png"
import ApplePayImagePlatin from "../../assets/img/payment_applepay_platin.png"
import GiropayImage from "../../assets/img/payment_giropay.png"
import GiropayImagePlatin from "../../assets/img/payment_giropay_platin.png"
import BancontactImage from "../../assets/img/payment_bancontact.png"
import BancontactImagePlatin from "../../assets/img/payment_bancontact_platin.png"
import MultibancoImage from "../../assets/img/payment_multibanco.png"
import MultibancoImagePlatin from "../../assets/img/payment_multibanco_platin.png"
import P24Image from "../../assets/img/payment_przelewy24.png"
import P24ImagePlatin from "../../assets/img/payment_przelewy24_platin.png"
import IdealImage from "../../assets/img/payment_ideal.png"
import IdealImagePlatin from "../../assets/img/payment_ideal_platin.png"
import SepaImage from "../../assets/img/payment_sepa.png"
import SepaImagePlatin from "../../assets/img/payment_sepa_platin.png"
import MircosoftPayImage from "../../assets/img/payment_microsoftwallet.png"
import MircosoftPayImagePlatin from "../../assets/img/payment_microsoftwallet_platin.png"
import GooglePayImage from "../../assets/img/payment_googlepay.png"
import GooglePayImagePlatin from "../../assets/img/payment_googlepay_platin.png"
import axios from "axios";
import UserActions from "../../stores/user/actions";

class Payment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activePayment: '',
            showPaymentSite: false,
            countryPaymentAmount: 0,
            stripePaymentAmount: 0,
            paymentURL: '',
            paymentHTML: '',
            paymentScript: '',
            loading: true,
            modalOpen: false,
            showPaymentData: false,
            showSofort: true,
            paymentError: '',
            error: ''
        }
        this.fetchLatestCurrencies = this.fetchLatestCurrencies.bind(this)
        this.convertCurrency = this.convertCurrency.bind(this)
    }

    componentDidMount() {
        this.props.fetchUser();
        this.renderOnSitePayment();
        if (this.props.memberID && this.props.user.ClassName === "CIC\\TopModelCast\\TopModel\\TopModel" && this.props.user.Status === "PaymentPending") {
            this.setState({
                stripePaymentAmount: this.props.user.StripePaymentAmount
            })
            if (this.props.user.PriceType && this.props.user.StripePaymentAmount && this.props.user.PriceType !== "EUR") {
                this.fetchLatestCurrencies("EUR", this.props.user.PriceType, this.props.user.StripePaymentAmount)
            }
            this.setState({
                showPaymentSite: true
            })
            ApiService.authorizedClient(this.props.authKey).get('TopModel/' + this.props.memberID)
                .then((response) => {
                    this.setState({
                        showPaymentData: response.data.ShowPaymentOption,
                        showPaymentSite: true
                    })
                }).catch((error) => {
                console.log(error)
            })

        }
    }

    renderOnSitePayment = () => {
        ApiService.authorizedClient(this.props.authKey)
            .get('/TopModel/' + this.props.memberID + '/initPayment?locale='+this.props.locale)
            .then((result) => {
                this.setState({
                    paymentHTML: result.data.html,
                    paymentScript: result.data.script,
                    loading: false,
                })
        }).catch(error => {
            console.log(error.response.data.message,)
            this.setState({
                error: this.props.translate('payment.alreadyPaymentDone'),
                loading: false,
            })
        })
    }


    fetchLatestCurrencies(base, to, amount) {
        let url = "https://api.exchangerate.host/latest?base=" + base;
        axios.get(url).then((result) => {
            if (typeof result.data.rates !== "undefined") {
                this.setState({
                    currencyBase: base,
                    currencyRates: result.data.rates,
                    stripePaymentAmount: amount
                }, () => this.convertCurrency(to, amount))
            }
        })

    }

    convertCurrency(to, amount) {
        let val = amount
        if (typeof this.state.currencyRates[to] !== "undefined") {
            val = Math.ceil(val * this.state.currencyRates[to]);
            this.setState({countryPaymentAmount: val.toFixed(2).replace('.00','')});
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.paymentScript === "" && this.state.paymentScript !== "") {
            // eslint-disable-next-line no-new-func
            const executeScript = new Function(this.state.paymentScript);
            executeScript();
        }
        if(this.props.locale !== prevProps.locale){
            this.renderOnSitePayment()
        }
        if (this.props.user.Status !== prevProps.user.Status) {
            if (this.props.user.Status !== 'PaymentPending') {
                this.setState({
                    showPaymentSite: false
                })
            } else {
                this.setState({
                    showPaymentSite: true
                })
            }

        }
    }


    render() {

        return (
            <Container>
                {this.state.showPaymentSite ?
                    <div className="full-height-center d-flex align-items-center payment-container">
                        <section className="w-100">
                            <div className="text-center">
                                <h3 className="text-primary"><Translate id="payment.title"/></h3>
                                <p><Translate id="payment.desc"/></p>
                                <br/>
                                {this.state.countryPaymentAmount && this.props.user.PriceType !== 'EUR' ?
                                    <>
                                        <p>
                                            <Translate id={"payment.otherCurrencyText"}/>
                                            <b>Euro {this.state.stripePaymentAmount.toFixed(2).replace('.00','')}</b> <Translate
                                            id={"payment.otherCurrencyText2"}/>
                                            <b>{this.props.user.PriceType} {this.state.countryPaymentAmount} </b>
                                        </p>
                                        <br/>
                                    </>
                                    :
                                    <>
                                        <p>
                                            <Translate id={"payment.otherCurrencyText"}/> <Translate id={"payment.otherCurrencyText3"}/>
                                            <b>Euro {this.state.stripePaymentAmount.toFixed(2).replace('.00','')}</b>
                                        </p>
                                        <br/>
                                    </>
                                }
                            </div>


                            <Row>
                                <Col id="payment-holder" lg={12}
                                     className="d-flex justify-content-center flex-column align-items-center">
                                    <div
                                        className="my-3 payment-holder w-100 d-flex flex-column align-items-center justify-content-center"
                                    >
                                        {!this.state.activePayment ?
                                            <p className="mb-3"><Translate id="payment.pleaseChoose"/></p>
                                            :
                                            this.props.user.PaymentProcessing ?
                                                !this.state.showPaymentData ?
                                                    <Alert variant="info" className="text-center">
                                                        <b>
                                                            <Translate
                                                                id="payment.showPaymentData"
                                                                options={{renderInnerHtml: true}}
                                                            />
                                                        </b>
                                                    </Alert>
                                                    :
                                                    null
                                                    // <Alert variant="info" className="text-center">
                                                    //     <b>
                                                    //         <Translate
                                                    //             id="payment.alreadyOpenPayment"
                                                    //             options={{renderInnerHtml: true}}
                                                    //         />
                                                    //     </b>
                                                    // </Alert>
                                                :
                                                null
                                        }
                                        {this.state.loading ?
                                            <Spinner animation="border" role="status"
                                                     className='mx-auto d-block align-self-center'>
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                            :
                                            null
                                        }
                                        <div className="w-100">
                                            {this.state.error ?
                                                <Alert variant="info" className="text-center">
                                                    {this.state.error}
                                                </Alert>
                                                :
                                                null
                                            }
                                            {this.state.paymentHTML && this.state.showPaymentData ?
                                                <div
                                                    dangerouslySetInnerHTML={{__html: this.state.paymentHTML}}
                                                />
                                                :
                                                null
                                            }
                                        </div>
                                    </div>

                                </Col>
                                <Col xs={12} id="scroll-to" className="py-5 my-4"></Col>
                            </Row>

                        </section>
                    </div>
                    :
                    <Alert variant="danger">
                        <Translate id="payment.noPermisson"/>
                    </Alert>
                }

            </Container>
        )
    }

}


const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    user: state.user.user,
    locale: state.locale.locale,
    translate: getTranslate(state.localize)
});

const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Payment));
