import React, {Component} from "react";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import ModelList from "../../components/ModelList/ModelList";
import {connect} from "react-redux";
import {getTranslate, Translate} from "react-localize-redux";
import "./ModelTab.style.scss"
import queryString from 'query-string';

class ModelTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offset:0,
            models: [],
            loading: true,
            searchTerm: ''
        }

        this.fetchModels = this.fetchModels.bind(this);
        this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
        this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
    }

    componentDidMount() {
        let params = queryString.parse(window.location.search)
        if (typeof params.offset !== 'undefined') {
            this.setState({
                offset: parseInt(params.offset),
            }, ()=>  this.fetchModels())
        }else{
            this.fetchModels();
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.offset != this.state.offset){
            this.fetchModels();
        }
    }

    fetchModels() {
        let params = {
            "filter[Status]": "ApprovalPending",
            "filter[AlreadyGotFeedBack]": '0',
            "sort": "SendToApprovalDate ASC",
            "limit" :30,
            "offset" :this.state.offset
        }
        if (this.state.searchTerm) {
            params = {
                "filter[Status]": "ApprovalPending",
                "filter[AlreadyGotFeedBack]": '0',
                "sort": "SendToApprovalDate ASC",
                "limit" :30,
                "offset" :this.state.offset,
                "filterAny[FirstName:PartialMatch]": this.state.searchTerm,
                "filterAny[Surname:PartialMatch]": this.state.searchTerm,
                "filterAny[Email:PartialMatch]": this.state.searchTerm,
                "filterAny[BookingCode:PartialMatch]": this.state.searchTerm,
            }
        }

        ApiService.authorizedClient(this.props.authKey).get(
            'TopModel/',
            {
                params
            }
        )
            .then((response) => {
                this.setState({
                    models: response.data,
                    loading: false
                })

            }).catch((error) => {
            this.setState({
                loading: false
            })
            let errorMsg = this.props.translate("error.unknown");
            if (error.response && error.response.data && error.response.data.message) {
                errorMsg = error.response.data.message
            }
            toastr.error(this.props.translate("toastr.error"), errorMsg)
        })
    }

    handleSearchTermChange(e) {
        this.setState({searchTerm: e.target.value})
    }

    handleSearchButtonClick(e) {
        window.history.pushState({}, null, '/')
        this.setState({loading: true,offset:0}, () => this.fetchModels())
    }

    updateURL = () => {
        let newUrl = window.location.pathname + '?offset=' + this.state.offset;
        window.history.pushState({}, null, newUrl)
    }

    render() {
        return (
            <section className="py-4 mb-3">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="text-center">
                                <h3 className="pb-4 text-primary"><Translate id="adminDashboard.approvalModelHeader"/>
                                </h3>
                            </div>
                        </Col>
                        <Col xs={12}>
                            {this.state.loading ?
                                <Spinner animation="border" role="status"
                                         className='mx-auto d-block align-self-center'>
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                                :
                                <>
                                    <div className="model-search d-md-flex">
                                        <Form.Control
                                            type="text"
                                            id="model-search"
                                            placeholder="Suche"
                                            onChange={this.handleSearchTermChange}
                                            disabled={this.props.loading}
                                            value={this.state.searchTerm}
                                            className='input-foreground'
                                        />
                                        <Button className="btn-black" onClick={this.handleSearchButtonClick}>
                                            <Translate id="adminDashboard.searchButton"/>
                                        </Button>
                                    </div>
                                    <ModelList models={this.state.models} showApproveLink={true}/>
                                    <Row>
                                        <Col xs={4}>
                                            {this.state.offset >= 30 ?
                                                <Button onClick={() => this.setState({loading:true,offset: parseInt(this.state.offset)-30}, () => this.updateURL())}>Zurück</Button>
                                                :
                                                null
                                            }

                                        </Col>
                                        <Col xs={4} className="d-flex justify-content-center align-items-center">
                                            <p>Seite: {(this.state.offset / 30)+1}</p>
                                        </Col>
                                        <Col xs={4} className="d-flex justify-content-end">
                                            {this.state.models.length < 30 ?
                                                null
                                                :
                                                <Button onClick={() => this.setState({loading:true,offset: parseInt(this.state.offset)+30}, () => this.updateURL())}>Weiter</Button>
                                            }
                                        </Col>
                                    </Row>
                                </>
                            }

                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }

}


const mapStateToProps = (state) => ({
    authKey: state.user.authKey,
    translate: getTranslate(state.localize)
});

export default connect(
    mapStateToProps,
    null
)(ModelTab);
