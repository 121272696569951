import React, {Component} from "react";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {HTMLService} from "../../services/HTMLService";
import {SelectPicker, TagPicker} from "rsuite";
import {getTranslate, Translate} from "react-localize-redux";
import {AnimatePresence, motion} from "framer-motion";
import UnitNumberInputField from "../UnitNumberInputField/UnitNumberInputField";
import UploadField from "../UploadField/UploadField";
import RadiusMap from "../RadiusMap/RadiusMap";
import {UnitConvertService} from "../../services/UnitConvertionService";
import ClothingsizeDropdown from "../ClothingsizeDropdown/ClothingsizeDropdown";
import {de} from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Checkbox from "@material-ui/core/Checkbox";
import options from '../../assets/country-data-native.json'

class ModelRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBodyMeasurements: false,
            clothingSizesForDropdown: UnitConvertService.clothingSizeAll,
            homeText: '',
            page: 0,
            age: 216,
            languages: '',
            showPage: true,
            locale: this.props.locale ?? 'de_DE',
            mainImg: {},
            mainImgID: 0,
            images: [],
            firstName: '',
            firstNameError: '',
            surname: '',
            surnameError: '',
            email: '',
            emailChecked: false,
            emailError: '',
            emailRepeat: '',
            emailRepeatError: '',
            height: '',
            heightSE: '',
            heightError: false,
            heightType: 'cm',
            weight: '',
            weightSE: '',
            weightType: 'kg',
            weightError: false,
            shoeSize: '',
            shoeSizeSE: '',
            shoeSizeType: 'EU',
            shoeSizeError: false,
            chest: '',
            chestSE: '',
            chestType: 'cm',
            price: '',
            priceSE: '',
            priceType: 'EUR',
            priceError: false,
            worldWide: false,
            searchRadius: '',
            searchRadiusSE: '',
            searchRadiusSETriggerChange: '',
            searchRadiusType: 'km',
            chestError: false,
            waist: '',
            waistSE: '',
            waistType: 'cm',
            waistError: false,
            clothingSize: '',
            hip: '',
            hipSE: '',
            hipType: 'cm',
            hipError: false,
            gender: '',
            hairColor: '',
            eyeColor: '',
            skinType: '',
            skinColor: '',
            desc: '',
            birthDate: null,
            birthDateError: '',
            parentName: '',
            phone: '',
            promoCode: '',
            promoCodeError: '',
            addressID: 0,
            address: {},
            addressError: '',
            addressTitle: '',
            stepOneError: '',
            stepTwoError: '',
            stepThreeError: '',
            firstNameShowRequired: '',
            surnameShowRequired: '',
            emailShowRequired: '',
            emailRepeatShowRequired: '',
            birthDateShowRequired: '',
            parentNameShowRequired: '',
            genderShowRequired: '',
            heightShowRequired: '',
            weightShowRequired: '',
            addressShowRequired: '',
            searchRadiusShowRequired: '',
            phoneShowRequired: '',
            martialStatus: this.props.locale === 'de_DE' ? '' : 'None',
        }

        this.prev = this.prev.bind(this)
        this.next = this.next.bind(this)
        this.submitModelRegistration = this.submitModelRegistration.bind(this)
        this.handleParentNameChange = this.handleParentNameChange.bind(this)
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this)
        this.handleSurnameChange = this.handleSurnameChange.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handleEmailRepeatChange = this.handleEmailRepeatChange.bind(this)
        this.handleBirthdayChange = this.handleBirthdayChange.bind(this)
        this.handleGenderChange = this.handleGenderChange.bind(this)
        this.handleHeightChange = this.handleHeightChange.bind(this)
        this.handleHeightTypeChange = this.handleHeightTypeChange.bind(this)
        this.handleWeightChange = this.handleWeightChange.bind(this)
        this.handleWeightTypeChange = this.handleWeightTypeChange.bind(this)
        this.handleChestChange = this.handleChestChange.bind(this)
        this.handleChestTypeChange = this.handleChestTypeChange.bind(this)
        this.handleWaistChange = this.handleWaistChange.bind(this)
        this.handleWaistTypeChange = this.handleWaistTypeChange.bind(this)
        this.handleHipChange = this.handleHipChange.bind(this)
        this.handleHipTypeChange = this.handleHipTypeChange.bind(this)
        this.handleShoeSizeChange = this.handleShoeSizeChange.bind(this)
        this.handleShoeSizeTypeChange = this.handleShoeSizeTypeChange.bind(this)
        this.handleClothingSizeChange = this.handleClothingSizeChange.bind(this)
        this.handleHairColorChange = this.handleHairColorChange.bind(this)
        this.handleEyeColorChange = this.handleEyeColorChange.bind(this)
        this.handleSkinTypeChange = this.handleSkinTypeChange.bind(this)
        this.handleSkinColorChange = this.handleSkinColorChange.bind(this)
        this.handleDescChange = this.handleDescChange.bind(this)
        this.setAddress = this.setAddress.bind(this)
        this.handleSearchRadiusChange = this.handleSearchRadiusChange.bind(this)
        this.handleSearchRadiusTypeChange = this.handleSearchRadiusTypeChange.bind(this)
        this.handlePriceChange = this.handlePriceChange.bind(this)
        this.handlePriceTypeChange = this.handlePriceTypeChange.bind(this)
        this.handleMainImgStatusChange = this.handleMainImgStatusChange.bind(this)
        this.handleMainImgDelete = this.handleMainImgDelete.bind(this)
        this.handleImageStatusChange = this.handleImageStatusChange.bind(this)
        this.handleImagesDelete = this.handleImagesDelete.bind(this)
        this.handlePromoCodeChange = this.handlePromoCodeChange.bind(this)
        this.setSearchRadius = this.setSearchRadius.bind(this);
        this.setSearchRadiusTrigger = this.setSearchRadiusTrigger.bind(this);
        this.checkPromoCode = this.checkPromoCode.bind(this);
        this.pageOneCompleteFinish = this.pageOneCompleteFinish.bind(this);
        this.handleAddressTimeout = this.handleAddressTimeout.bind(this);
        this.updateLatLong = this.updateLatLong.bind(this);
        this.handleMartialStatusChange = this.handleMartialStatusChange.bind(this);
    }

    showMaritalStatus() {
        let show = this.props.locale === 'de_DE';
        let birthdate;

        try {
            birthdate = new Date(this.state.birthDate);
        } catch (e) {
            return show;
        }

        // 31557600000 = 24 * 3600 * 365.25 * 1000 = year
        let age = Math.floor((new Date() - birthdate) / 31557600000);
        return show && age >= 18;
    }

    handleMartialStatusChange(val) {
        this.setState({ martialStatus: val });
    }

    handleRadiusTimeout = () => {
        clearTimeout(this.radiusTimeout);
        this.radiusTimeout = setTimeout(() => this.setSearchRadiusTrigger(), 1000);
    }

    handleAddressTimeout = () => {
        clearTimeout(this.AddressTimeout);
        this.AddressTimeout = setTimeout(() => this.updateLatLong(), 1000);
    }
    updateLatLong = () => {
        let query = this.state.address.Street + ' ' + this.state.address.Number +
            ', ' + this.state.address.PostalCode + ' ' + this.state.address.City + ', ' +
            this.state.address.Country;

        ApiService.geocodeFromAddress(query)
            .then((response) => {
                if (response.data?.results[0]?.geometry?.location) {
                    this.setState({
                        address: {
                            ...this.state.address,
                            Latitude: response.data.results[0].geometry.location.lat,
                            Longitude: response.data.results[0].geometry.location.lng
                        }
                    })
                }
            })
            .catch((error) => {
                let errorMsg = this.props.translate("error.unknown");
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message;
                }
                console.log(errorMsg);
                //toastr.error(this.props.translate("toastr.error"), errorMsg)
            })
    }

    setSearchRadiusTrigger() {
        this.setState({searchRadiusSETriggerChange: this.state.searchRadiusSE})
    }

    componentDidMount() {
        // window.gtag('event', 'conversion', {'send_to': 'AW-461926465/9e0rCJbl0-0BEMHgodwB'});
        this.fetchHomePageData()
    }

    setSearchRadius(radius) {
        let val = (radius / 1000).toFixed(2);
        let valSE = (radius / 1000).toFixed(2);
        if (this.state.searchRadiusType === "mi") {
            val = (val / 1.60934).toFixed(2);
        }
        this.setState({
            searchRadius: val,
            searchRadiusSE: valSE,
            worldWide: false,
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.searchRadiusSE !== prevState.searchRadiusSE) {
            this.handleRadiusTimeout();
        }

        if (this.props.locale !== prevProps.locale && this.props.locale !== 'de_DE') {
            this.setState({
                martialStatus: 'None'
            });
        }

        if (
            prevState.address.Latitude === this.state.address.Latitude &&
            prevState.address.Longitude === this.state.address.Longitude &&
            this.state.address.Street &&
            this.state.address.Number &&
            this.state.address.PostalCode &&
            this.state.address.City &&
            this.state.address.Country &&
            JSON.stringify(prevState.address) !== JSON.stringify(this.state.address)
        ) {
            this.handleAddressTimeout();
        }
        if (this.props.locale !== this.state.locale) {
            this.setState({
                locale: this.props.locale
            }, () => this.fetchHomePageData())
        }
        if ((prevState.age !== this.state.age) || (prevState.gender !== this.state.gender)) {
            if (this.state.age >= (14 * 12)) {
                this.setState({
                    clothingSizesForDropdown: UnitConvertService.clothingSizeEUAdult
                })
            } else {
                this.setState({
                    clothingSizesForDropdown: UnitConvertService.clothingSizeEUMinor
                })
            }
            if (this.state.age >= (14 * 12) && this.state.gender === "female") {
                this.setState({
                    showBodyMeasurements: true,
                })
            } else {
                this.setState({
                    showBodyMeasurements: false,
                    chest: '',
                    chestSE: '',
                    waist: '',
                    waistSE: '',
                    hip: '',
                    hipSE: '',
                })
            }
        }
    }

    fetchHomePageData() {
        ApiService.clientLocalized(this.state.locale).get('/SiteConfig/1')
            .then((response) => {
                this.setState({
                    homeText: response.data.AppHomeTextModel ?? '',
                })

            })
            .catch((error) => {
                let errorMsg = this.props.translate("error.unknown");
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message;
                }
                console.log(errorMsg);
                //toastr.error(this.props.translate("toastr.error"), errorMsg)
            })
    }

    thankYouContent() {
        return (
            <Col md={12} className='text-center'>
                <h1 className="pb-4 alternative-font font-size-big"><Translate id="registerForm.super"/></h1>
                <p className="pb-5">
                    <Translate id="registerForm.thankYouModel"/>
                </p>
            </Col>
        )
    }

    calcAge(birthDate) {
        let today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        age = age * 12 + m;
        this.setState({
            age: age
        })
    }

    handleFirstNameChange(e) {
        let text = e.target.value;
        this.setState({firstName: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({firstNameError: <Translate id="error.wrongFirstName"/>, disableButton: true,});
        } else {
            this.setState({firstNameError: ''});
        }
    }

    handleParentNameChange(e) {
        let text = e.target.value;
        this.setState({parentName: text});
    }

    handleSurnameChange(e) {
        let text = e.target.value;
        this.setState({surname: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({surnameError: <Translate id="error.wrongSurname"/>, disableButton: true,});
        } else {
            this.setState({surnameError: ''});
        }
    }

    handlePromoCodeChange(e) {
        let text = e.target.value;
        this.setState({promoCode: text});
    }

    handleEmailChange(e) {
        let text = e.target.value;
        this.setState({email: text,emailChecked: false});
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //let emailValidation = text.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
        if (!re.test(text)) {
            this.setState({emailError: <Translate id="error.emailError"/>});
        } else {
            this.setState({emailError: ''});
            ApiService.client.get('Member/checkEmail?email=' + text)
                .then((result) => {
                    if (!result.data.EmailOK) {
                        this.setState({emailError: <Translate id="error.emailAlreadyExists"/>});
                    }
                    this.setState({emailChecked: true});
                })
                .catch(error => console.log(error))

        }
        if (text && this.state.emailRepeat && text !== this.state.emailRepeat) {
            this.setState({emailRepeatError: <Translate id="error.emailRepeatError"/>});
        } else {
            this.setState({emailRepeatError: ''});
        }
    }

    handleEmailRepeatChange(e) {
        let text = e.target.value;
        this.setState({emailRepeat: text});
        if (text !== this.state.email) {
            this.setState({emailRepeatError: <Translate id="error.emailRepeatError"/>});
        } else {
            this.setState({emailRepeatError: ''});
        }
    }

    handleBirthdayChange(date) {
        if (date instanceof Date && isFinite(date)) {
            let currentDate = new Date();
            if (+date > +currentDate) {
                this.setState({
                    birthDateError: <Translate id="model.birthDateError"/>
                })
            } else {
                this.setState({birthDate: date, birthDateError: ''})
                this.calcAge(date)
            }
        } else {
            this.setState({
                birthDateError: <Translate id="model.birthDateError"/>
            })
        }
    }

    handleGenderChange(val) {
        this.setState({gender: val});
    }

    handleHeightChange(val, error, valSE) {
        this.setState({
            height: val,
            heightSE: valSE,
            heightError: error
        });
    }

    handleHeightTypeChange(val) {
        this.setState({heightType: val});
    }

    handleWeightChange(val, error, valSE) {
        this.setState({
            weight: val,
            weightSE: valSE,
            weightError: error
        });
    }

    handleWeightTypeChange(val) {
        this.setState({weightType: val});
    }

    handleChestChange(val, error, valSE) {
        this.setState({
            chest: val,
            chestSE: valSE,
            chestError: error
        });
    }

    handleWaistChange(val, error, valSE) {
        this.setState({
            waist: val,
            waistSE: valSE,
            waistError: error
        });
    }

    handleWaistTypeChange(val) {
        this.setState({waistType: val});
    }

    handleHipChange(val, error, valSE) {
        this.setState({
            hip: val,
            hipSE: valSE,
            hipError: error
        });
    }

    handleHipTypeChange(val) {
        this.setState({hipType: val});
    }

    handleChestTypeChange(val) {
        this.setState({chestType: val});
    }

    handleShoeSizeChange(val, error, valSE) {
        this.setState({
            shoeSize: val,
            shoeSizeSE: valSE,
            shoeSizeError: error
        });
    }

    handleShoeSizeTypeChange(val) {
        this.setState({shoeSizeType: val});
    }

    handleClothingSizeChange(val) {
        this.setState({
            clothingSize: val
        });
    }

    handleHairColorChange(val) {
        this.setState({hairColor: val});
    }

    handleEyeColorChange(val) {
        this.setState({eyeColor: val});
    }

    handleSkinTypeChange(val) {
        this.setState({skinType: val});
    }

    handleSkinColorChange(val) {
        this.setState({skinColor: val});
    }

    handleDescChange(e) {
        let text = e.target.value;
        let error = '';
        if (text.length > 500) {
            error = <Translate id="model.descError"/>
        }
        this.setState({
            desc: text,
            descError: error,
        });
    }

    setAddress(address) {
        let addressError = "";
        if (
            address.City === "" ||
            address.Street === "" ||
            address.Country === "" ||
            address.PostalCode === "" ||
            address.Number === ""
        ) {
            addressError = <Translate id="model.addressError"/>
        }
        this.setState({
            address: address,
            addressError: addressError,
            addressShowRequired: addressError ? 'showBorder' : ''
        })
    }

    handleSearchRadiusChange(val, error, valSE) {
        this.setState({
            searchRadius: val,
            searchRadiusSE: valSE,
            searchRadiusError: error
        });
    }

    handleSearchRadiusTypeChange(val) {
        this.setState({searchRadiusType: val});
    }

    handlePriceChange(val, error, valSE) {
        this.setState({
            price: val,
            priceSE: valSE,
            priceError: error
        });
    }

    handlePriceTypeChange(val) {
        this.setState({priceType: val});
    }

    handleMainImgStatusChange = (currentFiles) => {
        this.setState({mainImg: currentFiles[0]});
    }

    handleMainImgDelete(file, currentFiles) {
        this.setState({mainImg: {}});
    }

    handleImageStatusChange = (currentFiles) => {
        this.setState({images: currentFiles});
    }

    handleImagesDelete(file, currentFiles) {
        this.setState({images: currentFiles})

    }

    getPageContent() {
        switch (this.state.page) {
            default:
            case 0:
                return this.pageOneContent();
            case 1:
                return this.pageTwoContent();
            case 2:
                return this.pageThreeContent();
        }
    }

    getPageComplete() {
        switch (this.state.page) {
            default:
            case 0:
                return this.pageOneComplete();
            case 1:
                return this.pageTwoComplete();
            case 2:
                return this.pageThreeComplete();
        }
    }

    next() {
        if (this.getPageComplete()) {
            this.setState({
                page: this.state.page + 1,
                showPage: false,
            }, () => setTimeout(() => {
                this.setState({showPage: true});
            }, 500))

            if (this.state.martialStatus === '' && !this.showMaritalStatus()) {
                this.setState({martialStatus: 'None'});
            }
        }
    }

    prev() {
        this.setState({
            page: this.state.page - 1,
            showPage: false,
        }, () => setTimeout(() => {
            this.setState({showPage: true});
        }, 500))

        if (this.state.martialStatus === '' && !this.showMaritalStatus()) {
            this.setState({martialStatus: 'None'});
        }
    }

    checkPromoCode() {
        ApiService.client.get('PromoCode/?filter[Code]=' + this.state.promoCode)
            .then((result) => {
                if (result.data.length && result.data[0].Used === null) {
                    this.setState({
                        promoCodeError: ""
                    }, () => {
                        if(this.pageOneCompleteFinish()){
                            this.setState({
                                page: this.state.page + 1,
                                showPage: false,
                            }, () => setTimeout(() => {
                                this.setState({showPage: true});
                            }, 500))
                        }

                    })
                } else {
                    if (result.data.length && result.data[0].Used !== null) {
                        this.setState({
                            promoCodeError: <Translate id="registerForm.promoCodeUsed"/>
                        }, () => {
                            this.pageOneCompleteFinish()
                        })
                    } else {
                        this.setState({
                            promoCodeError: <Translate id="registerForm.promoCodeNotFound"/>
                        }, () => {
                            this.pageOneCompleteFinish()
                        })
                    }

                }
            })
            .catch(error => console.log(error))
    }

    pageOneCompleteFinish() {
        if (
            this.state.firstNameError ||
            this.state.surnameError ||
            this.state.emailError ||
            this.state.emailRepeatError ||
            (this.state.promoCode.length > 0 && this.state.promoCodeError.length > 0)

        ) {
            this.setState({stepOneError: <Translate id="error.checkInput"/>})
            return false;
        }
        if (
            this.state.firstName.length <= 0 ||
            this.state.surname.length <= 0 ||
            this.state.email.length <= 0 ||
            this.state.phone.length <= 0 ||
            this.state.birthDate === null ||
            this.state.emailRepeat.length <= 0
        ) {
            let firstNameShowRequired = this.state.firstName.length <= 0 ? 'showBorder' : '';
            let surnameShowRequired = this.state.surname.length <= 0 ? 'showBorder' : '';
            let emailShowRequired = this.state.email.length <= 0 ? 'showBorder' : '';
            let emailRepeatShowRequired = this.state.emailRepeat.length <= 0 ? 'showBorder' : '';
            let phoneShowRequired = this.state.phone.length <= 0 ? 'showBorder' : '';
            let birthDateShowRequired = this.state.birthDate === null ? 'showBorder' : '';

            let parentNameShowRequired = '';
            if (this.state.age < 216) {
                parentNameShowRequired = this.state.parentName.length <= 0 ? 'showBorder' : '';
            }

            this.setState({
                firstNameShowRequired: firstNameShowRequired,
                surnameShowRequired: surnameShowRequired,
                emailShowRequired: emailShowRequired,
                emailRepeatShowRequired: emailRepeatShowRequired,
                birthDateShowRequired: birthDateShowRequired,
                parentNameShowRequired: parentNameShowRequired,
                phoneShowRequired: phoneShowRequired,
                stepOneError: <Translate id="error.enterAllFields"/>
            })
            return false;
        }
        if (this.state.age < 216 && this.state.parentName.length <= 0) {
            let firstNameShowRequired = this.state.firstName.length <= 0 ? 'showBorder' : '';
            let surnameShowRequired = this.state.surname.length <= 0 ? 'showBorder' : '';
            let emailShowRequired = this.state.email.length <= 0 ? 'showBorder' : '';
            let emailRepeatShowRequired = this.state.emailRepeat.length <= 0 ? 'showBorder' : '';
            let birthDateShowRequired = this.state.birthDate === null ? 'showBorder' : '';
            let parentNameShowRequired = this.state.parentName.length <= 0 ? 'showBorder' : '';
            let phoneShowRequired = this.state.phone.length <= 0 ? 'showBorder' : '';
            this.setState({
                firstNameShowRequired: firstNameShowRequired,
                surnameShowRequired: surnameShowRequired,
                emailShowRequired: emailShowRequired,
                emailRepeatShowRequired: emailRepeatShowRequired,
                birthDateShowRequired: birthDateShowRequired,
                parentNameShowRequired: parentNameShowRequired,
                phoneShowRequired: phoneShowRequired,
                stepOneError: <Translate id="error.enterAllFields"/>
            })
            return false;
        }

        if(!this.state.emailChecked){
            return false;
        }

        this.setState({
            firstNameShowRequired: '',
            surnameShowRequired: '',
            emailShowRequired: '',
            emailRepeatShowRequired: '',
            birthDateShowRequired: '',
            parentNameShowRequired: '',
            phoneShowRequired: '',
            stepOneError: ''
        });
        return true;
    }

    pageOneComplete() {
        return this.pageOneCompleteFinish()
        // if (this.state.promoCode.length > 0) {
        //     this.checkPromoCode()
        // } else {
        //     this.setState({promoCodeError: ''})
        //     return this.pageOneCompleteFinish()
        // }
    }


    pageOneContent() {
        return (
            <>
                <h3 className="text-primary mb-3"><Translate id="modelRegister.mainData"/></h3>
                <h4 className="mb-3"><Translate id="modelRegister.mainDataDesc"/></h4>
                <section className="model-reg-section">
                    <Row>
                        <Col lg={6}>
                            <Form.Group className={'from-label-group ' + this.state.firstNameShowRequired}>
                                <Form.Label htmlFor='inputFirstName'><Translate id="model.firstNameReg"/>*</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="inputFirstName"
                                    onChange={this.handleFirstNameChange}
                                    disabled={this.state.loading}
                                    value={this.state.firstName}
                                    className='input-foreground'
                                />
                                <p className='text-danger m-0'>{this.state.firstNameError}</p>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className={'from-label-group ' + this.state.surnameShowRequired}>
                                <Form.Label htmlFor='inputLastName'><Translate id="model.surnameReg"/>*</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="inputLastName"
                                    onChange={this.handleSurnameChange}
                                    disabled={this.state.loading}
                                    value={this.state.surname}
                                    className='input-foreground'
                                />
                                <p className='text-danger m-0'>{this.state.surnameError}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Form.Group className={'from-label-group ' + this.state.emailShowRequired}>
                                <Form.Label htmlFor='inputEmail'><Translate id="model.email"/>*</Form.Label>
                                <Form.Control
                                    dir="ltr"
                                    type="text"
                                    id="inputEmail"
                                    onChange={this.handleEmailChange}
                                    disabled={this.state.loading}
                                    value={this.state.email}
                                    className='input-foreground'
                                />
                                <p className='text-danger m-0'>{this.state.emailError}</p>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className={'from-label-group ' + this.state.emailRepeatShowRequired}>
                                <Form.Label htmlFor='inputEmailRepeat'><Translate id="model.emailRepeat"/>*</Form.Label>
                                <Form.Control
                                    dir="ltr"
                                    type="text"
                                    id="inputEmailRepeat"
                                    onChange={this.handleEmailRepeatChange}
                                    disabled={this.state.loading}
                                    value={this.state.emailRepeat}
                                    className='input-foreground'
                                />
                                <p className='text-danger m-0'>{this.state.emailRepeatError}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={this.state.age < 216 && this.state.birthDate ? 6 : 12}>
                            <Form.Group className={'from-label-group ' + this.state.birthDateShowRequired}>
                                <Form.Label htmlFor='inputBirthday'><Translate id="model.birthDate"/>*</Form.Label>
                                <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        maxDate={new Date()}
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="dd.MM.yyyy"
                                        value={this.state.birthDate}
                                        locale={"de-DE"}
                                        onChange={this.handleBirthdayChange}
                                        cancelLabel={<Translate id="model.birthDateCancel"/>}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                {this.state.birthDateError.length <= 0 ?
                                    <p className='text-muted m-0'><Translate id="model.birthDateFormat"/></p>
                                    :
                                    <p className='text-danger m-0'>{this.state.birthDateError}<br/><Translate
                                        id="model.birthDateFormat"/></p>
                                }

                            </Form.Group>
                        </Col>
                        {this.state.age < 216 && this.state.birthDate ?
                            <Col lg={6}>
                                <Form.Group className={'from-label-group ' + this.state.parentNameShowRequired}>
                                    <Form.Label htmlFor='inputParentName'><Translate
                                        id="model.parentName"/>*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="inputparentName"
                                        onChange={this.handleParentNameChange}
                                        disabled={this.state.loading}
                                        value={this.state.parentName}
                                        className='input-foreground'
                                    />
                                    <p className='text-danger m-0'></p>
                                </Form.Group>
                            </Col>
                            :
                            null
                        }
                    </Row>
                    <Row className="mt-4">
                        <div className="col-lg-12">
                            <Form.Group className={'from-label-group phone-group ' + this.state.phoneShowRequired}>
                                <Form.Label htmlFor='inputPhone'><Translate id="model.phone"/></Form.Label>
                                <div  dir="ltr">
                                    <PhoneInput
                                        country={'at'}
                                        value={this.state.phone}
                                        onChange={phone => this.setState({phone})}
                                        placeholder={'+43 1 23456'}
                                    />
                                </div>

                                <p className='text-danger m-0'></p>
                            </Form.Group>
                        </div>
                    </Row>
                    {/*<Row className="mt-4">*/}
                    {/*    <Col lg={6}>*/}
                    {/*        <Form.Group className='from-label-group'>*/}
                    {/*            <Form.Label htmlFor='inputPromoCode'><Translate id="model.promoCode"/></Form.Label>*/}
                    {/*            <Form.Control*/}
                    {/*                type="text"*/}
                    {/*                id="inputPromoCode"*/}
                    {/*                onChange={this.handlePromoCodeChange}*/}
                    {/*                disabled={this.state.loading}*/}
                    {/*                value={this.state.promoCode}*/}
                    {/*                className='input-foreground'*/}
                    {/*            />*/}
                    {/*            <p className='text-danger m-0'>{this.state.promoCodeError}</p>*/}
                    {/*        </Form.Group>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {this.state.stepOneError ?
                        <Row>
                            <Col>
                                <Alert variant="danger">{this.state.stepOneError}</Alert>
                            </Col>
                        </Row>
                        :
                        null
                    }
                </section>
            </>
        )
    }

    pageTwoComplete() {
        if (
            this.state.heightError ||
            this.state.weightError ||
            this.state.weightError ||
            this.state.chestError ||
            this.state.waistError ||
            this.state.hipError ||
            this.state.shoeSizeError ||
            this.state.searchRadiusError ||
            this.state.priceError ||
            this.state.descError
        ) {
            this.setState({stepTwoError: <Translate id="error.checkInput"/>})
            return false;
        }

        let checkAddresError = true;
        if(
            this.state.address?.Street &&
            this.state.address?.Number &&
            this.state.address?.Country &&
            this.state.address?.PostalCode
        ){
            checkAddresError = false;
        }
        if (
            this.state.gender.length <= 0 ||
            this.state.height.length <= 0 ||
            this.state.weight.length <= 0 ||
            this.state.searchRadius.length <= 0 ||
            checkAddresError
        ) {
            let genderShowRequired = this.state.gender.length <= 0 ? 'showBorder' : '';
            let heightShowRequired = this.state.height.length <= 0 ? 'showBorder' : '';
            let weightShowRequired = this.state.weight.length <= 0 ? 'showBorder' : '';
            let phoneShowRequired = this.state.phone.length <= 0 ? 'showBorder' : '';
            let addressShowRequired = typeof this.state.address.Title === "undefined" ? 'showBorder' : '';
            let searchRadiusShowRequired = this.state.searchRadius.length <= 0 ? 'showBorder' : '';
            this.setState({
                genderShowRequired: genderShowRequired,
                heightShowRequired: heightShowRequired,
                weightShowRequired: weightShowRequired,
                addressShowRequired: addressShowRequired,
                searchRadiusShowRequired: searchRadiusShowRequired,
                phoneShowRequired: phoneShowRequired,
                stepTwoError: <Translate id="error.enterAllRequiredFields"/>
            })
            return false;
        }
        this.setState({
            genderShowRequired: '',
            heightShowRequired: '',
            weightShowRequired: '',
            addressShowRequired: '',
            searchRadiusShowRequired: '',
            phoneShowRequired: '',
            stepTwoError: ''
        });
        return true;
    }

    pageTwoContent() {
        return (
            <>
                <h3 className="text-primary mb-3"><Translate id="modelRegister.modelData"/></h3>
                <section className="model-reg-section">
                    <Row>
                        <Col lg={6}>
                            <Form.Group className={'from-label-group ' + this.state.genderShowRequired}>
                                <Form.Label htmlFor='inputGender'><Translate id="model.gender"/>*</Form.Label>
                                <SelectPicker
                                    block
                                    placeholder={<Translate id="model.pleaseChoose"/>}
                                    searchable={false}
                                    cleanable={false}
                                    data={[
                                        {value: 'female', label: <Translate id="model.gender_female"/>},
                                        {value: 'male', label: <Translate id="model.gender_male"/>}
                                    ]}
                                    value={this.state.gender}

                                    onChange={this.handleGenderChange}
                                />
                            </Form.Group>
                        </Col>
                        {this.showMaritalStatus() && (
                            <Col lg={6}>
                                <Form.Group className={"from-label-group "}>
                                    <Form.Label htmlFor="inputMartialStatus">
                                        <Translate id="model.martialStatus" />
                                    </Form.Label>
                                    <SelectPicker
                                        block
                                        placeholder={<Translate id="model.pleaseChoose" />}
                                        searchable={false}
                                        cleanable={false}
                                        data={[
                                            {
                                                value: "None",
                                                label: <Translate id="model.martialStatus_none" />,
                                            },
                                            {
                                                value: "Single",
                                                label: <Translate id="model.martialStatus_single" />,
                                            },
                                            {
                                                value: "Beziehung",
                                                label: <Translate id="model.martialStatus_beziehung" />,
                                            },
                                            {
                                                value: "Verheiratet",
                                                label: <Translate id="model.martialStatus_verheiratet" />,
                                            },
                                        ]}
                                        value={this.state.martialStatus}
                                        onChange={this.handleMartialStatusChange}
                                    />
                                </Form.Group>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Form.Group className={'unit-input ' + this.state.heightShowRequired}>
                                <UnitNumberInputField
                                    id="inputHeight"
                                    placeholder={<Translate id="model.height"/>}
                                    required={true}
                                    errorMessage={<Translate id="model.noValidValue"/>}
                                    value={this.state.height}
                                    loading={this.state.loading}
                                    valueType={this.state.heightType}
                                    unitType='Size'
                                    handelValueSet={(val, error, valSE) => this.handleHeightChange(val, error, valSE)}
                                    handleTypeSet={(val) => this.handleHeightTypeChange(val)}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className={'unit-input ' + this.state.weightShowRequired}>
                                <UnitNumberInputField
                                    id="inputWeight"
                                    placeholder={<Translate id="model.weight"/>}
                                    required={true}
                                    errorMessage={<Translate id="model.noValidValue"/>}
                                    value={this.state.weight}
                                    loading={this.state.loading}
                                    valueType={this.state.weightType}
                                    unitType='Weight'
                                    handelValueSet={(val, error, valSE) => this.handleWeightChange(val, error, valSE)}
                                    handleTypeSet={(val) => this.handleWeightTypeChange(val)}
                                />
                            </Form.Group>
                        </Col>
                        {this.state.showBodyMeasurements ?
                            <>
                                <Col lg={4}>
                                    <Form.Group>
                                        <UnitNumberInputField
                                            id="inputChest"
                                            placeholder={<Translate id="model.chest"/>}
                                            errorMessage={<Translate id="model.noValidValue"/>}
                                            value={this.state.chest}
                                            loading={this.state.loading}
                                            valueType={this.state.chestType}
                                            unitType='Size'
                                            handelValueSet={(val, error, valSE) => this.handleChestChange(val, error, valSE)}
                                            handleTypeSet={(val) => this.handleChestTypeChange(val)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group>
                                        <UnitNumberInputField
                                            id="inputWaist"
                                            placeholder={<Translate id="model.waist"/>}
                                            errorMessage={<Translate id="model.noValidValue"/>}
                                            value={this.state.waist}
                                            loading={this.state.loading}
                                            valueType={this.state.waistType}
                                            unitType='Size'
                                            handelValueSet={(val, error, valSE) => this.handleWaistChange(val, error, valSE)}
                                            handleTypeSet={(val) => this.handleWaistTypeChange(val)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group>
                                        <UnitNumberInputField
                                            id="inputHip"
                                            placeholder={<Translate id="model.hip"/>}
                                            errorMessage={<Translate id="model.noValidValue"/>}
                                            value={this.state.hip}
                                            loading={this.state.loading}
                                            valueType={this.state.hipType}
                                            unitType='Size'
                                            handelValueSet={(val, error, valSE) => this.handleHipChange(val, error, valSE)}
                                            handleTypeSet={(val) => this.handleHipTypeChange(val)}
                                        />
                                    </Form.Group>
                                </Col>
                            </>
                            :
                            null
                        }
                        <Col lg={6}>
                            <Form.Group>
                                <UnitNumberInputField
                                    id="inputShoeSize"
                                    placeholder={<Translate id="model.shoesize"/>}
                                    errorMessage={<Translate id="model.noValidValue"/>}
                                    value={this.state.shoeSize}
                                    loading={this.state.loading}
                                    valueType={this.state.shoeSizeType}
                                    gender={this.state.gender}
                                    unitType='Shoe'
                                    handelValueSet={(val, error, valSE) => this.handleShoeSizeChange(val, error, valSE)}
                                    handleTypeSet={(val) => this.handleShoeSizeTypeChange(val)}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className='from-label-group'>
                                <Form.Label htmlFor='inputClothingSize'>
                                    <Translate id="model.clothingSize"/>
                                </Form.Label>
                                <ClothingsizeDropdown
                                    age={this.state.age}
                                    value={this.state.clothingSize}
                                    handleClothingSizeChange={this.handleClothingSizeChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className='from-label-group'>
                                <Form.Label htmlFor='inputHairColor'><Translate id="model.hair"/></Form.Label>
                                <SelectPicker
                                    block
                                    id='inputHairColor'
                                    placeholder={<Translate id="model.pleaseChoose"/>}
                                    searchable={false}
                                    cleanable={false}
                                    data={[
                                        {value: 'blond', label: <Translate id="color.c_blond"/>},
                                        {value: 'brunette', label: <Translate id="color.c_brunette"/>},
                                        {value: 'brown', label: <Translate id="color.c_brown"/>},
                                        {value: 'red', label: <Translate id="color.c_red"/>},
                                        {value: 'black', label: <Translate id="color.c_black"/>},
                                        {value: 'gray', label: <Translate id="color.c_gray"/>},
                                        {value: 'other', label: <Translate id="color.c_other"/>}
                                    ]}
                                    value={this.state.hairColor}
                                    onChange={this.handleHairColorChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className='from-label-group'>
                                <Form.Label htmlFor='inputEyeColor'><Translate id="model.eyes"/></Form.Label>
                                <SelectPicker
                                    block
                                    id='inputEyeColor'
                                    placeholder={<Translate id="model.pleaseChoose"/>}
                                    searchable={false}
                                    cleanable={false}
                                    data={[
                                        {value: 'blue', label: <Translate id="color.c_blue"/>},
                                        {value: 'green', label: <Translate id="color.c_green"/>},
                                        {value: 'brown', label: <Translate id="color.c_brown"/>},
                                        {value: 'gray', label: <Translate id="color.c_gray"/>},
                                        {value: 'other', label: <Translate id="color.c_other"/>}
                                    ]}
                                    value={this.state.eyeColor}
                                    onChange={this.handleEyeColorChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className='from-label-group'>
                                <Form.Label htmlFor='inputSkinType'>
                                    <Translate id="model.skintype"/>
                                </Form.Label>
                                <SelectPicker
                                    block
                                    id='inputSkinType'
                                    placeholder={<Translate id="model.pleaseChoose"/>}
                                    searchable={false}
                                    cleanable={false}
                                    data={[
                                        {value: 'european', label: <Translate id="skintype.st_european"/>},
                                        {value: 'asian', label: <Translate id="skintype.st_asian"/>},
                                        {value: 'african', label: <Translate id="skintype.st_african"/>},
                                        {value: 'other', label: <Translate id="skintype.st_other"/>}
                                    ]}
                                    value={this.state.skinType}
                                    onChange={this.handleSkinTypeChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className='from-label-group'>
                                <Form.Label htmlFor='inputSkinColor'><Translate id="model.skincolor"/></Form.Label>
                                <SelectPicker
                                    block
                                    id='inputSkinColor'
                                    placeholder={<Translate id="model.pleaseChoose"/>}
                                    searchable={false}
                                    cleanable={false}
                                    data={[
                                        {value: 'light', label: <Translate id="skincolor.sc_light"/>},
                                        {value: 'middle', label: <Translate id="skincolor.sc_middle"/>},
                                        {value: 'dark', label: <Translate id="skincolor.sc_dark"/>},
                                        {value: 'other', label: <Translate id="skincolor.sc_other"/>}
                                    ]}
                                    value={this.state.skinColor}
                                    onChange={this.handleSkinColorChange}
                                />
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className='from-section-label mt-3'>
                                <small><Translate id="model.desc"/></small>
                                <span className='label-desc mt-1'>
                                    <Translate id="model.descText"/>
                                </span>
                            </div>
                            <Form.Group>
                                <div className="position-relative mb-1">
                                    <Form.Control as="textarea" rows="4"
                                                  id="inputDesc"
                                                  onChange={this.handleDescChange}
                                                  disabled={this.state.loading}
                                                  value={this.state.desc}
                                                  className='input-foreground'
                                    />
                                    <div
                                        className={"character-counter " + (this.state.desc.length > 500 ? 'error' : '')}>
                                        ({this.state.desc.length}/500)
                                    </div>
                                </div>
                                <p className='text-danger m-0'>{this.state.descError}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className='from-section-label mt-3'>
                                <small><Translate id="model.homeTownAndRadius"/></small>
                                <span className='label-desc mt-1'>
                                    <Translate id="model.homeTownAndRadiusText"/>
                                </span>
                            </div>
                        </Col>
                        {/*<Col lg={12}>*/}
                        {/*    <Form.Group className={'from-label-group'}>*/}
                        {/*        <Form.Label htmlFor='inputLocationColor'><Translate id="model.homeTown"/>*</Form.Label>*/}
                        {/*        <LocationField*/}
                        {/*            setAddress={address => this.setAddress(address)}*/}
                        {/*            address={this.state.addressTitle}*/}
                        {/*        />*/}
                        {/*        {!this.state.addressError ?*/}
                        {/*            <p className='text-muted m-0'><Translate id="model.addressError"/></p>*/}
                        {/*            :*/}
                        {/*            <p className='text-muted m-0'>{this.state.addressError}</p>*/}
                        {/*        }*/}

                        {/*    </Form.Group>*/}
                        {/*</Col>*/}
                        <Col lg={6}>
                            <Form.Group
                                className={'from-label-group ' + (this.state.address.Street ? '' : this.state.addressShowRequired)}>
                                <Form.Label htmlFor='inputLocationColor'><Translate id="model.street"/>*</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="inputStreet"
                                    onChange={
                                        (e) => this.setState({
                                            address: {
                                                ...this.state.address,
                                                Street: e.target.value
                                            }
                                        })
                                    }
                                    disabled={this.state.loading}
                                    value={this.state.address.Street}
                                    className='input-foreground'
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group
                                className={'from-label-group ' + (this.state.address.Number ? '' : this.state.addressShowRequired)}>
                                <Form.Label htmlFor='inputLocationColor'><Translate
                                    id="model.streetNumber"/>*</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="inputNumber"
                                    onChange={
                                        (e) => this.setState({
                                            address: {
                                                ...this.state.address,
                                                Number: e.target.value
                                            }
                                        })
                                    }
                                    disabled={this.state.loading}
                                    value={this.state.address.Number}
                                    className='input-foreground'
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group
                                className={'from-label-group ' + (this.state.address.PostalCode ? '' : this.state.addressShowRequired)}>
                                <Form.Label htmlFor='inputLocationColor'><Translate
                                    id="model.postalCode"/>*</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="inputZIP"
                                    onChange={
                                        (e) => this.setState({
                                            address: {
                                                ...this.state.address,
                                                PostalCode: e.target.value
                                            }
                                        })
                                    }
                                    disabled={this.state.loading}
                                    value={this.state.address.PostalCode}
                                    className='input-foreground'
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group
                                className={'from-label-group ' + (this.state.address.City ? '' : this.state.addressShowRequired)}>
                                <Form.Label htmlFor='inputLocationColor'><Translate id="model.city"/>*</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="inputCity"
                                    onChange={
                                        (e) => this.setState({
                                            address: {
                                                ...this.state.address,
                                                City: e.target.value
                                            }
                                        })
                                    }
                                    disabled={this.state.loading}
                                    value={this.state.address.City}
                                    className='input-foreground'
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={12}>
                            <Form.Group
                                className={'from-label-group ' + (this.state.address.Country ? '' : this.state.addressShowRequired)}>
                                <Form.Label htmlFor='inputLocationColor'><Translate
                                    id="model.country"/>*</Form.Label>
                                <SelectPicker
                                    block
                                    locale={{
                                        noResultsText: '',
                                        placeholder: this.props.translate("adminDashboard.search"),
                                        searchPlaceholder: this.props.translate("adminDashboard.search"),
                                        loading: '',
                                        emptyMessage: '',
                                    }}
                                    placeholder={<Translate id="model.pleaseChoose"/>}
                                    searchable={true}
                                    cleanable={false}
                                    data={options}
                                    value={this.state.address.Country ?? ''}
                                    onChange={
                                        (val) => this.setState({
                                            address: {
                                                ...this.state.address,
                                                Country: val
                                            }
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={12}>
                            {!this.state.worldWide ?
                                <Form.Group className={'unit-input ' + this.state.searchRadiusShowRequired}>
                                    <UnitNumberInputField
                                        id="inputDistance"
                                        placeholder={<Translate id="model.searchRadius"/>}
                                        required={true}
                                        errorMessage={<Translate id="model.noValidValue"/>}
                                        value={this.state.searchRadius}
                                        loading={this.state.loading}
                                        valueType={this.state.searchRadiusType}
                                        unitType='Distance'
                                        handelValueSet={(val, error, valSE) => this.handleSearchRadiusChange(val, error, valSE)}
                                        handleTypeSet={(val) => this.handleSearchRadiusTypeChange(val)}
                                    />
                                </Form.Group>
                                :
                                null
                            }
                            <Form.Group className='from-label-group'>
                                <div className="d-flex align-items-center">
                                    <Checkbox
                                        id="worldWide"
                                        checked={this.state.worldWide}
                                        onChange={() => this.setState({
                                            worldWide: !this.state.worldWide,
                                            searchRadiusType: 'km',
                                            searchRadius: this.state.worldWide ? 100 : 55000,
                                            searchRadiusSETriggerChange: this.state.worldWide ? 100 : 55000
                                        })}
                                    >
                                    </Checkbox>
                                    <label htmlFor="worldWide" className="cursor-pointer-imp">
                                        <Translate id="model.worldWide"/>
                                    </label>
                                </div>
                            </Form.Group>
                            {this.state.worldWide ?
                                null
                                :
                                <RadiusMap
                                    lat={this.state.address.Latitude}
                                    lng={this.state.address.Longitude}
                                    radius={this.state.searchRadiusSETriggerChange * 1000}
                                    setSearchRadius={(radius) => this.setSearchRadius(radius)}
                                />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className='from-section-label mt-3'>
                                <small><Translate id="model.price"/></small>
                                <span className='label-desc mt-1'>
                                    <Translate id="model.priceText"/>
                                </span>
                            </div>
                            <Form.Group>
                                <UnitNumberInputField
                                    id="inputPrice"
                                    placeholder={<Translate id="model.priceHour"/>}
                                    errorMessage={<Translate id="model.noValidValue"/>}
                                    value={this.state.price}
                                    loading={this.state.loading}
                                    valueType={this.state.priceType}
                                    unitType='Currency'
                                    handelValueSet={(val, error, valSE) => this.handlePriceChange(val, error, valSE)}
                                    handleTypeSet={(val) => this.handlePriceTypeChange(val)}
                                    adminCommentType={'Price'}
                                    adminComment={this.state.modelStatus === 'ApprovalPending' ? this.state.adminComment : {}}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <div className='from-section-label mt-3'>
                                <small><Translate id="model.languages"/></small>
                                <span className='label-desc mt-1'>
                                    <Translate id="model.languagesText"/>
                                </span>
                            </div>
                            <Form.Group class="language-picker">
                                <TagPicker
                                    width={"100%"}
                                    creatable={false}
                                    searchable={false}
                                    placement={'topStart'}
                                    placeholder={<Translate id="model.pleaseChoose"/>}
                                    data={UnitConvertService.langauges}
                                    onChange={(value) => this.setState({languages: JSON.stringify(value)})}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {this.state.stepTwoError ?
                        <Row>
                            <Col>
                                <Alert variant="danger">{this.state.stepTwoError}</Alert>
                            </Col>
                        </Row>
                        :
                        null
                    }
                </section>
            </>
        )
    }

    pageThreeComplete() {
        if (typeof this.state.mainImg.ID === "undefined") {
            this.setState({
                stepThreeError: <Translate id="error.uploadMainImageReq"/>
            })
            return false;
        }
        this.setState({
            stepThreeError: ''
        })
        return true;
    }

    pageThreeContent() {
        return (
            <>
                {/*<h3 className="text-primary mb-3"><Translate id="modelRegister.images"/></h3>*/}
                <section className="model-reg-section">
                    <Row>
                        <Col lg={12}>
                            <div className='from-section-label'>
                                <small><Translate id="model.profilePicture"/></small>
                                <span className='label-desc mt-1'>
                                    <Translate id="model.profilePictureText"/>
                                    <span style={{color: '#000'}}><br/><br/><b><Translate id="model.pictureDisclaimer"/></b></span>
                                </span>
                            </div>
                            <Form.Group>
                                {typeof this.state.mainImg !== 'undefined' && this.state.mainImg.ID ?
                                    <UploadField
                                        onChange={this.handleMainImgStatusChange}
                                        inputContent={<Translate id="upload.descOne"/>}
                                        onDelete={this.handleMainImgDelete}
                                        files={[this.state.mainImg]}
                                        alloweEdit={true}
                                        maxFiles={1}
                                    />
                                    :
                                    <UploadField
                                        onChange={this.handleMainImgStatusChange}
                                        onDelete={this.handleMainImgDelete}
                                        inputContent={<Translate id="upload.descOne"/>}
                                        maxFiles={1}
                                    />
                                }
                            </Form.Group>
                        </Col>
                        <Col lg={12}>
                            <Form.Group className='mt-3'>
                                <div className='from-section-label'>
                                    <small><Translate id="model.moreimages"/></small>
                                    <span className='label-desc mt-1'>
                                            <Translate id="model.moreimagesText"/>
                                            <span style={{color: '#000'}}><br/><br/><b><Translate
                                                id="model.pictureDisclaimer"/></b></span>
                                    </span>
                                </div>
                                <UploadField
                                    onChange={this.handleImageStatusChange}
                                    onDelete={this.handleImagesDelete}
                                    files={this.state.images}
                                    alloweEdit={true}
                                    adminCommentType="IMG_"
                                    adminComment={this.state.modelStatus === 'ApprovalPending' ? this.state.adminComment : {}}
                                    deleteAdminComment={this.deleteAdminComment}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {this.state.stepThreeError ?
                        <Row>
                            <Col>
                                <Alert variant="danger">{this.state.stepThreeError}</Alert>
                            </Col>
                        </Row>
                        :
                        null
                    }
                </section>
            </>
        )
    }

    submitModelRegistration() {
        if (this.getPageComplete()) {
            /// window.gtag('event', 'conversion', {'send_to': 'AW-461926465/L7eTCNDs0-0BEMHgodwB'});
            console.log('logging conversion');
            try {
                window.gtag_report_conversion();
            } catch (e) {
                console.error('failed', e);
            }

            let searchRadius = this.state.searchRadiusSE;
            if(this.state.worldWide){
                searchRadius = 55000
            }
            ApiService.client.post(
                'TopModel/',
                {
                    Surname: this.state.surname,
                    FirstName: this.state.firstName,
                    Email: this.state.email,
                    Locale: this.props.locale ?? 'de_DE',
                    Birthdate: this.state.birthDate,
                    Height: this.state.heightSE,
                    Weight: this.state.weightSE,
                    ShoeSize: this.state.shoeSizeSE,
                    Chest: this.state.chestSE,
                    Waist: this.state.waistSE,
                    Hip: this.state.hipSE,
                    Gender: this.state.gender,
                    HairColor: this.state.hairColor,
                    EyeColor: this.state.eyeColor,
                    SkinType: this.state.skinType,
                    SkinColor: this.state.skinColor,
                    Desc: this.state.desc,
                    Price: this.state.price,
                    PriceType: this.state.priceType,
                    ClothingSize: this.state.clothingSize,
                    SearchRadius: searchRadius,
                    WorldWide: this.state.worldWide,
                    PromoCode: this.state.promoCode,
                    ParentName: this.state.parentName,
                    Phone: this.state.phone,
                    Langauges: this.state.languages,
                    MartialStatus: this.state.martialStatus,
                }
            ).then(
                (response) => {
                    this.saveModelAddress(response.data.ID);
                },
                (error) => {
                    let errorMsg = this.props.translate("error.unknown");
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error(this.props.translate("toastr.error"), errorMsg)
                }
            );
        }

    }

    saveModelAddress(modelID) {
        let data = this.state.address;
        data.MemberID = modelID;
        ApiService.client.post(
            'Address/',
            data
        ).then(
            (response) => {
                ApiService.client.put(
                    'TopModel/' + modelID,
                    {
                        AddressID: response.data.ID,
                    }
                ).then(
                    () => {
                        this.saveModelProfileImage(modelID)
                    },
                    (error) => {
                        let errorMsg = this.props.translate("error.unknown");
                        if (error.response && error.response.data && error.response.data.message) {
                            errorMsg = error.response.data.message
                        }
                        toastr.error(this.props.translate("toastr.error"), errorMsg)
                    }
                );
            },
            (error) => {
                let errorMsg = this.props.translate("error.unknown");
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                toastr.error(this.props.translate("toastr.error"), errorMsg)
            }
        );
    }

    saveModelProfileImage(modelID) {
        if (typeof this.state.mainImg.ID !== 'undefined') {
            ApiService.client.put(
                'TopModel/' + modelID + '/saveMainImage/',
                {
                    ImageID: this.state.mainImg.ID,
                    SendApproval: true
                }
            ).then(
                () => {
                    if (this.state.images.length) {
                        this.saveModelImages(modelID);
                    } else {
                        this.setState({done: true})
                    }

                },
                (error) => {
                    let errorMsg = this.props.translate("error.unknown");
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error(this.props.translate("toastr.error"), errorMsg)
                }
            );
        }
    }

    saveModelImages(modelID) {
        if (typeof this.state.mainImg.ID !== 'undefined') {
            ApiService.client.put(
                'TopModel/' + modelID + '/saveModelImages/',
                {Images: this.state.images}
            ).then(
                () => {
                    this.setState({done: true})
                },
                (error) => {
                    let errorMsg = this.props.translate("error.unknown");
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error(this.props.translate("toastr.error"), errorMsg)
                }
            );
        }
    }

    render() {
        return (
            <div className="full-height-center d-flex align-items-center">
                <section className="section pt-5 pb-5 w-100">
                    <Container>
                        {this.state.done ?
                            this.thankYouContent()
                            :
                            <>
                                {this.state.page === 0 ?
                                    <Row>
                                        <Col xs={12} className="pb-5 header-padding"
                                             dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.homeText)}}/>
                                    </Row>
                                    : null
                                }
                                <AnimatePresence>
                                    {this.state.showPage ?
                                        <motion.div
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            transition={{duration: 0.5}}
                                        >
                                            {this.getPageContent()}
                                        </motion.div>
                                        :
                                        null
                                    }
                                </AnimatePresence>

                                <div className="row button-row model-reg-button justify-content-between m-0 mt-3">
                                    {this.state.page !== 0 ?
                                        <Button onClick={this.prev}>
                                            <Translate id="modelRegister.back"/>
                                        </Button>
                                        :
                                        <span>&nbsp;</span>
                                    }
                                    {this.state.page < 2 ?
                                        <Button
                                            onClick={this.next}
                                            className="align-self-baseline"
                                        >
                                            <Translate id="modelRegister.next"/>
                                        </Button>
                                        :
                                        <Button
                                            onClick={this.submitModelRegistration}
                                            className="align-self-baseline finishModelRegistration"
                                        >
                                            <Translate id="modelRegister.submit"/>
                                        </Button>
                                    }
                                </div>
                            </>
                        }
                    </Container>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    locale: state.locale.locale,
    translate: getTranslate(state.localize)
});

export default connect(
    mapStateToProps,
    null,
)(ModelRegister);
