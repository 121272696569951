import React, {Component} from "react";
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import {Container} from "react-bootstrap";
import {getTranslate, Translate} from "react-localize-redux";
import LocaleActions from "../../stores/locale/actions";
import ModelSearchActions from "../../stores/modelSearch/actions";

class QuickLogout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: ''
        }
    }


    componentDidMount() {
        this.props.logout();
        this.props.setSetByUser(false);
        clearInterval(this.chatinterval);
        this.props.clearModelSearch();
        this.props.setShowSlider(true);
        this.props.history.push("/");

    }

    render() {
        return (
            <Container>
                {this.state.error ?
                    <p className="text-center text-danger">{this.state.error}</p>
                    :
                    ''
                }
            </Container>)
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    translate: getTranslate(state.localize)
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(UserActions.logout()),
    setSetByUser: (val) => dispatch(LocaleActions.setSetByUser(val)),
    clearModelSearch: () => dispatch(ModelSearchActions.clearModelSearch()),
    setShowSlider: (mode) => dispatch(ModelSearchActions.setShowSlider(mode)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(QuickLogout));
