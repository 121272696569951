import React, {Component} from "react";
import {getTranslate} from "react-localize-redux";
import {connect} from "react-redux";
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import LazyImage from "../../components/LazyImage/LazyImage";
import './ScoutAdmin.sytle.scss'

class ScoutAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            registeredScouts: [],
            scouts: [],
            loading: true,
            loadingtwo: true,
            searchTerm: ''
        }

        this.fetchAllScouts = this.fetchAllScouts.bind(this);
        this.fetchEmailConfirmedScouts = this.fetchEmailConfirmedScouts.bind(this);
        this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
        this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
        this.activateCustomer = this.activateCustomer.bind(this);
        this.disableCustomer = this.disableCustomer.bind(this);
    }

    componentDidMount() {
        this.fetchEmailConfirmedScouts()
        this.fetchAllScouts()
    }

    fetchAllScouts() {
        let params = {
            "filter[Confirmed]": 1,
            "filter[Status:not]": 'EmailConfirmed',
            "sort": "Created DESC"
        }
        if (this.state.searchTerm) {
            params = {
                "filter[Confirmed]": 1,
                "filter[Status:not]": 'EmailConfirmed',
                "sort": "FirstName ASC",
                "filterAny[FirstName:PartialMatch]": this.state.searchTerm,
                "filterAny[Surname:PartialMatch]": this.state.searchTerm,
                "filterAny[Email:PartialMatch]": this.state.searchTerm,
            }
        }
        ApiService.authorizedClient(this.props.authKey)
            .get('Customer/', {params})
            .then((response) => {
                this.setState({
                    scouts: response.data,
                    loadingtwo: false
                })

            }).catch((error) => {
            this.setState({
                loading: false
            })
            let errorMsg = this.props.translate("error.unknown");
            if (error.response && error.response.data && error.response.data.message) {
                errorMsg = error.response.data.message
            }
            toastr.error(this.props.translate("toastr.error"), errorMsg)
        })
    }

    fetchEmailConfirmedScouts() {
        let params = {
            "filter[Status]": "EmailConfirmed",
            "sort": "CustomerNumber DESC"
        }
        if (this.state.searchTerm) {
            params = {
                "filter[Status]": "EmailConfirmed",
                "sort": "FirstName DESC",
                "filterAny[FirstName:PartialMatch]": this.state.searchTerm,
                "filterAny[Surname:PartialMatch]": this.state.searchTerm,
                "filterAny[Email:PartialMatch]": this.state.searchTerm,
            }
        }
        ApiService.authorizedClient(this.props.authKey).get(
            'Customer/',
            {
                params
            }
        )
            .then((response) => {
                this.setState({
                    registeredScouts: response.data,
                    loading: false
                })

            }).catch((error) => {
            this.setState({
                loading: false
            })
            let errorMsg = this.props.translate("error.unknown");
            if (error.response && error.response.data && error.response.data.message) {
                errorMsg = error.response.data.message
            }
            toastr.error(this.props.translate("toastr.error"), errorMsg)
        })
    }

    handleSearchTermChange(e) {
        this.setState({searchTerm: e.target.value})
    }

    handleSearchButtonClick(e) {
        this.setState(
            {loading: true, loadingtwo: true},
            () => {
                this.fetchAllScouts()
                this.fetchEmailConfirmedScouts()
            }
        )
    }

    activateCustomer(customerID) {
        ApiService.authorizedClient(this.props.authKey).put(
            'Customer/' + customerID,
            {
                Status: 'Active'
            }
        )
            .then((response) => {
                toastr.success("Aktiviert", "Kunde wurde aktiviert")
                this.fetchAllScouts()
                this.fetchEmailConfirmedScouts()
            }).catch((error) => {
            this.setState({
                loading: false
            })
            let errorMsg = this.props.translate("error.unknown");
            if (error.response && error.response.data && error.response.data.message) {
                errorMsg = error.response.data.message
            }
            toastr.error(this.props.translate("toastr.error"), errorMsg)
        })
    }

    disableCustomer(customerID) {
        ApiService.authorizedClient(this.props.authKey).put(
            'Customer/' + customerID,
            {
                Status: 'Disabled'
            }
        )
            .then((response) => {
                toastr.success("Gesperrt", "Kunde wurde gesperrt")
                this.fetchAllScouts()
                this.fetchEmailConfirmedScouts()
            }).catch((error) => {
            this.setState({
                loading: false
            })
            let errorMsg = this.props.translate("error.unknown");
            if (error.response && error.response.data && error.response.data.message) {
                errorMsg = error.response.data.message
            }
            toastr.error(this.props.translate("toastr.error"), errorMsg)
        })
    }


    render() {
        return (
            <section className="py-4 mb-3">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="text-center">
                                <h3 className="pb-4 text-primary">
                                    Kundenprofile
                                </h3>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <>
                                <div className="model-search d-md-flex">
                                    <Form.Control
                                        type="text"
                                        id="scout-search"
                                        placeholder="Suche"
                                        onChange={this.handleSearchTermChange}
                                        disabled={this.props.loading}
                                        value={this.state.searchTerm}
                                        className='input-foreground'
                                    />
                                    <Button className="btn-black" onClick={this.handleSearchButtonClick}>
                                        Kunde suchen
                                    </Button>
                                </div>
                                <Row>
                                    <Col lg={12}>
                                        <h3>Freizugebende Kunden</h3>
                                        {this.state.loading ?
                                            <Spinner animation="border" role="status"
                                                     className='mx-auto d-block align-self-center'>
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                            :
                                            this.state.registeredScouts.length ?
                                                this.state.registeredScouts.map((scout, index) => (
                                                    <div
                                                        className='scout-list-item d-flex align-items-center justify-content-between'
                                                        key={scout.ID}>
                                                        <Row className="w-100">
                                                            <Col xs={6} lg={2}>
                                                                {scout.PreviewImage ?
                                                                    <LazyImage src={scout.PreviewImage}
                                                                               className='img-fluid'/>
                                                                    :
                                                                    <LazyImage
                                                                        src={"https://gravatar.com/avatar/638947efd6e9c7ea8dbe14e8eb2bc8ff?s=400&d=mp&r=x"}
                                                                        className='img-fluid'/>
                                                                }
                                                            </Col>
                                                            <Col xs={6} lg={7}
                                                                 className="d-flex justify-content-center flex-column">
                                                                <small className="text-muted">{scout.CreatedD}</small>
                                                                <div className='scout-name'>
                                                                    {scout.FirstName ?? ''} {scout.Surname ?? ''}
                                                                </div>
                                                                <div>
                                                                    Nr.: {scout.CustomerNumber}<br/>
                                                                    E-Mail: <a style={{color: '#000'}}
                                                                               href={"mailto:" + scout.Email}>{scout.Email}</a><br/>
                                                                    Firma: {scout.Company ?? 'N/A'}<br/>
                                                                    Firmenwebseite: {scout.CompanyWebsite ?? 'N/A'}<br/>
                                                                    Adresse: {scout.FullAddress ?? 'N/A'}<br/>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} lg={3}
                                                                 className='d-flex flex-column justify-content-center mt-3 mt-lg-0'
                                                            >
                                                                <Button
                                                                    variant="secondary"
                                                                    className="mb-3"
                                                                    onClick={() => this.activateCustomer(scout.ID)}
                                                                >
                                                                    Freischalten
                                                                </Button>
                                                                <Button
                                                                    variant="danger"
                                                                    onClick={() => this.disableCustomer(scout.ID)}
                                                                >
                                                                    Sperren
                                                                </Button>

                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))
                                                :
                                                <p>Keine Kunden gefunden</p>

                                        }
                                    </Col>

                                    <Col lg={12} className="py-5">
                                        <hr/>
                                    </Col>
                                    <Col lg={12}>
                                        <h3>Alle freigeschalteten Kunden</h3>
                                        {this.state.loadingtwo ?
                                            <Spinner animation="border" role="status"
                                                     className='mx-auto d-block align-self-center'>
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                            :
                                            this.state.scouts.length ?
                                                this.state.scouts.map((scout, index) => (
                                                    <div className={
                                                        'scout-list-item d-flex align-items-center justify-content-between ' +
                                                        scout.Status
                                                    } key={scout.ID}>
                                                        <Row className="w-100">
                                                            <Col xs={6} lg={2}>
                                                                {scout.PreviewImage ?
                                                                    <LazyImage src={scout.PreviewImage}
                                                                               className='img-fluid'/>
                                                                    :
                                                                    <LazyImage
                                                                        src={"https://gravatar.com/avatar/638947efd6e9c7ea8dbe14e8eb2bc8ff?s=400&d=mp&r=x"}
                                                                        className='img-fluid'/>
                                                                }
                                                            </Col>

                                                            <Col xs={6} lg={7}
                                                                 className="d-flex justify-content-center flex-column">
                                                                <small className="text-muted">{scout.Created}</small>
                                                                <div className='scout-name'>
                                                                    {scout.FirstName ?? ''} {scout.Surname ?? ''}
                                                                </div>
                                                                <div>
                                                                    Nr.: {scout.CustomerNumber}<br/>
                                                                    E-Mail: <a style={{color: '#000'}}
                                                                               href={"mailto:" + scout.Email}>{scout.Email}</a><br/>
                                                                    Firma: {scout.Company ?? 'N/A'}<br/>
                                                                    Firmenwebseite: {scout.CompanyWebsite ?? 'N/A'}<br/>
                                                                    Adresse: {scout.FullAddress ?? 'N/A'}<br/>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} lg={3}
                                                                 className='d-flex flex-column justify-content-center mt-3 mt-lg-0'
                                                            >
                                                                {scout.Status === "Active" ?
                                                                    <Button
                                                                        variant="danger"
                                                                        onClick={() => this.disableCustomer(scout.ID)}
                                                                    >
                                                                        Sperren
                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        variant="secondary"
                                                                        className="mb-3"
                                                                        onClick={() => this.activateCustomer(scout.ID)}
                                                                    >
                                                                        Freischalten
                                                                    </Button>
                                                                }


                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))
                                                :
                                                <p>Keine Kunden gefunden</p>

                                        }
                                    </Col>
                                </Row>
                            </>


                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    authKey: state.user.authKey,
    translate: getTranslate(state.localize)
});

export default connect(
    mapStateToProps,
    null
)(ScoutAdmin);
