const parseVideo = (data) => {
    if (!data) {
        return data;
    }
    const regex = /<oembed.+?url="https?:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})[^"]*"><\/oembed>/g;
    data = data.replace(regex, '<div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" src="https://www.youtube.com/embed/$1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>');
    return data;
}


export const HTMLService = {
    parseVideo,
};
