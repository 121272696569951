import React, {Component} from "react";
import {ChatItem} from 'react-chat-elements';
import './CustomChatItem.style.scss'
import Icofont from "../Icofont/Icofont";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import {Translate} from "react-localize-redux";
import {Button} from "react-bootstrap";

class CustomChatItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteConfirm: false,
            chooseAction: false,
            reportConfirm: false,
            blockConfirm: false,
            confirmBoth: false,
        }

        this.handleDelete = this.handleDelete.bind(this)
        this.handleReport = this.handleReport.bind(this)
        this.handleBlock = this.handleBlock.bind(this)
        this.handleBoth = this.handleBoth.bind(this)
    }

    handleDelete() {
        const me = this;
        let archivedMode = 'ArchivedModel';
        if (this.props.user.ClassName === "CIC\\TopModelCast\\Customer\\Customer") {
            archivedMode = 'ArchivedCustomer';
        }
        ApiService.authorizedClient(this.props.authKey).put(
            'Chat/' + this.props.chatID,
            {[archivedMode]: true}
        ).then((response) => {
            if (typeof me.props.reloadChats === "function") {
                me.props.reloadChats()
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    handleBlock() {
        const me = this;
        ApiService.authorizedClient(this.props.authKey).put(
            'Chat/' + this.props.chatID,
            {Blocked: true}
        ).then((response) => {
            if (typeof me.props.reloadChats === "function") {
                me.props.reloadChats()
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    handleReport() {
        const me = this;
        ApiService.authorizedClient(this.props.authKey).put(
            'Chat/' + this.props.chatID,
            {Reported: true}
        ).then((response) => {
            if (typeof me.props.reloadChats === "function") {
                me.props.reloadChats()
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    handleBoth() {
        const me = this;
        ApiService.authorizedClient(this.props.authKey).put(
            'Chat/' + this.props.chatID,
            {
                Reported: true,
                Blocked: true
            }
        ).then((response) => {
            if (typeof me.props.reloadChats === "function") {
                me.props.reloadChats()
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    render() {
        return (
            <div
                className={"chat-item d-flex " + (this.props.reported ? 'reported ' : '') + (this.props.unread ? 'unread' : '') + (typeof this.props.chatWindow !== "undefined" && this.props.chatWindow ? ' inChatWindow' : ' ')}>
                {this.state.deleteConfirm ?
                    <div className="chat-confirm-delete">
                        <div className="d-flex justify-content-center flex-column align-items-center p-2 h-100">
                            <p><Translate id="chat.confirmDelete"/></p>
                            <div className="buttons">
                                <Icofont icon="check-alt" className="text-success mr-2"
                                         onClick={this.handleDelete}
                                />
                                <Icofont icon="close-line" className="text-danger mr-2"
                                         onClick={() => this.setState({deleteConfirm: !this.state.deleteConfirm})}
                                />
                            </div>
                        </div>

                    </div>
                    :
                    null
                }
                {this.state.chooseAction ?
                    <div className="chat-confirm-delete">
                        <div
                            className="d-flex flex-column flex-md-row justify-content-center align-items-center p-2 h-100">
                            <Icofont icon="close-line" className="close-choose"
                                     onClick={() => this.setState({chooseAction: !this.state.chooseAction})}
                            />
                            {!this.props.reported ?
                                <Button
                                    className="mr-2 my-2 small-btn"
                                    onClick={() => this.setState({
                                        chooseAction: false,
                                        reportConfirm: true,
                                        blockConfirm: false,
                                        confirmBoth: false,
                                    })}>
                                    <Icofont icon="icofont-ui-block" className="mr-1"/>
                                    <Translate id="chat.reportChat"/>
                                </Button>
                                : null
                            }
                            {!this.props.blocked ?
                                <Button
                                    className="mr-2 my-2 small-btn"
                                    onClick={() => this.setState({
                                        chooseAction: false,
                                        reportConfirm: false,
                                        blockConfirm: true,
                                        confirmBoth: false,
                                    })}>
                                    <Icofont icon="icofont-ui-block" className="mr-1"/>
                                    <Translate id="chat.blockChat"/>
                                </Button>
                                :
                                null
                            }
                            {!this.props.reported && !this.props.blocked ?
                                <Button
                                    className="my-2 small-btn"
                                    onClick={() => this.setState({
                                        chooseAction: false,
                                        reportConfirm: false,
                                        blockConfirm: false,
                                        confirmBoth: true,
                                    })}>
                                    <Icofont icon="icofont-ui-block" className="mr-1"/>
                                    <Translate id="chat.bothActionsChat"/>
                                </Button>
                                :
                                null
                            }

                        </div>
                    </div>
                    :
                    null
                }
                {this.state.reportConfirm ?
                    <div className="chat-confirm-delete">
                        <div className="d-flex justify-content-center flex-column align-items-center p-2 h-100">
                            <p><Translate id="chat.confirmReport"/></p>
                            <div className="buttons">
                                <Icofont icon="check-alt" className="text-success mr-2"
                                         onClick={this.handleReport}
                                />
                                <Icofont icon="close-line" className="text-danger mr-2"
                                         onClick={() => this.setState({reportConfirm: !this.state.reportConfirm})}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                {this.state.blockConfirm ?
                    <div className="chat-confirm-delete">
                        <div className="d-flex justify-content-center flex-column align-items-center p-2 h-100">
                            <p><Translate id="chat.confirmBlock"/></p>
                            <div className="buttons">
                                <Icofont icon="check-alt" className="text-success mr-2"
                                         onClick={this.handleBlock}
                                />
                                <Icofont icon="close-line" className="text-danger mr-2"
                                         onClick={() => this.setState({blockConfirm: !this.state.blockConfirm})}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                {this.state.confirmBoth ?
                    <div className="chat-confirm-delete">
                        <div className="d-flex justify-content-center flex-column align-items-center p-2 h-100">
                            <p><Translate id="chat.confirmBoth"/></p>
                            <div className="buttons">
                                <Icofont icon="check-alt" className="text-success mr-2"
                                         onClick={this.handleBoth}
                                />
                                <Icofont icon="close-line" className="text-danger mr-2"
                                         onClick={() => this.setState({confirmBoth: !this.state.confirmBoth})}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <ChatItem {...this.props}/>
                <div className="chat-actions d-flex flex-column justify-content-between">
                    {!this.props.reported || !this.props.blocked ?
                        <Icofont icon="ui-block" className="report"
                                 onClick={() => this.setState({chooseAction: !this.state.chooseAction})}
                        />
                        :
                        <div className="no-action" style={{height: 40}}></div>
                    }
                    <Icofont icon="ui-delete" className="delete"
                             onClick={() => this.setState({deleteConfirm: !this.state.deleteConfirm})}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    memberID: state.user.memberID,
    authKey: state.user.authKey,
    user: state.user.user,
});

export default connect(
    mapStateToProps,
    null
)(withRouter(CustomChatItem));
