const shoeSizeUSMale = [
    {usSize: 1, euSize: 32.5},
    {usSize: 1.5, euSize: 33},
    {usSize: 2, euSize: 33.5},
    {usSize: 2.5, euSize: 34},
    {usSize: 3, euSize: 34.5},
    {usSize: 3.5, euSize: 35},
    {usSize: 4, euSize: 35.5},
    {usSize: 4, euSize: 36},
    {usSize: 4.5, euSize: 36.5},
    {usSize: 5, euSize: 37},
    {usSize: 5.5, euSize: 37.5},
    {usSize: 6, euSize: 38},
    {usSize: 6.5, euSize: 39},
    {usSize: 7, euSize: 39.5},
    {usSize: 7.5, euSize: 40},
    {usSize: 8, euSize: 40.5},
    {usSize: 8.5, euSize: 41},
    {usSize: 8.5, euSize: 41.5},
    {usSize: 9, euSize: 42},
    {usSize: 9.5, euSize: 42.5},
    {usSize: 10, euSize: 43},
    {usSize: 10, euSize: 43.5},
    {usSize: 10.5, euSize: 44},
    {usSize: 11, euSize: 44.5},
    {usSize: 11.5, euSize: 45.5},
    {usSize: 12, euSize: 46},
    {usSize: 12.5, euSize: 46.5},
    {usSize: 13, euSize: 47},
    {usSize: 13.5, euSize: 47.5},
    {usSize: 14, euSize: 48.5},
    {usSize: 14.5, euSize: 49},
    {usSize: 15, euSize: 49.5},
    {usSize: 15.5, euSize: 50},
];

const shoeSizeUSFemale = [
    {usSize: 2.5, euSize: 32.5},
    {usSize: 3, euSize: 33},
    {usSize: 3.5, euSize: 33.5},
    {usSize: 4, euSize: 34},
    {usSize: 4.5, euSize: 34.5},
    {usSize: 5, euSize: 35},
    {usSize: 5.5, euSize: 35.5},
    {usSize: 5.5, euSize: 36},
    {usSize: 6, euSize: 36.5},
    {usSize: 6.5, euSize: 37},
    {usSize: 7, euSize: 37.5},
    {usSize: 7.5, euSize: 38},
    {usSize: 8, euSize: 39},
    {usSize: 8.5, euSize: 39.5},
    {usSize: 9, euSize: 40},
    {usSize: 9.5, euSize: 40.5},
    {usSize: 10, euSize: 41.5},
    {usSize: 10.5, euSize: 41.5},
    {usSize: 11, euSize: 42},
    {usSize: 11, euSize: 42.5},
    {usSize: 11.5, euSize: 43},
    {usSize: 11.5, euSize: 43.5},
    {usSize: 12, euSize: 44},
    {usSize: 12.5, euSize: 44.5},
    {usSize: 13, euSize: 45.5},
    {usSize: 13.5, euSize: 46},
    {usSize: 14, euSize: 46.5},
    {usSize: 14.5, euSize: 47},
    {usSize: 15, euSize: 47.5},
    {usSize: 15.5, euSize: 48},
    {usSize: 15.5, euSize: 48.5},
    {usSize: 16, euSize: 49},
    {usSize: 16.5, euSize: 49.5},
    {usSize: 17, euSize: 50},
]

const shoeSizeUKMale = [
    {ukSize: 0.5, euSize: 32.5},
    {ukSize: 1, euSize: 33},
    {ukSize: 1.5, euSize: 33.5},
    {ukSize: 2, euSize: 34},
    {ukSize: 2.5, euSize: 34.5},
    {ukSize: 3, euSize: 35},
    {ukSize: 3.5, euSize: 35.5},
    {ukSize: 4, euSize: 36},
    {ukSize: 4.25, euSize: 36.5},
    {ukSize: 4.5, euSize: 37},
    {ukSize: 5, euSize: 37.5},
    {ukSize: 5.5, euSize: 38},
    {ukSize: 6, euSize: 38.5},
    {ukSize: 6.5, euSize: 39},
    {ukSize: 6.75, euSize: 39.5},
    {ukSize: 7, euSize: 40},
    {ukSize: 7.25, euSize: 40.5},
    {ukSize: 7.5, euSize: 41},
    {ukSize: 7.75, euSize: 41.5},
    {ukSize: 8, euSize: 42},
    {ukSize: 8.25, euSize: 42.5},
    {ukSize: 8.5, euSize: 43},
    {ukSize: 8.75, euSize: 43.5},
    {ukSize: 10, euSize: 44},
    {ukSize: 10.25, euSize: 44.5},
    {ukSize: 11, euSize: 45},
    {ukSize: 11.25, euSize: 45.5},
    {ukSize: 11.5, euSize: 46},
    {ukSize: 12, euSize: 46.5},
    {ukSize: 12.5, euSize: 47},
    {ukSize: 12.5, euSize: 47.5},
    {ukSize: 12.75, euSize: 48},
    {ukSize: 13, euSize: 48.5},
    {ukSize: 14.5, euSize: 49},
    {ukSize: 15, euSize: 49.5},
    {ukSize: 15.5, euSize: 50},
];

const shoeSizeUKFemale = [
    {ukSize: 1, euSize: 32.5},
    {ukSize: 1.5, euSize: 33},
    {ukSize: 1.75, euSize: 33.5},
    {ukSize: 2, euSize: 34},
    {ukSize: 2.25, euSize: 34.5},
    {ukSize: 2.5, euSize: 35},
    {ukSize: 3, euSize: 35.5},
    {ukSize: 3.5, euSize: 36},
    {ukSize: 3.75, euSize: 36.5},
    {ukSize: 4, euSize: 37},
    {ukSize: 4.5, euSize: 37.5},
    {ukSize: 5, euSize: 38},
    {ukSize: 5.5, euSize: 38.5},
    {ukSize: 6, euSize: 39},
    {ukSize: 6.25, euSize: 39.5},
    {ukSize: 6.5, euSize: 40},
    {ukSize: 6.75, euSize: 40.5},
    {ukSize: 7, euSize: 41},
    {ukSize: 7.25, euSize: 41.5},
    {ukSize: 7.5, euSize: 42},
    {ukSize: 7.75, euSize: 42.5},
    {ukSize: 8, euSize: 43},
    {ukSize: 8.5, euSize: 43.5},
    {ukSize: 9.5, euSize: 44},
    {ukSize: 9.75, euSize: 44.5},
    {ukSize: 10.5, euSize: 45},
    {ukSize: 10.75, euSize: 45.5},
    {ukSize: 11, euSize: 46},
    {ukSize: 11.5, euSize: 46.5},
    {ukSize: 12, euSize: 47},
    {ukSize: 12.5, euSize: 47.5},
    {ukSize: 13, euSize: 48},
    {ukSize: 13.5, euSize: 48.5},
    {ukSize: 14, euSize: 49},
    {ukSize: 14.5, euSize: 49.5},
    {ukSize: 15, euSize: 50},
]

const shoeSizeJapanMale = [
    {japanSize: 18, euSize: 32.5},
    {japanSize: 19.5, euSize: 33},
    {japanSize: 20, euSize: 33.5},
    {japanSize: 20.5, euSize: 34},
    {japanSize: 21, euSize: 34.5},
    {japanSize: 21.5, euSize: 35},
    {japanSize: 22, euSize: 35.5},
    {japanSize: 22.5, euSize: 36},
    {japanSize: 22.75, euSize: 36.5},
    {japanSize: 23, euSize: 37},
    {japanSize: 23.5, euSize: 37.5},
    {japanSize: 24, euSize: 38},
    {japanSize: 24.5, euSize: 38.5},
    {japanSize: 25, euSize: 39},
    {japanSize: 25.25, euSize: 39.5},
    {japanSize: 25.5, euSize: 40},
    {japanSize: 25.75, euSize: 40.5},
    {japanSize: 26, euSize: 41},
    {japanSize: 26.25, euSize: 41.5},
    {japanSize: 26.5, euSize: 42},
    {japanSize: 26.75, euSize: 42.5},
    {japanSize: 27.5, euSize: 43},
    {japanSize: 27.75, euSize: 43.5},
    {japanSize: 28.5, euSize: 44},
    {japanSize: 28.75, euSize: 44.5},
    {japanSize: 29.5, euSize: 45},
    {japanSize: 29.75, euSize: 45.5},
    {japanSize: 30, euSize: 46},
    {japanSize: 30.5, euSize: 46.5},
    {japanSize: 30.75, euSize: 47},
    {japanSize: 30.75, euSize: 47.5},
    {japanSize: 31.25, euSize: 48},
    {japanSize: 31.5, euSize: 48.5},
    {japanSize: 32, euSize: 49},
    {japanSize: 32.5, euSize: 49.5},
    {japanSize: 33, euSize: 50},
];

const shoeSizeJapanFemale = [
    {japanSize: 18, euSize: 32.5},
    {japanSize: 18.5, euSize: 33},
    {japanSize: 19, euSize: 33.5},
    {japanSize: 19.5, euSize: 34},
    {japanSize: 20.5, euSize: 34.5},
    {japanSize: 21, euSize: 35},
    {japanSize: 21.5, euSize: 35.5},
    {japanSize: 22, euSize: 36},
    {japanSize: 22.25, euSize: 36.5},
    {japanSize: 22.5, euSize: 37},
    {japanSize: 23, euSize: 37.5},
    {japanSize: 23.5, euSize: 38},
    {japanSize: 24, euSize: 38.5},
    {japanSize: 24.5, euSize: 39},
    {japanSize: 24.75, euSize: 39.5},
    {japanSize: 25, euSize: 40},
    {japanSize: 25.25, euSize: 40.5},
    {japanSize: 25.5, euSize: 41},
    {japanSize: 25.75, euSize: 41.5},
    {japanSize: 26, euSize: 42},
    {japanSize: 26.5, euSize: 42.5},
    {japanSize: 27, euSize: 43},
    {japanSize: 27.5, euSize: 43.5},
    {japanSize: 28, euSize: 44},
    {japanSize: 28.5, euSize: 44.5},
    {japanSize: 29, euSize: 45},
    {japanSize: 29.5, euSize: 45.5},
    {japanSize: 29.75, euSize: 46},
    {japanSize: 30, euSize: 46.5},
    {japanSize: 30.5, euSize: 47},
    {japanSize: 30.75, euSize: 47.5},
    {japanSize: 31.75, euSize: 48},
    {japanSize: 32, euSize: 48.5},
    {japanSize: 32.5, euSize: 49},
    {japanSize: 33, euSize: 49.5},
    {japanSize: 33.5, euSize: 50},
]

const clothingSizeEUAdult = [
    {value: 32, label: 32},
    {value: 34, label: 34},
    {value: 36, label: 36},
    {value: 38, label: 38},
    {value: 40, label: 40},
    {value: 42, label: 42},
    {value: 44, label: 44},
    {value: 46, label: 46},
    {value: 48, label: 48},
    {value: 50, label: 50},
    {value: 52, label: 52},
    {value: 54, label: 54},
    {value: 56, label: 56},
    {value: 58, label: 58},
    {value: 60, label: 60},
    {value: 62, label: 62}
];
const clothingSizeEUMinor = [
    {value: 50, label: 50},
    {value: 56, label: 56},
    {value: 62, label: 62},
    {value: 68, label: 68},
    {value: 74, label: 74},
    {value: 80, label: 80},
    {value: 86, label: 86},
    {value: 92, label: 92},
    {value: 98, label: 98},
    {value: 104, label: 104},
    {value: 110, label: 110},
    {value: 116, label: 116},
    {value: 122, label: 122},
    {value: 128, label: 128},
    {value: 134, label: 134},
    {value: 140, label: 140},
    {value: 146, label: 146},
    {value: 152, label: 152},
    {value: 158, label: 158},
    {value: 164, label: 164},
    {value: 170, label: 170},
    {value: 176, label: 176},
];

const clothingSizeOther = [
    {value: 34, label: "XS"},
    {value: 36, label: "S"},
    {value: 38, label: "M"},
    {value: 40, label: "L"},
    {value: 42, label: "XL"},
    {value: 44, label: "XXL"},
    {value: 46, label: "XXXL"},
    {value: 48, label: "XXXXL"},
];

const clothingSizeAll = [
    {value: 32, label: 32},
    {value: 34, label: 34},
    {value: 36, label: 36},
    {value: 38, label: 38},
    {value: 40, label: 40},
    {value: 42, label: 42},
    {value: 44, label: 44},
    {value: 46, label: 46},
    {value: 48, label: 48},
    {value: 50, label: 50},
    {value: 52, label: 52},
    {value: 54, label: 54},
    {value: 56, label: 56},
    {value: 58, label: 58},
    {value: 60, label: 60},
    {value: 62, label: 62},
    {value: 68, label: 68},
    {value: 74, label: 74},
    {value: 80, label: 80},
    {value: 86, label: 86},
    {value: 92, label: 92},
    {value: 98, label: 98},
    {value: 104, label: 104},
    {value: 110, label: 110},
    {value: 116, label: 116},
    {value: 122, label: 122},
    {value: 128, label: 128},
    {value: 134, label: 134},
    {value: 140, label: 140},
    {value: 146, label: 146},
    {value: 152, label: 152},
    {value: 158, label: 158},
    {value: 164, label: 164},
    {value: 170, label: 170},
    {value: 176, label: 176},
];

const convertShoeSize = (val, from, to, gender) => {
    if(from === to){
        return val;
    }
    if (typeof gender === "undefined") {
        gender = "male";
    }
    if (gender.length <= 0) {
        gender = "male";
    }
    if (!val) {
        return val;
    }
    let euSize = parseFloat(val);
    if (from === "EU") {
        if (euSize <= 32) {
            euSize = 32.5
        }
        if (euSize >= 50) {
            euSize = 50
        }
    }
    if (from === "US") {
        if (gender === 'male') {
            let result = shoeSizeUSMale.find(function (shoeSize) {
                return shoeSize.usSize === euSize;
            });
            if (typeof result !== "undefined") {
                euSize = result.euSize;
            }else {
                while (typeof result === "undefined"){
                    euSize -=0.5
                    result = shoeSizeUSMale.find(function (shoeSize) {
                        return shoeSize.usSize === euSize;
                    });
                }
                euSize = result.euSize;

            }

        }
        if (gender === 'female') {
            let result = shoeSizeUSFemale.find(function (shoeSize) {
                return shoeSize.usSize === euSize;
            });
            if (typeof result !== "undefined") {
                euSize = result.euSize;
            }else {
                while (typeof result === "undefined" ){
                    euSize -=0.25
                    result = shoeSizeUSFemale.find(function (shoeSize) {
                        return shoeSize.usSize === euSize;
                    });
                }
                euSize = result.euSize;

            }

        }
    }
    if (from === "UK") {
        if (gender === 'male') {
            let result = shoeSizeUKMale.find(function (shoeSize) {
                return shoeSize.ukSize === euSize;
            });
            if (typeof result !== "undefined") {
                euSize = result.euSize;
            }else {
                while (typeof result === "undefined" && euSize > 0){
                    euSize -=0.5
                    result = shoeSizeUKMale.find(function (shoeSize) {
                        return shoeSize.ukSize === euSize;
                    });
                }
                if (typeof result !== "undefined") {
                    euSize = result.euSize;
                }
            }

        }
        if (gender === 'female') {
            let result = shoeSizeUKFemale.find(function (shoeSize) {
                return shoeSize.ukSize === euSize;
            });
            if (typeof result !== "undefined") {
                euSize = result.euSize;
            }else {
                while (typeof result === "undefined" && euSize > 0){
                    euSize -=0.5
                    result = shoeSizeUKFemale.find(function (shoeSize) {
                        return shoeSize.ukSize === euSize;
                    });
                }
                if (typeof result !== "undefined") {
                    euSize = result.euSize;
                }
            }

        }
    }

    if (from === "JAPAN") {
        if (gender === 'male') {
            let result = shoeSizeJapanMale.find(function (shoeSize) {
                return shoeSize.japanSize === euSize;
            });
            if (typeof result !== "undefined") {
                euSize = result.euSize;
            }else {
                while (typeof result === "undefined"){
                    euSize -=0.5
                    result = shoeSizeJapanMale.find(function (shoeSize) {
                        return shoeSize.japanSize === euSize;
                    });
                }
                euSize = result.euSize;
            }

        }
        if (gender === 'female') {
            let result = shoeSizeJapanFemale.find(function (shoeSize) {
                return shoeSize.japanSize === euSize;
            });
            if (typeof result !== "undefined") {
                euSize = result.euSize;
            }else {
                while (typeof result === "undefined"){
                    euSize -=0.5
                    result = shoeSizeJapanMale.find(function (shoeSize) {
                        return shoeSize.japanSize === euSize;
                    });
                }
                euSize = result.euSize;

            }

        }
    }
    euSize = parseFloat(euSize);
    if (to === "EU") {
        return euSize;
    }
    if (to === "US") {
        if (gender === 'male') {
            let result = shoeSizeUSMale.find(function (shoeSize) {
                return shoeSize.euSize === euSize;
            });
            if (typeof result === "undefined") {
                return euSize;
            }
            return result.usSize;
        }
        if (gender === 'female') {
            let result = shoeSizeUSFemale.find(function (shoeSize) {
                return shoeSize.euSize === euSize;
            });
            if (typeof result === "undefined") {
                return euSize;
            }
            return result.usSize;
        }
    }
    if (to === "UK") {
        if (gender === 'male') {
            let result = shoeSizeUKMale.find(function (shoeSize) {
                return shoeSize.euSize === euSize;
            });
            if (typeof result === "undefined") {
                return euSize;
            }
            return result.ukSize;
        }
        if (gender === 'female') {
            let result = shoeSizeUKFemale.find(function (shoeSize) {
                return shoeSize.euSize === euSize;
            });
            if (typeof result === "undefined") {
                return euSize;
            }
            return result.ukSize;
        }
    }

    if (to === "JAPAN") {
        if (gender === 'male') {
            let result = shoeSizeJapanMale.find(function (shoeSize) {
                return shoeSize.euSize === euSize;
            });
            if (typeof result === "undefined") {
                return euSize;
            }
            return result.japanSize;
        }
        if (gender === 'female') {
            let result = shoeSizeJapanFemale.find(function (shoeSize) {
                return shoeSize.euSize === euSize;
            });
            if (typeof result === "undefined") {
                return euSize;
            }
            return result.japanSize;
        }
    }

    return euSize;
}


const convertLength = (givenVal, from, to) => {
    let val = parseFloat(givenVal);
    let siVal = parseFloat(val);

    if (from === "inch") {
        siVal = (val * 2.54);
    }


    if (to === 'cm') {
        return siVal;
    }

    if (to === 'inch') {
        return siVal / 2.54;
    }

    return val;
}


const convertWeight = (givenVal, from, to) => {
    let val = parseFloat(givenVal);
    let siVal = parseFloat(val);

    if (from === "lbs") {
        siVal = (val / 2.205);
    }


    if (to === 'kg') {
        return siVal;
    }

    if (to === 'lbs') {
        return siVal * 2.205;
    }

    return val;
}
//https://gist.githubusercontent.com/piraveen/fafd0d984b2236e809d03a0e306c8a4d/raw/4258894f85de7752b78537a4aa66e027090c27ad/languages.json
const langauges = [
    {value:'de',label:'Deutsch'},
    {value:'en',label:'English'},
    {value:'es',label:'español (Spanish)'},
    {value:'fr',label:'français (French)'},
    {value:'it"',label:'Italiano (Italian)'},
    {value:'hu"',label:'Magyar (Hungarian)'},
    {value:'pl',label:'polski (Polish)'},
    {value:'sk',label:'slovenčina (Slovak)'},
    {value:'sl',label:'slovenščina (Slovene)'},
    {value:'hr',label:'hrvatski (Croatian)'},
    {value:'dv',label:'Nederlands, Vlaams (Dutch)'},
    {value:'sv',label:'svenska (Swedish)'},
    {value:'da',label:'dansk (Danish)'},
    {value:'no',label:'Norsk (Norwegian)'},
    {value:'el"',label:'Ελληνικά (Greek)'},
    {value:'cs',label:'česky, čeština (Czech)'},
    {value:'fi',label:'suomi, suomen kieli (Finnish)'},
    {value:'ro',label:'română (Romanian, Moldavian, Moldovan)'},
    {value:'tr',label:'Türkçe (Turkish)'},
    {value:'ru',label:'русский язык (Russian)'},
    {value:'pt',label:'Português (Portuguese)'},
    {value:'uk',label:'українська (Ukrainian)'},
    {value:'ab',label:'Abkhaz'},
    {value:'aa',label:'Afaraf'},
    {value:'af',label:'Afrikaans'},
    {value:'ak',label:'Akan'},
    {value:'sq',label:'Albanian'},
    {value:'am',label:'Amharic'},
    {value:'ar',label:'Arabic'},
    {value:'an',label:'Aragonese'},
    {value:'hy',label:'Armenian'},
    {value:'as',label:'Assamese'},
    {value:'av',label:'Avaric'},
    {value:'ae',label:'Avestan'},
    {value:'ay',label:'Aymara'},
    {value:'az',label:'Azerbaijani'},
    {value:'bm',label:'Bambara'},
    {value:'ba',label:'Bashkir'},
    {value:'eu',label:'Basque'},
    {value:'be',label:'Беларуская (Belarusian)'},
    {value:'bn',label:'Bengali'},
    {value:'bh',label:'Bihari'},
    {value:'bi',label:'Bislama'},
    {value:'bs',label:'bosanski jezik (Bosnian)'},
    {value:'br',label:'Breton'},
    {value:'bg',label:'български език (Bulgarian)'},
    {value:'my',label:'Burmese'},
    {value:'ca',label:'Català (Catalan; Valencian)'},
    {value:'ch',label:'Chamorro'},
    {value:'ce',label:'Chechen'},
    {value:'ny',label:'Chichewa; Chewa; Nyanja'},
    {value:'zh',label:'中文 (Zhōngwén), 汉语, 漢語 (Chinese)'},
    {value:'cv',label:'Chuvash'},
    {value:'kw',label:'Cornish'},
    {value:'co',label:'Corsican'},
    {value:'cr',label:'Cree'},
    {value:'dv',label:'Divehi; Dhivehi; Maldivian'},
    {value:'eo',label:'Esperanto'},
    {value:'et',label:'Estonian'},
    {value:'ee',label:'Ewe'},
    {value:'fo',label:'Faroese'},
    {value:'fj',label:'Fijian'},
    {value:'ff',label:'Fula'},
    {value:'gl',label:'Galician'},
    {value:'ka',label:'Georgian'},
    {value:'gn',label:'Guaraní'},
    {value:'gu',label:'Gujarati'},
    {value:'ht',label:'Haitian'},
    {value:'ha',label:'Hausa'},
    {value:'he',label:'Hebrew (he)'},
    {value:'iw',label:'Hebrew (iw)'},
    {value:'hz',label:'Herero'},
    {value:'hi',label:'Hindi'},
    {value:'ho',label:'Hiri Motu'},
    {value:'ia',label:'Interlingua'},
    {value:'id',label:'Indonesian'},
    {value:'ie',label:'Interlingue'},
    {value:'ga',label:'Irish'},
    {value:'ig',label:'Igbo'},
    {value:'ik',label:'Inupiaq'},
    {value:'io',label:'Ido'},
    {value:'is',label:'Icelandic'},
    {value:'iu',label:'Inuktitut'},
    {value:'ja',label:'日本語 (にほんご／にっぽんご) (Japanese)'},
    {value:'jv',label:'Javanese'},
    {value:'kl',label:'Kalaallisut, Greenlandic'},
    {value:'kn',label:'Kannada'},
    {value:'kr',label:'Kanuri'},
    {value:'ks',label:'Kashmiri'},
    {value:'kk',label:'Kazakh'},
    {value:'km',label:'Khmer'},
    {value:'ki',label:'Gikuyu'},
    {value:'rw',label:'Kinyarwanda'},
    {value:'ky',label:'Kirghiz'},
    {value:'kv',label:'Komi'},
    {value:'kg',label:'Kongo'},
    {value:'ko',label:'Korean'},
    {value:'ku',label:'Kurdish'},
    {value:'kj',label:'Kwanyama'},
    {value:'la',label:'Latin'},
    {value:'lb',label:'Lëtzebuergesch (Luxembourgish)'},
    {value:'lg',label:'Luganda'},
    {value:'li',label:'Limburgish'},
    {value:'ln',label:'Lingala'},
    {value:'lo',label:'Lao'},
    {value:'lt',label:'Lithuanian'},
    {value:'lu',label:'Luba-Katanga'},
    {value:'lv',label:'Latvian'},
    {value:'lv',label:'Latvian'},
    {value:'mk',label:'Macedonian'},
    {value:'mg',label:'Malagasy'},
    {value:'ms',label:'Malay'},
    {value:'ml',label:'Malayalam'},
    {value:'mt',label:'Maltese'},
    {value:'mi',label:'Māori'},
    {value:'mr',label:'Marathi'},
    {value:'mh',label:'Marshallese'},
    {value:'mn',label:'Mongolian'},
    {value:'na',label:'Nauru'},
    {value:'nv',label:'Navajo'},
    {value:'nd',label:'North Ndebele'},
    {value:'ne',label:'Nepali'},
    {value:'ng',label:'Ndonga'},
    {value:'nb',label:'Norwegian Bokmål'},
    {value:'nn',label:'Norwegian Nynorsk'},
    {value:'ii',label:'Nuosu'},
    {value:'nr',label:'South Ndebele'},
    {value:'oc',label:'Occitan'},
    {value:'oj',label:'Ojibwe'},
    {value:'oj',label:'Oromo'},
    {value:'or',label:'Oriya'},
    {value:'os',label:'Ossetian'},
    {value:'pa',label:'Panjabi'},
    {value:'pi',label:'Pāli'},
    {value:'fa',label:'Persian'},
    {value:'ps',label:'Pashto'},
    {value:'qu',label:'Quechua'},
    {value:'rm',label:'Romansh'},
    {value:'rn',label:'Kirundi'},
    {value:'sa',label:'Sanskrit (Saṁskṛta)'},
    {value:'sc',label:'Sardinian'},
    {value:'sd',label:'Sindhi'},
    {value:'se',label:'Northern Sami'},
    {value:'sm',label:'Samoan'},
    {value:'sg',label:'Sango'},
    {value:'sr',label:'Serbian'},
    {value:'gd',label:'Scottish Gaelic'},
    {value:'sn',label:'Shona'},
    {value:'si',label:'Sinhala'},
    {value:'so',label:'Somali'},
    {value:'st',label:'Southern Sotho'},
    {value:'su',label:'Sundanese'},
    {value:'sw',label:'Swahili'},
    {value:'ss',label:'Swati'},
    {value:'ta',label:'Tamil'},
    {value:'te',label:'Telugu'},
    {value:'tg',label:'Tajik'},
    {value:'th',label:'Thai'},
    {value:'ti',label:'Tigrinya'},
    {value:'bo',label:'Tibetan Standard'},
    {value:'tk',label:'Turkmen'},
    {value:'tl',label:'Tagalog'},
    {value:'tn',label:'Tswana'},
    {value:'to',label:'Tonga'},
    {value:'ts',label:'Tsonga'},
    {value:'tt',label:'Tatar'},
    {value:'tw',label:'Twi'},
    {value:'ty',label:'Tahitian'},
    {value:'ug',label:'Uighur'},
    {value:'ur',label:'Urdu'},
    {value:'uz',label:'Uzbek'},
    {value:'ve',label:'Venda'},
    {value:'vi',label:'Vietnamese'},
    {value:'vo',label:'Volapük'},
    {value:'wa',label:'Walloon'},
    {value:'cy',label:'Welsh'},
    {value:'wo',label:'Wolof'},
    {value:'fy',label:'Western Frisian'},
    {value:'xh',label:'Xhosa'},
    {value:'yi',label:'Yiddish'},
    {value:'yo',label:'Yoruba'},
    {value:'za',label:'Zhuang'}
];

export const UnitConvertService = {
    convertShoeSize,
    convertLength,
    convertWeight,
    clothingSizeEUAdult,
    clothingSizeEUMinor,
    clothingSizeOther,
    clothingSizeAll,
    langauges,
};
