/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import {createReducer} from 'reduxsauce';
import {INITIAL_STATE} from './initialState';
import {CheckoutActionTypes} from './actions';

export const clearCheckout = (state) => {
    return {
        ...state,
        basketID: 0,
        billingAddressID: 0,
        shippingAddressID: 0,
        remarks: '',
        checkoutPreFill: false
    };
};

export const setCheckout = (state, {basketID, billingAddressID, shippingAddressID, remarks, checkoutPreFill}) => {
    return {
        ...state,
        basketID: basketID,
        billingAddressID: billingAddressID,
        shippingAddressID: shippingAddressID,
        remarks: remarks,
        checkoutPreFill: checkoutPreFill
    };
};


/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
    [CheckoutActionTypes.SET_CHECKOUT]: setCheckout,
    [CheckoutActionTypes.CLEAR_CHECKOUT]: clearCheckout,
});
