import React, {Component} from "react";
import './ModelDashboard.style.scss'
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import EditModelProfile from "../../containers/EditModelProfile/EditModelProfile";
import Chats from "../../containers/Chats/Chats";
import {getTranslate, Translate} from "react-localize-redux";


class ModelDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modelStatus: '',
            allowEdit: false,
            userIsLoading: true,
            adminComment: {}
        }

        this.fetchTopModelData = this.fetchTopModelData.bind(this)
    }

    componentDidMount() {
        this.fetchTopModelData()
    }


    fetchTopModelData() {
        ApiService.authorizedClient(this.props.authKey).get('TopModel/' + this.props.memberID)
            .then((response) => {
                this.setState({
                    modelStatus: response.data.Status,
                    allowEdit: response.data.AllowEditProfile,
                    userIsLoading: false,
                    adminComment: response.data.AdminJsonComment ? JSON.parse(response.data.AdminJsonComment) : {},
                })

            }).catch((error) => {
            let errorMsg = this.props.translate("error.unknown");
            if (error.response && error.response.data && error.response.data.message) {
                errorMsg = error.response.data.message
            }
            toastr.error(this.props.translate("toastr.error"), errorMsg)
        })
    }

    render() {
        return (
            <section>
                <Row>
                    <Col>
                        {this.state.userIsLoading ?
                            <Spinner animation="border" role="status"
                                     className='mx-auto d-block align-self-center'>
                                <span className="sr-only"><Translate id="modelDashboard.loading"/></span>
                            </Spinner>
                            :
                            (this.state.modelStatus !== 'ApprovalPending' && this.state.modelStatus !== 'Active') || (!this.state.allowEdit && this.state.modelStatus !== 'Active') ?
                                <div className="text-center">
                                    <h3 className="text-primary text-center">
                                        <Translate id="home.accountNotDone"/>
                                    </h3>
                                    <Button className="mt-5 d-inline-block" onClick={this.props.logout}><Translate
                                        id="user.logOut"/></Button>
                                </div>
                                :
                                this.state.modelStatus === 'ApprovalPending' ?
                                    <EditModelProfile/>
                                    :
                                    <Chats/>

                        }

                    </Col>
                </Row>


            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    translate: getTranslate(state.localize)
});
const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(UserActions.logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModelDashboard);
