import React, {Component} from "react";
import {ApiService} from "../../services/ApiService";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class ChatButton extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this)
    }

    checkIfChatsExists(modelID, customerID) {
        ApiService.authorizedClient(this.props.authKey).get('/Chat', {
            params: {
                "filter[ModelID]": modelID,
                "filter[CustomerID]": customerID,
            }
        }).then((response) => {
            if (typeof response.data[0] !== "undefined") {
                this.props.history.push('/chats/' + response.data[0].Hash)
            } else {
                this.props.history.push("/chats/new/" + this.props.modelBookingCode)
            }

        }).catch((error) => {
            console.log(error)
        })
    }

    handleClick() {
        if (this.props.modelID && this.props.modelBookingCode) {
            this.checkIfChatsExists(this.props.modelID, this.props.memberID);
        } else {
            this.props.fallbackCall()
        }
    }

    render() {
        return (
            <div onClick={this.handleClick}>
                {this.props.children}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    memberID: state.user.memberID,
    authKey: state.user.authKey,
    user: state.user.user,
});


export default connect(
    mapStateToProps,
    null
)(withRouter(ChatButton));
