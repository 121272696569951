import React, {Component} from "react";
import {connect} from "react-redux";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {Alert, Button, Col, Container, Row, Spinner} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import LazyImage from "../../components/LazyImage/LazyImage";
import SimpleGallery from "../../components/SimplleGallery/SimplleGallery";
import {Translate} from "react-localize-redux";
import ModelDataField from "../EditModelProfile/ModelDataField";
import ApproveImageField from "../EditModelProfile/ApproveImageField";
import {List, Rate} from "rsuite";
import Icofont from "../../components/Icofont/Icofont";
import isTouchDevice from "is-touch-device"

class ApproveModelProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: {},
            rating: 0,
            age: '',
            ageType: '',
            mainImg: {},
            images: [],
            address: {},
            adminMode: this.props.adminMode ?? false,
            sortMode: false,
            adminComment: {},
            loading: true
        }
        this.fetchModel = this.fetchModel.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.saveFeedBack = this.saveFeedBack.bind(this);
        this.sortComments = this.sortComments.bind(this);
        this.sendFeedBack = this.sendFeedBack.bind(this);
        this.activateModel = this.activateModel.bind(this);
        this.approveModel = this.approveModel.bind(this);
        this.getLocalUnits = this.getLocalUnits.bind(this);
        this.disableModel = this.disableModel.bind(this);
        this.rejectModel = this.rejectModel.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.changeRating = this.changeRating.bind(this);
        this.handleSortEnd = this.handleSortEnd.bind(this);
        this.sendFreeRenewal = this.sendFreeRenewal.bind(this);
        this.terminateContract = this.terminateContract.bind(this);
    }

    componentDidMount() {
        this.fetchModel()
    }

    fetchModel() {
        let id = this.props.match.params.id;
        if (!isNaN(id) && id) {
            ApiService.client.get('/TopModel/' + id)
                .then((result) => {
                    this.setState({
                        model: result.data,
                        rating: result.data.Rating,
                        address: result.data.Address ?? {},
                        mainImg: result.data.MainImageImage ?? {},
                        images: result.data.ModelImagesImage ?? [],
                        adminComment: result.data.AdminJsonComment ? JSON.parse(result.data.AdminJsonComment) : {},
                        loading: false
                    }, () => {
                        this.calcAge(result.data.Birthdate)
                        this.sortComments()
                    })
                }).catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten'
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                this.setState({
                        loading: false
                    }
                );
                toastr.error('Fehler beim Laden des Models', msg)
            })
        } else {
            toastr.error('Fehler beim Laden des Models', 'Keine gültige ID')
            this.setState({
                    loading: false
                }
            );
        }
    }

    handleCommentChange(type, val, actionNeeded, sortOrder, label) {
        if (!actionNeeded) {
            let adminComment = Object.assign({}, this.state.adminComment);
            delete adminComment[type];
            this.setState({adminComment: adminComment})
        } else {
            let adminComment = Object.assign({}, this.state.adminComment);
            adminComment[type] = {
                message: val,
                actionNeeded: actionNeeded,
                sortOrder: sortOrder,
                label: label,
            };
            this.setState({adminComment: adminComment}, () => this.sortComments())
        }
    }

    sortComments() {
        const adminCommentSorted = Object.fromEntries(
            Object.entries(this.state.adminComment).sort(([, a], [, b]) => a.sortOrder - b.sortOrder)
        );
        this.setState({adminComment: adminCommentSorted})
    }

    saveFeedBack(noSend = false) {
        let id = this.props.match.params.id;
        if (!isNaN(id) && id) {
            ApiService.authorizedClient(this.props.authKey).put(
                'TopModel/' + id,
                {
                    AdminJsonComment: JSON.stringify(this.state.adminComment),
                    HasChangedSinceFeedBack: false
                }
            ).then(
                () => {
                    //toastr.success('Erfolgreich', 'Feedback wurde gespeichert')
                    if (!noSend) {
                        this.sendFeedBack();
                    }

                },
                (error) => {
                    let errorMsg = this.props.translate("error.unknown");
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error(this.props.translate('toastr.error'), errorMsg)
                }
            );
        }

    }

    sendFreeRenewal() {
        let id = this.props.match.params.id;
        if (!isNaN(id) && id) {
            ApiService.authorizedClient(this.props.authKey).post(
                'TopModel/' + id + '/sendFreeRenewalEmailAdmin',
                {}
            ).then(
                () => {
                    toastr.success('Verlängerung', 'Eine Verlängerungs E-Mail wurde verschickt');
                    this.props.history.push('/');
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                }
            );
        }
    }

    sendFeedBack() {
        let id = this.props.match.params.id;
        if (!isNaN(id) && id) {
            ApiService.authorizedClient(this.props.authKey).post(
                'TopModel/' + id + '/sendFeedbackToModel',
                {}
            ).then(
                () => {
                    toastr.success('Freigabe', 'Feedback E-Mail Sendeauftrag wurde erstellt!')
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                }
            );
        }
    }

    approveModel() {
        let id = this.props.match.params.id;
        if (!isNaN(id) && id) {
            ApiService.authorizedClient(this.props.authKey).put(
                'TopModel/' + id,
                {
                    Status: "Approved",
                    HasChangedSinceFeedBack: false,
                    AlreadyGotFeedBack: true,
                }
            ).then(
                () => {
                    toastr.success('Erfolgreich', 'Das Model wurde freigegeben!')
                    // this.fetchModel();
                    this.props.history.push('/')
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                }
            );
        }

    }

    activateModel() {
        let id = this.props.match.params.id;
        if (!isNaN(id) && id) {
            ApiService.authorizedClient(this.props.authKey).put(
                'TopModel/' + id,
                {
                    FreeModel: true
                }
            ).then(
                () => {
                    ApiService.authorizedClient(this.props.authKey).put(
                        'TopModel/' + id,
                        {
                            Status: "Approved",
                            FreeModel: true
                        }
                    ).then(
                        () => {
                            toastr.success('Erfolgreich', 'Das Model wurde freigeschalten!')
                            this.fetchModel();
                        },
                        (error) => {
                            let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                            if (error.response && error.response.data && error.response.data.message) {
                                errorMsg = error.response.data.message
                            }
                            toastr.error('Hoppla', errorMsg)
                        }
                    );
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                }
            );
        }

    }

    rejectModel() {
        let id = this.props.match.params.id;
        if (!isNaN(id) && id) {
            ApiService.authorizedClient(this.props.authKey).put(
                'TopModel/' + id,
                {
                    Status: "Rejected"
                }
            ).then(
                () => {
                    toastr.success('Erfolgreich', 'Das Model wurde abgelehnt!')
                    this.fetchModel();
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                }
            );
        }

    }

    terminateContract() {
        let id = this.props.match.params.id;
        if (!isNaN(id) && id) {
            ApiService.authorizedClient(this.props.authKey).put(
                'TopModel/' + id,
                {
                    Status: "ContractTerminated"
                }
            ).then(
                () => {
                    toastr.success('Erfolgreich', 'Das Vertrag wurde gekündigt!')
                    this.fetchModel();
                    this.props.history.push('/');
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                }
            );
        }

    }

    disableModel() {
        let id = this.props.match.params.id;
        if (!isNaN(id) && id) {
            ApiService.authorizedClient(this.props.authKey).put(
                'TopModel/' + id,
                {
                    Status: "Disabled"
                }
            ).then(
                () => {
                    toastr.success('Erfolgreich', 'Das Model wurde freigeschalten!')
                    this.fetchModel();
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                }
            );
        }

    }

    getLocalUnits(seUnit) {
        if (typeof this.props.user.Locale !== 'undefined') {
            if (seUnit === 'cm' && this.props.user.Locale === 'en_US') {
                return 'inch'
            }
            if (seUnit === 'kg' && this.props.user.Locale === 'en_US') {
                return 'lbs'
            }
            if (seUnit === 'EU' && this.props.user.Locale === 'en_US') {
                return 'US'
            }
            if (seUnit === 'mi' && this.props.user.Locale === 'en_US') {
                return 'km'
            }
        }
        return seUnit;
    }

    deleteImage(imgID) {
        let id = this.props.match.params.id;
        if (!isNaN(id) && id) {
            ApiService.authorizedClient(this.props.authKey).put(
                'TopModel/' + id + '/deleteImage',
                {
                    ImgID: imgID
                }
            ).then(
                () => {
                    toastr.success('Erfolgreich', 'Das Bild wurde gelöscht')
                    this.fetchModel();
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                }
            );
        }
    }

    handleSortEnd({oldIndex, newIndex}) {
        this.setState(({images}) => {
            const moveData = images.splice(oldIndex, 1);
            const newData = [...images];
            newData.splice(newIndex, 0, moveData[0]);
            return {
                images: newData
            };
        }, () => {
            this.state.images.map((file, index) => {
                if (file.SortOrder !== index) {
                    file.SortOrder = index;
                    if (file.ID) {
                        ApiService.client.put(
                            `/TopModelImage/${file.ID}/`,
                            {SortOrder: index}
                        ).then().catch((error) => {
                            console.error(error);
                        })
                    }
                }
                return null;
            })
        });
    }

    calcAge(dateString) {
        let today = new Date();
        let birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        age = age * 12 + m;
        if (age >= 24) {
            this.setState({
                age: parseInt((age / 12)),
                ageType: <Translate id="model.years"/>
            })
        } else {
            this.setState({
                age: age,
                ageType: <Translate id="model.months"/>
            })
        }
    }

    changeRating(rating) {
        this.setState({rating: rating});
        let id = this.props.match.params.id;
        if (!isNaN(id) && id) {
            ApiService.authorizedClient(this.props.authKey).put(
                'TopModel/' + id,
                {
                    Rating: rating
                }
            ).then(
                () => {
                    toastr.success('Erfolgreich', 'Rating wurde gespeichert')
                    //this.fetchModel();
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                }
            );
        }
    }

    render() {
        return (
            <Container>
                {this.state.loading ?
                    <Spinner animation="border" role="status"
                             className='mx-auto d-block align-self-center'>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    :
                    <>
                        <section className='py-4 pb-5'>
                            {this.state.model.HasChangedSinceFeedBack ?
                                <Alert variant="info">Neue Änderungen seit letztem Feedback</Alert>
                                :
                                this.state.model.AlreadyGotFeedBack ?
                                    <Alert variant="success">Keine neuen Änderungen seit letztem Feedback</Alert>
                                    :
                                    null
                            }
                            <Row>
                                {this.props.user.AdminPermission ?
                                    <Col xs={12} className="d-flex justify-content-center py-3">
                                        <Button as={Link} to="/" className="small-btn">zurück zur Übersicht</Button>
                                    </Col>
                                    :
                                    <Col xs={12} className="d-flex justify-content-center py-3">
                                        <Button onClick={this.props.history.goBack}
                                                className="small-btn">zurück</Button>
                                    </Col>
                                }
                                <Col lg={6}>
                                    {typeof this.state.mainImg.ImageURL !== 'undefined' ?
                                        this.state.adminMode ?
                                            <Row>
                                                <ApproveImageField
                                                    image={this.state.mainImg}
                                                    size={12}
                                                    showCommentBox={typeof this.state.adminComment['MainImage'] !== 'undefined' && typeof this.state.adminComment['MainImage'].actionNeeded !== 'undefined' && this.state.adminComment['MainImage'].actionNeeded}
                                                    message={typeof this.state.adminComment['MainImage'] !== 'undefined' ? this.state.adminComment['MainImage'].message : ''}
                                                    topModelImageID={this.state.mainImg.ID}
                                                    commentType={"MainImage"}
                                                    handleCommentChange={this.handleCommentChange}
                                                    handleDeleteImage={(imgID) => this.deleteImage(imgID)}
                                                    sortOrder={0}
                                                    labelString={'Profilbild'}
                                                />
                                            </Row>

                                            :
                                            <LazyImage src={this.state.mainImg.ImageURL} className="img-fluid"/>
                                        : null
                                    }
                                </Col>
                                <Col lg={6}>
                                    <div className="pb-2"><b>{this.state.model.BookingCode}</b></div>
                                    {this.state.adminMode ?
                                        <>

                                            <ModelDataField
                                                label={<Translate id="model.firstName"/>}
                                                value={this.state.model.FirstName}
                                                commentType={'FirstName'}
                                                showCommentBox={typeof this.state.adminComment['FirstName'] !== 'undefined' && typeof this.state.adminComment['FirstName'].actionNeeded !== 'undefined' && this.state.adminComment['FirstName'].actionNeeded}
                                                message={typeof this.state.adminComment['FirstName'] !== 'undefined' ? this.state.adminComment['FirstName'].message : ''}
                                                showAdminCommentBox={this.state.adminMode}
                                                handleCommentChange={this.handleCommentChange}
                                                sortOrder={50}
                                                labelString={'Vorname'}
                                            />
                                            <ModelDataField
                                                label={<Translate id="model.surname"/>}
                                                value={this.state.model.Surname}
                                                commentType={'Surname'}
                                                showCommentBox={typeof this.state.adminComment['Surname'] !== 'undefined' && typeof this.state.adminComment['Surname'].actionNeeded !== 'undefined' && this.state.adminComment['Surname'].actionNeeded}
                                                message={typeof this.state.adminComment['Surname'] !== 'undefined' ? this.state.adminComment['Surname'].message : ''}
                                                showAdminCommentBox={this.state.adminMode}
                                                handleCommentChange={this.handleCommentChange}
                                                sortOrder={51}
                                                labelString={'Nachname'}
                                            />
                                        </>
                                        :
                                        <h1>{this.state.model.FirstName} {this.state.model.Surname} </h1>
                                    }

                                    <ModelDataField
                                        label={<Translate id="model.age"/>}
                                        value={this.state.age}
                                        unit={this.state.ageType}
                                        commentType={'Age'}
                                        showCommentBox={typeof this.state.adminComment['Age'] !== 'undefined' && typeof this.state.adminComment['Age'].actionNeeded !== 'undefined' && this.state.adminComment['Age'].actionNeeded}
                                        message={typeof this.state.adminComment['Age'] !== 'undefined' ? this.state.adminComment['Age'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={100}
                                        labelString={'Alter'}
                                    />
                                    <ModelDataField
                                        label={<Translate id="model.gender"/>}
                                        value={<Translate id={"model.gender_" + this.state.model.Gender}/>}
                                        showAdminCommentBox={this.state.adminMode}
                                        commentType={'Gender'}
                                        showCommentBox={typeof this.state.adminComment['Gender'] !== 'undefined' && typeof this.state.adminComment['Gender'].actionNeeded !== 'undefined' && this.state.adminComment['Gender'].actionNeeded}
                                        message={typeof this.state.adminComment['Gender'] !== 'undefined' ? this.state.adminComment['Gender'].message : ''}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={101}
                                        labelString={'Geschlecht'}
                                    />
                                </Col>
                            </Row>
                            <div className="py-3">
                                {this.state.adminMode ?
                                    <Row>
                                        {this.state.sortMode ?
                                            <>
                                                <div className="my-2 col-12 text-center">
                                                    <Button className="d-inline-block"
                                                            onClick={() => this.setState({sortMode: false})}>Sortierung
                                                        beenden</Button>
                                                </div>
                                                <List
                                                    sortable={this.state.images.length > 1}
                                                    onSort={this.handleSortEnd}
                                                >
                                                    {this.state.images.map((img, index) => {
                                                        return (
                                                            <List.Item
                                                                key={img.ID}
                                                                index={index}
                                                            >
                                                                <div className="admin-sort-item-container">
                                                                    {this.state.images.length > 1 && index < this.state.images.length > 0 && isTouchDevice() ?
                                                                        <div className="reorderArrow w-100"
                                                                             onClick={() => this.handleSortEnd({
                                                                                 oldIndex: index,
                                                                                 newIndex: index - 1
                                                                             })}
                                                                        >
                                                                            <Icofont icon="arrow-up"/>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    <div
                                                                        className="admin-sort-item d-flex align-items-center">
                                                                        {isTouchDevice() ?
                                                                            null
                                                                            :
                                                                            <Icofont icon={"drag"} className="mx-2"
                                                                                     size={2}/>
                                                                        }
                                                                        <LazyImage
                                                                            className={"mx-3 " + (isTouchDevice() ? 'mx-auto d-block my-3' : '')}
                                                                            fluid
                                                                            src={img.ImageURL ?? img.AbsoluteURL}/>
                                                                    </div>
                                                                    {this.state.images.length > 1 && index < this.state.images.length - 1 && isTouchDevice() ?
                                                                        <div className="reorderArrow w-100"
                                                                             onClick={() => this.handleSortEnd({
                                                                                 oldIndex: index,
                                                                                 newIndex: index + 1
                                                                             })}
                                                                        >
                                                                            <Icofont icon="arrow-down"/>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>

                                                            </List.Item>
                                                        )
                                                    })
                                                    }
                                                </List>
                                                <div className="my-2 col-12 text-center">
                                                    <Button className="d-inline-block"
                                                            onClick={() => this.setState({sortMode: false})}>Sortierung
                                                        beenden</Button>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="my-2 col-12 text-center">
                                                    <Button className="d-inline-block"
                                                            onClick={() => this.setState({sortMode: true})}>Bilder
                                                        sortieren</Button>
                                                </div>
                                                {this.state.images.map((img, index) => {
                                                    return (
                                                        <ApproveImageField
                                                            key={img.ID}
                                                            className='mb-3'
                                                            image={img}
                                                            topModelImageID={img.ID}
                                                            handleDeleteImage={(imgID) => this.deleteImage(imgID)}
                                                            showCommentBox={typeof this.state.adminComment['IMG_' + img.ID] !== 'undefined' && typeof this.state.adminComment['IMG_' + img.ID].actionNeeded !== 'undefined' && this.state.adminComment['IMG_' + img.ID].actionNeeded}
                                                            message={typeof this.state.adminComment['IMG_' + img.ID] !== 'undefined' ? this.state.adminComment['IMG_' + img.ID].message : ''}
                                                            commentType={"IMG_" + img.ID}
                                                            handleCommentChange={this.handleCommentChange}
                                                            sortOrder={(1000 + parseInt(index))}
                                                            labelString={'Bild: ' + index}
                                                        />)
                                                })
                                                }
                                                <div className="my-2 col-12 text-center">
                                                    <Button className="d-inline-block"
                                                            onClick={() => this.setState({sortMode: true})}>Bilder
                                                        sortieren</Button>
                                                </div>
                                            </>
                                        }
                                    </Row>

                                    :
                                    <SimpleGallery images={this.state.images}/>
                                }

                            </div>
                            <Row>
                                <Col xs={12}>
                                    <hr/>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <ModelDataField
                                        label={<Translate id="model.height"/>}
                                        value={this.state.model.Height}
                                        unit={this.getLocalUnits('cm')}
                                        commentType={'Height'}
                                        showCommentBox={typeof this.state.adminComment['Height'] !== 'undefined' && typeof this.state.adminComment['Height'].actionNeeded !== 'undefined' && this.state.adminComment['Height'].actionNeeded}
                                        message={typeof this.state.adminComment['Height'] !== 'undefined' ? this.state.adminComment['Height'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={102}
                                        labelString={'Größe'}
                                    />
                                </Col>
                                <Col xs={12} lg={6}>
                                    <ModelDataField
                                        label={<Translate id="model.weight"/>}
                                        value={this.state.model.Weight}
                                        unit={this.getLocalUnits('kg')}
                                        commentType={'Weight'}
                                        showCommentBox={typeof this.state.adminComment['Weight'] !== 'undefined' && typeof this.state.adminComment['Weight'].actionNeeded !== 'undefined' && this.state.adminComment['Weight'].actionNeeded}
                                        message={typeof this.state.adminComment['Weight'] !== 'undefined' ? this.state.adminComment['Weight'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={103}
                                        labelString={'Gewicht'}
                                    />
                                </Col>
                                <Col xs={12} lg={6}>
                                    <ModelDataField
                                        label={<Translate id="model.shoesize"/>}
                                        value={this.state.model.ShoeSize}
                                        gender={this.state.model.gender}
                                        unit={this.getLocalUnits('EU')}
                                        unitType={"Shoe"}
                                        showAdminCommentBox={this.state.adminMode}
                                        commentType={'ShoeSize'}
                                        showCommentBox={typeof this.state.adminComment['ShoeSize'] !== 'undefined' && typeof this.state.adminComment['ShoeSize'].actionNeeded !== 'undefined' && this.state.adminComment['ShoeSize'].actionNeeded}
                                        message={typeof this.state.adminComment['ShoeSize'] !== 'undefined' ? this.state.adminComment['ShoeSize'].message : ''}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={104}
                                        labelString={'Schugröße'}
                                    />
                                </Col>
                                <Col xs={12} lg={6}>
                                    <ModelDataField
                                        label={<Translate id="model.clothingSize"/>}
                                        value={this.state.model.ClothingSize}
                                        commentType={'ClothingSize'}
                                        showCommentBox={typeof this.state.adminComment['ClothingSize'] !== 'undefined' && typeof this.state.adminComment['ClothingSize'].actionNeeded !== 'undefined' && this.state.adminComment['ClothingSize'].actionNeeded}
                                        message={typeof this.state.adminComment['ClothingSize'] !== 'undefined' ? this.state.adminComment['ClothingSize'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={105}
                                        labelString={'Konfektionsgröße'}
                                    />
                                </Col>
                                <Col xs={12}/>
                                <Col xs={12} lg={6}>
                                    <ModelDataField
                                        label={<Translate id="model.chest"/>}
                                        value={this.state.model.Chest}
                                        unit={this.getLocalUnits('cm')}
                                        commentType={'Chest'}
                                        showCommentBox={typeof this.state.adminComment['Chest'] !== 'undefined' && typeof this.state.adminComment['Chest'].actionNeeded !== 'undefined' && this.state.adminComment['Chest'].actionNeeded}
                                        message={typeof this.state.adminComment['Chest'] !== 'undefined' ? this.state.adminComment['Chest'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={106}
                                        labelString={'Brustumfang'}
                                    />
                                </Col>
                                <Col xs={12} lg={6}>
                                    <ModelDataField
                                        label={<Translate id="model.waist"/>}
                                        value={this.state.model.Waist}
                                        unit={this.getLocalUnits('cm')}
                                        commentType={'Waist'}
                                        showCommentBox={typeof this.state.adminComment['Waist'] !== 'undefined' && typeof this.state.adminComment['Waist'].actionNeeded !== 'undefined' && this.state.adminComment['Waist'].actionNeeded}
                                        message={typeof this.state.adminComment['Waist'] !== 'undefined' ? this.state.adminComment['Waist'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={107}
                                        labelString={'Tailienumfang'}
                                    />
                                </Col>
                                <Col xs={12} lg={6}>
                                    <ModelDataField
                                        label={<Translate id="model.hip"/>}
                                        value={this.state.model.Hip}
                                        unit={this.getLocalUnits('cm')}
                                        commentType={'Hip'}
                                        showCommentBox={typeof this.state.adminComment['Hip'] !== 'undefined' && typeof this.state.adminComment['Hip'].actionNeeded !== 'undefined' && this.state.adminComment['Hip'].actionNeeded}
                                        message={typeof this.state.adminComment['Hip'] !== 'undefined' ? this.state.adminComment['Hip'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={108}
                                        labelString={'Hüftumfang'}
                                    />
                                </Col>
                                <Col xs={12}/>
                                <Col xs={12} lg={6}>
                                    <ModelDataField
                                        label={<Translate id="model.hair"/>}
                                        value={<Translate
                                            id={"color.c_" + this.state.model.HairColor}
                                        />}
                                        commentType={'HairColor'}
                                        showCommentBox={typeof this.state.adminComment['HairColor'] !== 'undefined' && typeof this.state.adminComment['HairColor'].actionNeeded !== 'undefined' && this.state.adminComment['HairColor'].actionNeeded}
                                        message={typeof this.state.adminComment['HairColor'] !== 'undefined' ? this.state.adminComment['HairColor'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={109}
                                        labelString={'Haarfarbe'}
                                    />
                                </Col>
                                <Col xs={12} lg={6}>
                                    <ModelDataField
                                        label={<Translate id="model.eyes"/>}
                                        value={<Translate
                                            id={"color.c_" + this.state.model.EyeColor}
                                        />}
                                        commentType={'EyeColor'}
                                        showCommentBox={typeof this.state.adminComment['EyeColor'] !== 'undefined' && typeof this.state.adminComment['EyeColor'].actionNeeded !== 'undefined' && this.state.adminComment['EyeColor'].actionNeeded}
                                        message={typeof this.state.adminComment['EyeColor'] !== 'undefined' ? this.state.adminComment['EyeColor'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={110}
                                        labelString={'Augenfarbe'}
                                    />
                                </Col>
                                <Col xs={12} lg={6}>
                                    <ModelDataField
                                        label={<Translate id="model.skintype"/>}
                                        value={<Translate
                                            id={"skintype.st_" + this.state.model.SkinType}
                                        />}
                                        commentType={'SkinType'}
                                        showCommentBox={typeof this.state.adminComment['SkinType'] !== 'undefined' && typeof this.state.adminComment['SkinType'].actionNeeded !== 'undefined' && this.state.adminComment['SkinType'].actionNeeded}
                                        message={typeof this.state.adminComment['SkinType'] !== 'undefined' ? this.state.adminComment['SkinType'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={111}
                                        labelString={'Hauttyp'}
                                    />
                                </Col>
                                <Col xs={12} lg={6}>
                                    <ModelDataField
                                        label={<Translate id="model.skincolor"/>}
                                        value={<Translate
                                            id={"skincolor.sc_" + this.state.model.SkinColor}
                                        />}
                                        commentType={'SkinColor'}
                                        showCommentBox={typeof this.state.adminComment['SkinColor'] !== 'undefined' && typeof this.state.adminComment['SkinColor'].actionNeeded !== 'undefined' && this.state.adminComment['SkinColor'].actionNeeded}
                                        message={typeof this.state.adminComment['SkinColor'] !== 'undefined' ? this.state.adminComment['SkinColor'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={112}
                                        labelString={'Hautfarbe'}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <hr/>
                                </Col>
                                <Col xs={12}>
                                    <ModelDataField
                                        label={<Translate id="model.desc"/>}
                                        value={this.state.model.Desc}
                                        lgTop={12}
                                        lgBottom={12}
                                        commentType={'Desc'}
                                        showCommentBox={typeof this.state.adminComment['Desc'] !== 'undefined' && typeof this.state.adminComment['Desc'].actionNeeded !== 'undefined' && this.state.adminComment['Desc'].actionNeeded}
                                        message={typeof this.state.adminComment['Desc'] !== 'undefined' ? this.state.adminComment['Desc'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={113}
                                        labelString={'Beschreibung'}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <hr/>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <ModelDataField
                                        label={<Translate id="model.address"/>}
                                        value={this.state.model.FullAddressTitle}
                                        commentType={'Address'}
                                        showCommentBox={typeof this.state.adminComment['Address'] !== 'undefined' && typeof this.state.adminComment['Address'].actionNeeded !== 'undefined' && this.state.adminComment['Address'].actionNeeded}
                                        message={typeof this.state.adminComment['Address'] !== 'undefined' ? this.state.adminComment['Address'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={114}
                                        labelString={'Adresse'}
                                    />
                                </Col>
                                <Col xs={12} lg={6}>
                                    <ModelDataField
                                        label={<Translate id="model.searchRadius"/>}
                                        value={this.state.model.SearchRadius}
                                        unit={this.getLocalUnits('km')}
                                        commentType={'SearchRadius'}
                                        showCommentBox={typeof this.state.adminComment['SearchRadius'] !== 'undefined' && typeof this.state.adminComment['SearchRadius'].actionNeeded !== 'undefined' && this.state.adminComment['SearchRadius'].actionNeeded}
                                        message={typeof this.state.adminComment['SearchRadius'] !== 'undefined' ? this.state.adminComment['SearchRadius'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={115}
                                        labelString={'Suchradius'}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <hr/>
                                </Col>
                                <Col xs={12}>
                                    <ModelDataField
                                        label={<Translate id="model.price"/>}
                                        value={this.state.model.Price}
                                        unit={this.state.model.PriceType === 'USD' ? '$/h' : '€/h'}
                                        commentType={'Price'}
                                        showCommentBox={typeof this.state.adminComment['Price'] !== 'undefined' && typeof this.state.adminComment['Price'].actionNeeded !== 'undefined' && this.state.adminComment['Price'].actionNeeded}
                                        message={typeof this.state.adminComment['Price'] !== 'undefined' ? this.state.adminComment['Price'].message : ''}
                                        showAdminCommentBox={this.state.adminMode}
                                        handleCommentChange={this.handleCommentChange}
                                        sortOrder={116}
                                        labelString={'Preis'}
                                    />
                                </Col>
                            </Row>
                            <div className="rating-container py-3 d-flex flex-column">
                                <label className="mb-2" style={{fontWeight: 400}}>Bewertung:</label>
                                <Rate max={10} value={this.state.rating} onChange={this.changeRating}/>
                            </div>
                            {this.state.adminMode ?
                                <section>
                                    <Row className="py-3 bg-gray mt-4">
                                        <Col xs={12}>
                                            <h3><Translate id="approvemodel.summary"/></h3>
                                            {Object.keys(this.state.adminComment).length ?
                                                <>
                                                    {Object.keys(this.state.adminComment).map(key => {
                                                        return (
                                                            <Row key={key}>
                                                                <Col xs={6} lg={4}>
                                                                    <b>{this.state.adminComment[key].label}</b>
                                                                </Col>
                                                                <Col xs={6} lg={8}>
                                                                    {this.state.adminComment[key].message}
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })}
                                                    <Row>
                                                        <Col className="d-flex justify-content-end">
                                                            <Button variant="danger" className="mt-3 small-btn"
                                                                    onClick={
                                                                        () => {
                                                                            this.setState({
                                                                                    adminComment: [],
                                                                                    loading: true
                                                                                },
                                                                                () => {
                                                                                    this.saveFeedBack(true);
                                                                                    this.fetchModel();
                                                                                })
                                                                        }
                                                                    }>
                                                                <Translate id="approvemodel.deleteAll"/>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                <p><b><Translate id="approvemodel.noComments"/></b></p>
                                            }
                                        </Col>
                                        <Col xs={12}>
                                            <hr/>
                                        </Col>
                                        <Col xs={12}>
                                            <Row className="justify-content-center">
                                                {this.state.model.Status === "ApprovalPending" ?
                                                    <>
                                                        <Col xs={12} lg={6} className="text-center">
                                                            <Button onClick={() => this.saveFeedBack(false)}
                                                                    className="w-100">
                                                                Feedback senden
                                                            </Button>
                                                        </Col>
                                                        <Col xs={12} lg={6} className="text-center">
                                                            <Button variant="outline-success"
                                                                    onClick={this.approveModel}
                                                                    className="w-100">
                                                                Model freigeben
                                                            </Button>
                                                        </Col>
                                                    </>
                                                    :
                                                    null
                                                }
                                                {this.state.model.ShowExpiredButton ?
                                                    <>
                                                        <Col xs={12} lg={6} className="text-center mt-lg-2">
                                                            <Button variant="outline-success"
                                                                    onClick={this.sendFreeRenewal}
                                                                    className="w-100">
                                                                KOSTENLOS verlängern
                                                            </Button>
                                                        </Col>
                                                        <Col xs={12} lg={6} className="text-center mt-lg-2">
                                                            <Button variant="outline-danger" onClick={this.terminateContract}
                                                                    className="w-100">
                                                                Vertrag kündigen
                                                            </Button>
                                                        </Col>
                                                    </>
                                                    :
                                                    null
                                                }
                                                {this.state.model.Status === "Active" || this.state.model.FreeModel ?
                                                    <Col xs={12} lg={6} className="text-center mt-lg-2">
                                                        <Button variant="outline-danger" onClick={this.disableModel}
                                                                className="w-100">
                                                            Model sperren
                                                        </Button>
                                                    </Col>
                                                    :
                                                    <>
                                                        <Col xs={12} lg={6} className="text-center mt-lg-2">
                                                            <Button variant="outline-warning"
                                                                    onClick={this.activateModel}
                                                                    className="w-100">
                                                                KOSTENLOS aktivieren
                                                            </Button>
                                                        </Col>
                                                        <Col xs={12} lg={6} className="text-center mt-lg-2">
                                                            <Button variant="outline-danger" onClick={this.rejectModel}
                                                                    className="w-100">
                                                                Model ablehnen
                                                            </Button>
                                                        </Col>
                                                    </>
                                                }

                                            </Row>
                                        </Col>
                                    </Row>
                                </section>
                                :
                                null
                            }

                        </section>
                    </>

                }
            </Container>


        )
    }
}


const mapStateToProps = (state) => ({
    memberID: state.user.memberID,
    authKey: state.user.authKey,
    user: state.user.user
});

export default connect(
    mapStateToProps,
    null
)(withRouter(ApproveModelProfile));
