/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import {createReducer} from 'reduxsauce';
import {INITIAL_STATE} from './initialState';
import {LocaleActionTypes} from './actions';

export const setLocale = (state, {localeCode}) => {
    return {
        ...state,
        locale: localeCode
    };
};

export const setSetByUser = (state, {val}) => {
    return {
        ...state,
        setByUser: val
    };
};
/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
    [LocaleActionTypes.SET_LOCALE]: setLocale,
    [LocaleActionTypes.SET_SET_BY_USER]: setSetByUser,
});
