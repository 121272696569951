import {combineReducers} from 'redux';
import rootSaga from '../sagas';
import {reducer as UserReducer} from './user/reducers';
import {reducer as CoordsReducer} from './coords/reducers';
import {reducer as CheckoutReducer} from './checkout/reducers';
import {reducer as DSGVOReducer} from './dsgvo/reducers';
import {reducer as LocaleReducer} from './locale/reducers';
import {reducer as ModelSearchReducer} from './modelSearch/reducers';
import configureStore from './CreateStore';
import {reducer as toastrReducer} from 'react-redux-toastr'
import {localizeReducer} from "react-localize-redux";

export default () => {
    const appReducer = combineReducers({
        /**
         * Register your reducers here.
         * @see https://redux.js.org/api-reference/combinereducers
         */
        user: UserReducer,
        toastr: toastrReducer,
        coords: CoordsReducer,
        dsgvo: DSGVOReducer,
        checkout: CheckoutReducer,
        locale: LocaleReducer,
        modelSearch: ModelSearchReducer,
        localize: localizeReducer
    });

    const rootReducer = (state, action) => {
        return appReducer(state, action);
    };
    return configureStore(rootReducer, rootSaga);
}
