import React, {Component} from "react";
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import {Container} from "react-bootstrap";
import {getTranslate, Translate} from "react-localize-redux";

class QuickLoginModel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: ''
        }
    }


    componentDidMount() {
        if (this.props.match.params.hash) {
            this.quickLogin();
        }
    }

    quickLogin() {
        const me = this;
        me.setState({loading: true});
        ApiService.client.post(
            'TopModel/oneTimeAuthorize',
            {
                'ModelHash': me.props.match.params.hash,
                'Path':  typeof me.props.match.params.url !== "undefined" ? me.props.match.params.url : ''
            }
        ).then(
            (response) => {
                if (response.data.valid === false) {
                    me.setState({loading: false, errors: response.data[0].messages});
                } else {
                    me.props.login(response.data.Key, response.data.ValidUntil, response.data.MemberID);
                    setTimeout(function () {
                        me.props.fetchUser();
                        setTimeout(function () {
                            if (typeof me.props.url !== "undefined" && me.props.url.length) {
                                this.props.history.push(me.props.url);
                            } else {
                                if (typeof me.props.match.params.url !== "undefined") {
                                    if(me.props.match.params.url === 'paymentNoHeader'){
                                        me.props.history.push('/payment?noHeader=1');
                                    }else{

                                        me.props.history.push('/' + me.props.match.params.url);
                                    }
                                } else {
                                    me.props.history.push('/profile-model');
                                }

                            }
                        }, 400)
                    }, 400)


                }
            },
            (error) => {
                let errorMsg = this.props.translate("error.unknown");
                if (error.response && error.response.data && error.response.data.messages[0].message) {
                    errorMsg = error.response.data.messages[0].message
                }
                me.setState({loading: false, error: errorMsg});
            },
        );
    }

    render() {
        return (
            <Container>
                {this.state.error ?
                    <p className="text-center text-danger">{this.state.error}</p>
                    :
                    <p className="text-center"><Translate id="quickLogin.info"/></p>
                }
            </Container>)
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    translate: getTranslate(state.localize)
});

const mapDispatchToProps = (dispatch) => ({
    login: (authKey, validUntil, memberID) => dispatch(UserActions.login(authKey, validUntil, memberID)),
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(QuickLoginModel));
