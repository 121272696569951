import React, {Component} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {Translate} from "react-localize-redux";
import LocationField from "../LocationField/LocationField";
import ReactSlider from "react-slider";
import {SelectPicker} from "rsuite";
import {UnitConvertService} from "../../services/UnitConvertionService";
import UnitNumberInputField from "../UnitNumberInputField/UnitNumberInputField";

const lengthTypes = [
    {value: 'cm', label: 'cm'},
    {value: 'inch', label: 'inch'}
];
const weightTypes = [
    {value: 'kg', label: 'kg'},
    {value: 'lbs', label: 'lbs'}
];

const shoeSizeTyp = [
    {value: 'EU', label: 'EU'},
    {value: 'US', label: 'US'},
    {value: 'UK', label: 'UK'},
    {value: 'JAPAN', label: 'JAPAN'},
];

const priceTypes = [
        {value: 'EUR', label: 'EUR', step: 10, factor: 1},
        {value: 'USD', label: 'USD', step: 10, factor: 1},
        {value: 'CAD', label: 'CAD', step: 10, factor: 1},
        {value: 'HKD', label: 'HKD', step: 10, factor: 9.5},
        {value: 'ISK', label: 'ISK', step: 1000, factor: 200},
        {value: 'PHP', label: 'PHP', step: 500, factor: 60},
        {value: 'DKK', label: 'DKK', step: 10, factor: 7},
        {value: 'HUF', label: 'HUF', step: 1000, factor: 400},
        {value: 'CZK', label: 'CZK', step: 10, factor: 25},
        {value: 'GBP', label: 'GBP', step: 10, factor: 1},
        {value: 'RON', label: 'RON', step: 10, factor: 5},
        {value: 'SEK', label: 'SEK', step: 100, factor: 10},
        {value: 'IDR', label: 'IDR', step: 10000, factor: 20000},
        {value: 'INR', label: 'INR', step: 1000, factor: 100},
        {value: 'BRL', label: 'BRL', step: 100, factor: 10},
        {value: 'RUB', label: 'RUB', step: 1000, factor: 100},
        {value: 'HRK', label: 'HRK', step: 100, factor: 10},
        {value: 'JPY', label: 'JPY', step: 1000, factor: 150},
        {value: 'THB', label: 'THB', step: 100, factor: 50},
        {value: 'CHF', label: 'CHF', step: 10, factor: 1},
        {value: 'MYR', label: 'MYR', step: 10, factor: 5},
        {value: 'BGN', label: 'BGN', step: 10, factor: 2},
        {value: 'TRY', label: 'TRY', step: 100, factor: 10},
        {value: 'CNY', label: 'CNY', step: 100, factor: 10},
        {value: 'NOK', label: 'NOK', step: 100, factor: 15},
        {value: 'NZD', label: 'NZD', step: 10, factor: 2},
        {value: 'ZAR', label: 'ZAR', step: 1000, factor: 20},
        {value: 'MXN', label: 'MXN', step: 1000, factor: 25},
        {value: 'SGD', label: 'SGD', step: 10, factor: 2},
        {value: 'AUD', label: 'AUD', step: 10, factor: 2},
        {value: 'ILS', label: 'ILS', step: 20, factor: 4},
        {value: 'KRW', label: 'KRW', step: 1000, factor: 1500},
        {value: 'PLN', label: 'PLN', step: 20, factor: 5},
        {value: 'AED', label: 'AED', step: 20, factor: 5},
        {value: 'AFN', label: 'AFN', step: 20, factor: 5},
        {value: 'ALL', label: 'ALL', step: 20, factor: 5},
        {value: 'AMD', label: 'AMD', step: 20, factor: 5},
        {value: 'ANG', label: 'ANG', step: 20, factor: 5},
        {value: 'AOA', label: 'AOA', step: 20, factor: 5},
        {value: 'ARS', label: 'ARS', step: 20, factor: 5},
        {value: 'AWG', label: 'AWG', step: 20, factor: 5},
        {value: 'AZN', label: 'AZN', step: 20, factor: 5},
        {value: 'BAM', label: 'BAM', step: 20, factor: 5},
        {value: 'BBD', label: 'BBD', step: 20, factor: 5},
        {value: 'BDT', label: 'BDT', step: 20, factor: 5},
        {value: 'BHD', label: 'BHD', step: 20, factor: 5},
        {value: 'BIF', label: 'BIF', step: 20, factor: 5},
        {value: 'BMD', label: 'BMD', step: 20, factor: 5},
        {value: 'BND', label: 'BND', step: 20, factor: 5},
        {value: 'BOB', label: 'BOB', step: 20, factor: 5},
        {value: 'BSD', label: 'BSD', step: 20, factor: 5},
        {value: 'BTC', label: 'BTC', step: 20, factor: 5},
        {value: 'BTN', label: 'BTN', step: 20, factor: 5},
        {value: 'BWP', label: 'BWP', step: 20, factor: 5},
        {value: 'BYN', label: 'BYN', step: 20, factor: 5},
        {value: 'BZD', label: 'BZD', step: 20, factor: 5},
        {value: 'CDF', label: 'CDF', step: 20, factor: 5},
        {value: 'CLF', label: 'CLF', step: 20, factor: 5},
        {value: 'CLP', label: 'CLP', step: 20, factor: 5},
        {value: 'CNH', label: 'CNH', step: 20, factor: 5},
        {value: 'COP', label: 'COP', step: 20, factor: 5},
        {value: 'CRC', label: 'CRC', step: 20, factor: 5},
        {value: 'CUC', label: 'CUC', step: 20, factor: 5},
        {value: 'CUP', label: 'CUP', step: 20, factor: 5},
        {value: 'CVE', label: 'CVE', step: 20, factor: 5},
        {value: 'DJF', label: 'DJF', step: 20, factor: 5},
        {value: 'DOP', label: 'DOP', step: 20, factor: 5},
        {value: 'DZD', label: 'DZD', step: 20, factor: 5},
        {value: 'EGP', label: 'EGP', step: 20, factor: 5},
        {value: 'ERN', label: 'ERN', step: 20, factor: 5},
        {value: 'ETB', label: 'ETB', step: 20, factor: 5},
        {value: 'FJD', label: 'FJD', step: 20, factor: 5},
        {value: 'FKP', label: 'FKP', step: 20, factor: 5},
        {value: 'GEL', label: 'GEL', step: 20, factor: 5},
        {value: 'GGP', label: 'GGP', step: 20, factor: 5},
        {value: 'GHS', label: 'GHS', step: 20, factor: 5},
        {value: 'GIP', label: 'GIP', step: 20, factor: 5},
        {value: 'GMD', label: 'GMD', step: 20, factor: 5},
        {value: 'GNF', label: 'GNF', step: 20, factor: 5},
        {value: 'GTQ', label: 'GTQ', step: 20, factor: 5},
        {value: 'GYD', label: 'GYD', step: 20, factor: 5},
        {value: 'HNL', label: 'HNL', step: 20, factor: 5},
        {value: 'HRK', label: 'HRK', step: 20, factor: 5},
        {value: 'HTG', label: 'HTG', step: 20, factor: 5},
        {value: 'IMP', label: 'IMP', step: 20, factor: 5},
        {value: 'IQD', label: 'IQD', step: 20, factor: 5},
        {value: 'IRR', label: 'IRR', step: 20, factor: 5},
        {value: 'JEP', label: 'JEP', step: 20, factor: 5},
        {value: 'JMD', label: 'JMD', step: 20, factor: 5},
        {value: 'JOD', label: 'JOD', step: 20, factor: 5},
        {value: 'KES', label: 'KES', step: 20, factor: 5},
        {value: 'KGS', label: 'KGS', step: 20, factor: 5},
        {value: 'KHR', label: 'KHR', step: 20, factor: 5},
        {value: 'KMF', label: 'KMF', step: 20, factor: 5},
        {value: 'KPW', label: 'KPW', step: 20, factor: 5},
        {value: 'KWD', label: 'KWD', step: 20, factor: 5},
        {value: 'KYD', label: 'KYD', step: 20, factor: 5},
        {value: 'KZT', label: 'KZT', step: 20, factor: 5},
        {value: 'LAK', label: 'LAK', step: 20, factor: 5},
        {value: 'LBP', label: 'LBP', step: 20, factor: 5},
        {value: 'LKR', label: 'LKR', step: 20, factor: 5},
        {value: 'LRD', label: 'LRD', step: 20, factor: 5},
        {value: 'LSL', label: 'LSL', step: 20, factor: 5},
        {value: 'LYD', label: 'LYD', step: 20, factor: 5},
        {value: 'MAD', label: 'MAD', step: 20, factor: 5},
        {value: 'MDL', label: 'MDL', step: 20, factor: 5},
        {value: 'MGA', label: 'MGA', step: 20, factor: 5},
        {value: 'MKD', label: 'MKD', step: 20, factor: 5},
        {value: 'MMK', label: 'MMK', step: 20, factor: 5},
        {value: 'MNT', label: 'MNT', step: 20, factor: 5},
        {value: 'MOP', label: 'MOP', step: 20, factor: 5},
        {value: 'MRO', label: 'MRO', step: 20, factor: 5},
        {value: 'MRU', label: 'MRU', step: 20, factor: 5},
        {value: 'MUR', label: 'MUR', step: 20, factor: 5},
        {value: 'MVR', label: 'MVR', step: 20, factor: 5},
        {value: 'MWK', label: 'MWK', step: 20, factor: 5},
        {value: 'MZN', label: 'MZN', step: 20, factor: 5},
        {value: 'NAD', label: 'NAD', step: 20, factor: 5},
        {value: 'NGN', label: 'NGN', step: 20, factor: 5},
        {value: 'NIO', label: 'NIO', step: 20, factor: 5},
        {value: 'NPR', label: 'NPR', step: 20, factor: 5},
        {value: 'OMR', label: 'OMR', step: 20, factor: 5},
        {value: 'PAB', label: 'PAB', step: 20, factor: 5},
        {value: 'PEN', label: 'PEN', step: 20, factor: 5},
        {value: 'PGK', label: 'PGK', step: 20, factor: 5},
        {value: 'PKR', label: 'PKR', step: 20, factor: 5},
        {value: 'PYG', label: 'PYG', step: 20, factor: 5},
        {value: 'QAR', label: 'QAR', step: 20, factor: 5},
        {value: 'RSD', label: 'RSD', step: 20, factor: 5},
        {value: 'RWF', label: 'RWF', step: 20, factor: 5},
        {value: 'SAR', label: 'SAR', step: 20, factor: 5},
        {value: 'SBD', label: 'SBD', step: 20, factor: 5},
        {value: 'SCR', label: 'SCR', step: 20, factor: 5},
        {value: 'SDG', label: 'SDG', step: 20, factor: 5},
        {value: 'SHP', label: 'SHP', step: 20, factor: 5},
        {value: 'SLL', label: 'SLL', step: 20, factor: 5},
        {value: 'SOS', label: 'SOS', step: 20, factor: 5},
        {value: 'SRD', label: 'SRD', step: 20, factor: 5},
        {value: 'SSP', label: 'SSP', step: 20, factor: 5},
        {value: 'STD', label: 'STD', step: 20, factor: 5},
        {value: 'STN', label: 'STN', step: 20, factor: 5},
        {value: 'SVC', label: 'SVC', step: 20, factor: 5},
        {value: 'SYP', label: 'SYP', step: 20, factor: 5},
        {value: 'SZL', label: 'SZL', step: 20, factor: 5},
        {value: 'TJS', label: 'TJS', step: 20, factor: 5},
        {value: 'TMT', label: 'TMT', step: 20, factor: 5},
        {value: 'TND', label: 'TND', step: 20, factor: 5},
        {value: 'TOP', label: 'TOP', step: 20, factor: 5},
        {value: 'TTD', label: 'TTD', step: 20, factor: 5},
        {value: 'TWD', label: 'TWD', step: 20, factor: 5},
        {value: 'TZS', label: 'TZS', step: 20, factor: 5},
        {value: 'UAH', label: 'UAH', step: 20, factor: 5},
        {value: 'UGX', label: 'UGX', step: 20, factor: 5},
        {value: 'UYU', label: 'UYU', step: 20, factor: 5},
        {value: 'UZS', label: 'UZS', step: 20, factor: 5},
        {value: 'VES', label: 'VES', step: 20, factor: 5},
        {value: 'VND', label: 'VND', step: 20, factor: 5},
        {value: 'VUV', label: 'VUV', step: 20, factor: 5},
        {value: 'WST', label: 'WST', step: 20, factor: 5},
        {value: 'XAF', label: 'XAF', step: 20, factor: 5},
        {value: 'XAG', label: 'XAG', step: 20, factor: 5},
        {value: 'XAU', label: 'XAU', step: 20, factor: 5},
        {value: 'XCD', label: 'XCD', step: 20, factor: 5},
        {value: 'XDR', label: 'XDR', step: 20, factor: 5},
        {value: 'XOF', label: 'XOF', step: 20, factor: 5},
        {value: 'XPD', label: 'XPD', step: 20, factor: 5},
        {value: 'XPF', label: 'XPF', step: 20, factor: 5},
        {value: 'XPT', label: 'XPT', step: 20, factor: 5},
        {value: 'YER', label: 'YER', step: 20, factor: 5},
        {value: 'ZMW', label: 'ZMW', step: 20, factor: 5},
        {value: 'ZWL', label: 'ZWL', step: 20, factor: 5}
    ]

;

export default class ModelFilter extends Component {
    constructor(props) {
        super(props);

        this.clearFilter = this.clearFilter.bind(this)
    }

    clearFilter() {
        if (typeof this.props.clearFilter === "function") {
            this.props.clearFilter();
        }
    }

    render() {
        let minShoeSize = 15;
        let maxShoeSize = 50;
        if (this.props.shoeSizeType === "US") {
            if (this.props.genderFilter === "female") {
                minShoeSize = 2.5;
                maxShoeSize = 17;
            } else {
                minShoeSize = 1;
                maxShoeSize = 15.5;
            }
        }
        if (this.props.shoeSizeType === "UK") {
            if (this.props.genderFilter === "female") {
                minShoeSize = 0.5;
                maxShoeSize = 15;
            } else {
                minShoeSize = 1;
                maxShoeSize = 15;
            }
        }
        if (this.props.shoeSizeType === "JAPAN") {
            if (this.props.genderFilter === "female") {
                minShoeSize = 18;
                maxShoeSize = 33.5;
            } else {
                minShoeSize = 18;
                maxShoeSize = 33;
            }
        }
        let priceSteps = 10;
        let minPrice = 0;
        let maxPrice = 1000;
        let type = this.props.priceType;
        let result = priceTypes.find(function (price) {
            return price.value === type;
        });
        if (typeof result !== "undefined") {
            maxPrice *= result.factor;
            priceSteps = parseInt(result.step);
        }
        return (
            <>
                <div className="d-flex w-100 justify-content-center">
                    <Col lg={4} className="mb-3">
                        <div className="filter-label"><Translate
                            id="modelSearch.bookingCode"/></div>
                        <Form.Group className={this.props.className + ' location-field mb-0'}>
                            <Form.Control
                                value={this.props.bookingCode}
                                onChange={(e) => {
                                    this.props.setPropState({bookingCode: e.target.value})
                                }}
                            />
                        </Form.Group>
                    </Col>
                </div>
                <Col xs={12} lg={6}>
                    <Row>
                        <Col lg={6} className="mb-3 mb-lg-0">
                            <Row>
                                <Col lg={6}>
                                    <div className="filter-label"><Translate
                                        id="modelSearch.locationFilter"/></div>
                                    <LocationField
                                        onlyCitys={true}
                                        setAddress={address => this.props.setAddress(address)}
                                        address={typeof this.props.address.Title !== "undefined" ? this.props.address.Title : null}
                                    />
                                </Col>
                                <Col lg={6} className="filter-unit-input">
                                    <div className="filter-label">
                                        <Translate
                                            id="modelSearch.rangeFilter"/>
                                    </div>
                                    <UnitNumberInputField
                                        id="inputDistance"
                                        placeholder={''}
                                        errorMessage={<Translate id="model.noValidValue"/>}
                                        value={this.props.rangeFilterVal !== '' ? this.props.rangeFilterVal : null}
                                        loading={false}
                                        onlyNumber={true}
                                        valueType={this.props.rangeFilterType}
                                        unitType='Distance'
                                        handelValueSet={(val, error, valSE) => {
                                            this.props.setPropState({
                                                rangeFilter: valSE,
                                                rangeFilterVal: val,
                                            })
                                        }}
                                        handleTypeSet={(val) => {
                                            this.props.setPropState({
                                                rangeFilterType: val,
                                            })
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                            <div className="filter-label"><Translate
                                id="modelSearch.honoraFilter"/></div>
                            {this.props.initSlider ?
                                <ReactSlider
                                    className="horizontal-slider main-white pt-3"
                                    thumbClassName="white-thumb"
                                    trackClassName="white-track"
                                    min={minPrice} max={maxPrice} step={priceSteps}
                                    value={this.props.priceFilter}
                                    onChange={(i) => {
                                        this.props.setPropState({priceFilter: i})
                                    }}
                                />
                                : null
                            }
                            <div className="text-center text-white mt-4">
                                <div
                                    className="d-flex w-100 align-items-center justify-content-center price-filter-text">
                                    <Form.Control
                                        type="number"
                                        id="inputPriceTo"
                                        min={0}
                                        max={this.props.priceFilter[1]}
                                        placeholder={this.props.priceFilter[0]}
                                        onChange={(e) => {
                                            let val = parseInt(e.target.value);
                                            if (parseInt(e.target.value) >= parseInt(this.props.priceFilter[1])) {
                                                val -= 1;
                                            }
                                            this.props.setPropState({priceFilter: [val, this.props.priceFilter[1]]})
                                        }}
                                        value={this.props.priceFilter[0]}
                                    />
                                    <div className="mx-2">-</div>
                                    <Form.Control
                                        type="number"
                                        id="inputPriceTo"
                                        min={this.props.priceFilter[0]}
                                        max={999999}
                                        placeholder={this.props.priceFilter[1]}
                                        onChange={(e) => {
                                            this.props.setPropState({priceFilter: [this.props.priceFilter[0], parseInt(e.target.value)]})
                                        }}
                                        value={this.props.priceFilter[1]}
                                    />
                                    <div className="price-type-holder ml-2">
                                        <SelectPicker
                                            block
                                            searchable={false}
                                            cleanable={false}
                                            data={priceTypes}
                                            value={this.props.priceType}
                                            onChange={(i) => {
                                                maxPrice = 1000;
                                                let result = priceTypes.find(function (price) {
                                                    return price.value === i;
                                                });
                                                if (typeof result !== "undefined") {
                                                    maxPrice *= result.factor;
                                                }
                                                if (this.props.priceFilter[1] === 1000 || this.props.priceFilter[1] >= maxPrice) {
                                                    let minPrice = this.props.priceFilter[0];
                                                    if (this.props.priceFilter[0] >= maxPrice) {
                                                        minPrice = 0
                                                    }
                                                    this.props.setPropState({priceFilter: [parseInt(minPrice), parseInt(maxPrice)]})
                                                }
                                                this.props.setPropState({priceType: i})
                                            }
                                            }
                                        />
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={6}>
                    <Row>
                        <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                            <div className="filter-label"><Translate
                                id="modelSearch.genderFilter"/></div>
                            <SelectPicker
                                block
                                id={'inputSkinColor' + this.props.idCounter}
                                placeholder={<Translate id="modelSearch.chooseFilter"/>}
                                searchable={false}
                                cleanable={false}
                                data={[
                                    {
                                        value: '',
                                        label: <Translate id="modelSearch.chooseFilter"/>
                                    },
                                    {value: 'female', label: 'weiblich'},
                                    {value: 'male', label: 'männlich'}
                                ]}
                                value={this.props.genderFilter}
                                onChange={(val) => {
                                    this.props.setPropState({genderFilter: val});
                                }}
                            />
                        </Col>
                        <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                            <div className="filter-label"><Translate
                                id="modelSearch.ageFilter"/></div>
                            {this.props.initSlider ?
                                <ReactSlider
                                    className="horizontal-slider main-white pt-3"
                                    thumbClassName="white-thumb"
                                    trackClassName="white-track"
                                    min={0} max={99} step={1}
                                    value={this.props.ageFilter}
                                    onChange={(i) => {
                                        this.props.setPropState({ageFilter: i})
                                    }}
                                />
                                : null
                            }
                            <p className="text-center text-white mt-4">{this.props.ageFilter[0]} - {this.props.ageFilter[1]} Jahre</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={6}>
                    <Row>
                        <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                            <div className="filter-label"><Translate
                                id="modelSearch.heightFilter"/></div>
                            {this.props.initSlider ?
                                <ReactSlider
                                    className="horizontal-slider main-white pt-3"
                                    thumbClassName="white-thumb"
                                    trackClassName="white-track"
                                    min={this.props.heightType === 'inch' ? 15 : 40}
                                    max={this.props.heightType === 'inch' ? 86 : 220} step={1}
                                    value={this.props.heightFilter}
                                    onChange={(i) => {
                                        this.props.setPropState({
                                            heightFilter: i,
                                            heightFilterSI: [
                                                parseInt(UnitConvertService.convertLength(i[0], this.props.heightType, 'cm')),
                                                parseInt(UnitConvertService.convertLength(i[1], this.props.heightType, 'cm')),
                                            ]
                                        })
                                    }}
                                />
                                : null
                            }
                            <div className="mt-4 d-flex justify-content-center align-items-center">
                                <p className="text-white mt-4">{this.props.heightFilter[0]} - {this.props.heightFilter[1]}</p>
                                <div className="price-type-holder ml-2">
                                    <SelectPicker
                                        block
                                        searchable={false}
                                        cleanable={false}
                                        data={lengthTypes}
                                        value={this.props.heightType}
                                        onChange={(i) => this.props.setPropState({
                                            heightType: i,
                                            heightFilter: [
                                                parseInt(UnitConvertService.convertLength(this.props.heightFilterSI[0], 'cm', i)),
                                                parseInt(UnitConvertService.convertLength(this.props.heightFilterSI[1], 'cm', i)),
                                            ]
                                        })}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                            <div className="filter-label"><Translate
                                id="modelSearch.weightFilter"/></div>
                            {this.props.initSlider ?
                                <ReactSlider
                                    className="horizontal-slider main-white pt-3"
                                    thumbClassName="white-thumb"
                                    trackClassName="white-track"
                                    min={this.props.weightType === 'lbs' ? 6 : 3}
                                    max={this.props.weightType === 'lbs' ? 330 : 150} step={1}
                                    value={this.props.weightFilter}
                                    onChange={(i) => {
                                        this.props.setPropState({
                                            weightFilter: i,
                                            weightFilterSI: [
                                                parseInt(UnitConvertService.convertWeight(i[0], this.props.weightType, 'kg')),
                                                parseInt(UnitConvertService.convertWeight(i[1], this.props.weightType, 'kg')),
                                            ]
                                        })
                                    }}
                                />
                                : null
                            }
                            <div className="mt-4 d-flex justify-content-center align-items-center">
                                <p className="text-white mt-4">{this.props.weightFilter[0]} - {this.props.weightFilter[1]}</p>
                                <div className="price-type-holder ml-2">
                                    <SelectPicker
                                        block
                                        searchable={false}
                                        cleanable={false}
                                        data={weightTypes}
                                        value={this.props.weightType}
                                        onChange={(i) => this.props.setPropState({
                                            weightType: i,
                                            weightFilter: [
                                                parseInt(UnitConvertService.convertWeight(this.props.weightFilterSI[0], 'kg', i)),
                                                parseInt(UnitConvertService.convertWeight(this.props.weightFilterSI[1], 'kg', i)),
                                            ]
                                        })}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={6}>
                    <Row>
                        <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                            <div className="filter-label less-margin-bottom">
                                <Translate id="modelSearch.clothingSizeFilter"/>
                            </div>
                            <div
                                className="d-flex w-100 align-items-center justify-content-center clothing-size-selection">
                                <SelectPicker
                                    block
                                    id='inputClothingSize1'
                                    placeholder={<Translate id="modelSearch.chooseFilter"/>}
                                    searchable={false}
                                    cleanable={false}
                                    className="clothing-size-select"
                                    data={[
                                        {value: 31, label: <Translate id="modelSearch.chooseFilter"/>},
                                        ...this.props.clothingSizesForDropdown
                                    ]
                                    }
                                    value={this.props.clothingSizeFilter[0]}
                                    onChange={(i) => this.props.setPropState({
                                        clothingSizeFilter: [i, this.props.clothingSizeFilter[1]]

                                    })}
                                />
                                <div className="mx-2 text-white">-</div>
                                <SelectPicker
                                    block
                                    id='inputClothingSize2'
                                    placeholder={<Translate id="modelSearch.chooseFilter"/>}
                                    searchable={false}
                                    cleanable={false}
                                    className="clothing-size-select"
                                    data={[
                                        {value: 31, label: <Translate id="modelSearch.chooseFilter"/>},
                                        ...this.props.clothingSizesForDropdown
                                    ]}
                                    value={this.props.clothingSizeFilter[1]}
                                    onChange={(i) => this.props.setPropState({
                                        clothingSizeFilter: [this.props.clothingSizeFilter[0], i]

                                    })}
                                />
                                <SelectPicker
                                    block
                                    id='inputClothingType'
                                    searchable={false}
                                    cleanable={false}
                                    className={"price-type-holder ml-2"}
                                    data={[
                                        {value: "EU", label: "EU"},
                                        {value: "US", label: "US"},
                                        {value: "UK", label: "UK"}
                                    ]
                                    }
                                    value={this.props.clothingSizesType}
                                    onChange={(i) => this.props.setPropState({
                                        clothingSizesType: i

                                    })}
                                />
                            </div>
                            {/*{this.props.initSlider ?*/}
                            {/*    <ReactSlider*/}
                            {/*        className="horizontal-slider main-white pt-3"*/}
                            {/*        thumbClassName="white-thumb"*/}
                            {/*        trackClassName="white-track"*/}
                            {/*        min={32} max={176} step={1}*/}
                            {/*        value={this.props.clothingSizeFilter}*/}
                            {/*        onChange={(i) => {*/}
                            {/*            this.props.setPropState({clothingSizeFilter: i})*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*    : null*/}
                            {/*}*/}
                            {/*<p className="text-center text-white mt-4">{this.props.clothingSizeFilter[0]} - {this.props.clothingSizeFilter[1]}</p>*/}
                        </Col>
                        <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                            <div className="filter-label"><Translate
                                id="modelSearch.shoeSizeFilter"/></div>
                            {this.props.initSlider ?
                                <ReactSlider
                                    className="horizontal-slider main-white pt-3"
                                    thumbClassName="white-thumb"
                                    trackClassName="white-track"
                                    min={minShoeSize} max={maxShoeSize} step={0.5}
                                    value={this.props.shoeSizeFilter}
                                    onChange={(i) => {
                                        this.props.setPropState({
                                            shoeSizeFilter: i,
                                            shoeSizeFilterSI: [
                                                parseFloat(UnitConvertService.convertShoeSize(i[0], this.props.shoeSizeType, 'EU', this.props.genderFilter ?? 'male')),
                                                parseFloat(UnitConvertService.convertShoeSize(i[1], this.props.shoeSizeType, 'EU', this.props.genderFilter ?? 'male')),
                                            ]
                                        })
                                    }}
                                />
                                : null
                            }
                            <div className="mt-4 d-flex justify-content-center align-items-center">
                                <p className="text-white mt-4">{this.props.shoeSizeFilter[0]} - {this.props.shoeSizeFilter[1]}</p>
                                <div className="price-type-holder ml-2">
                                    <SelectPicker
                                        block
                                        searchable={false}
                                        cleanable={false}
                                        data={shoeSizeTyp}
                                        value={this.props.shoeSizeType}
                                        onChange={(i) => this.props.setPropState({
                                            shoeSizeType: i,
                                            shoeSizeFilter: [
                                                parseFloat(UnitConvertService.convertShoeSize(this.props.shoeSizeFilterSI[0], 'EU', i, this.props.genderFilter ?? 'male')),
                                                parseFloat(UnitConvertService.convertShoeSize(this.props.shoeSizeFilterSI[1], 'EU', i, this.props.genderFilter ?? 'male')),
                                            ]
                                        })}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={6}>
                    <Row>
                        <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                            <div className='pb-3'>
                                <div className="filter-label"><Translate
                                    id="modelSearch.hairColorFilter"/></div>
                                <SelectPicker
                                    block
                                    id={'inputHairColor' + this.props.idCounter}
                                    placeholder={<Translate id="modelSearch.chooseFilter"/>}
                                    searchable={false}
                                    cleanable={false}
                                    data={[
                                        {
                                            value: '',
                                            label: <Translate
                                                id="modelSearch.chooseFilter"/>
                                        },
                                        {
                                            value: 'blond',
                                            label: <Translate id="color.c_blond"/>
                                        },
                                        {
                                            value: 'brunette',
                                            label: <Translate id="color.c_brunette"/>
                                        },
                                        {
                                            value: 'brown',
                                            label: <Translate id="color.c_brown"/>
                                        },
                                        {
                                            value: 'red',
                                            label: <Translate id="color.c_red"/>
                                        },
                                        {
                                            value: 'black',
                                            label: <Translate id="color.c_black"/>
                                        },
                                        {
                                            value: 'gray',
                                            label: <Translate id="color.c_gray"/>
                                        },
                                        {
                                            value: 'other',
                                            label: <Translate id="color.c_other"/>
                                        }
                                    ]}
                                    value={this.props.hairColorFilter}
                                    onChange={(val) => {
                                        this.props.setPropState({hairColorFilter: val});
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                            <div className='pb-3'>
                                <div className="filter-label"><Translate
                                    id="modelSearch.eyeColorFilter"/></div>
                                <SelectPicker
                                    block
                                    id={'inputHairColor' + this.props.idCounter}
                                    placeholder={<Translate id="modelSearch.chooseFilter"/>}
                                    searchable={false}
                                    cleanable={false}
                                    data={[
                                        {
                                            value: '',
                                            label: <Translate
                                                id="modelSearch.chooseFilter"/>
                                        },
                                        {
                                            value: 'blue',
                                            label: <Translate id="color.c_blue"/>
                                        },
                                        {
                                            value: 'green',
                                            label: <Translate id="color.c_green"/>
                                        },
                                        {
                                            value: 'brown',
                                            label: <Translate id="color.c_brown"/>
                                        },
                                        {
                                            value: 'gray',
                                            label: <Translate id="color.c_gray"/>
                                        },
                                        {
                                            value: 'other',
                                            label: <Translate id="color.c_other"/>
                                        }
                                    ]}
                                    value={this.props.eyeColorFilter}
                                    onChange={(val) => {
                                        this.props.setPropState({eyeColorFilter: val});
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={6}>
                    <Row>
                        <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                            <div className='pb-3'>
                                <div className="filter-label"><Translate
                                    id="modelSearch.skinTypeFilter"/></div>
                                <SelectPicker
                                    block
                                    id={'inputSkinType' + this.props.idCounter}
                                    placeholder={<Translate id="modelSearch.chooseFilter"/>}
                                    searchable={false}
                                    cleanable={false}
                                    data={[
                                        {
                                            value: '',
                                            label: <Translate
                                                id="modelSearch.chooseFilter"/>
                                        },
                                        {
                                            value: 'european',
                                            label: <Translate id="skintype.st_european"/>
                                        },
                                        {
                                            value: 'asian',
                                            label: <Translate id="skintype.st_asian"/>
                                        },
                                        {
                                            value: 'african',
                                            label: <Translate id="skintype.st_african"/>
                                        },
                                        {
                                            value: 'other',
                                            label: <Translate id="skintype.st_other"/>
                                        }
                                    ]}
                                    value={this.props.skinTypeFilter}
                                    onChange={(val) => {
                                        this.props.setPropState({skinTypeFilter: val});
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                            <div className='pb-3'>
                                <div className="filter-label"><Translate
                                    id="modelSearch.skinColorFilter"/></div>
                                <SelectPicker
                                    block
                                    id={'inputSkinColor' + this.props.idCounter}
                                    placeholder={<Translate id="modelSearch.chooseFilter"/>}
                                    searchable={false}
                                    cleanable={false}
                                    data={[
                                        {
                                            value: '',
                                            label: <Translate
                                                id="modelSearch.chooseFilter"/>
                                        },
                                        {
                                            value: 'light',
                                            label: <Translate id="skincolor.sc_light"/>
                                        },
                                        {
                                            value: 'middle',
                                            label: <Translate id="skincolor.sc_middle"/>
                                        },
                                        {
                                            value: 'dark',
                                            label: <Translate id="skincolor.sc_dark"/>
                                        },
                                        {
                                            value: 'other',
                                            label: <Translate id="skincolor.sc_other"/>
                                        }
                                    ]}
                                    value={this.props.skinColorFilter}
                                    onChange={(val) => {
                                        this.props.setPropState({skinColorFilter: val});
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                {/*<Col lg={6} className="d-flex justify-content-end w-100 mb-3">*/}
                {/*    <Button variant="secondary" className="small-btn" onClick={this.clearFilter}>*/}
                {/*        <Icofont icon="close" className="mr-2"/>*/}
                {/*        <Translate id="modelSearch.clearFilter"/>*/}
                {/*    </Button>*/}
                {/*</Col>*/}
            </>

        )
    }
}
