import React, {Component} from "react";
import {Badge, Col, Form, Row} from "react-bootstrap";
import Icofont from "../../components/Icofont/Icofont";
import './ModelDataField.style.scss'
import {AnimatePresence, motion} from "framer-motion";
import {UnitConvertService} from "../../services/UnitConvertionService";

export default class ModelDataField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCommentBox: this.props.showCommentBox ?? false,
            message: this.props.message ?? '',
            value: this.props.value ?? '',
            gender: this.props.gender ?? 'male',
            showSaved: false
        }
        this.handleChangeMessage = this.handleChangeMessage.bind(this)
        this.handleSaveMessage = this.handleSaveMessage.bind(this)
        this.handleDeleteMessage = this.handleDeleteMessage.bind(this)
        this.convertToImperial = this.convertToImperial.bind(this)
    }

    handleChangeMessage(e) {
        let val = e.target.value;
        this.setState({message: val, showSaved: true}, () => this.handleSaveMessage())
    }

    handleSaveMessage() {
        setTimeout(() => {
            this.setState({showSaved: false});
        }, 2000)
        if (typeof this.props.handleCommentChange === "function") {
            this.props.handleCommentChange(
                this.props.commentType,
                this.state.message,
                true,
                this.props.sortOrder ?? 0,
                this.props.labelString ?? '',
            )
        }
    }

    handleDeleteMessage() {
        if (typeof this.props.handleCommentChange === "function") {
            this.props.handleCommentChange(this.props.commentType, "", false)
        }

        this.setState({
            message: '',
            showCommentBox: false
        })

    }

    componentDidMount() {
        if (this.props.unit && this.props.value) {
            let newVal = this.props.value;
            if(this.props.unitType === "Shoe"){
                newVal = UnitConvertService.convertShoeSize(this.props.value,"EU",this.props.unit,this.state.gender)
            }else{
                newVal = this.convertToImperial(this.props.value, this.props.unit);
            }
            this.setState({
                value: newVal
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.unit !== this.props.unit) {
            let newVal = this.props.value
            if(this.props.unitType === "Shoe"){
                newVal = UnitConvertService.convertShoeSize(this.state.value,prevProps.unit,this.props.unit,this.state.gender)
            }else{
                newVal = this.convertToImperial(this.props.value, this.props.unit);
            }
            this.setState({
                value: newVal
            })
        }
    }

    convertToImperial(val, convertToType) {
        if (convertToType === 'inch') {
            return (val / 2.54).toFixed(2);
        }
        if (convertToType === 'lbs') {
            return (val * 2.205).toFixed(2);
        }
        if (convertToType === 'mi') {
            return (val / 1.60934).toFixed(2);
        }
        return val;
    }

    render() {
        return (
            <Row className="py-2">
                <Col xs={6} lg={this.props.lgTop ?? 4}><b>{this.props.label}:</b></Col>
                <Col xs={6} lg={this.props.lgBottom ?? 8}>{this.state.value} {this.props.unit}</Col>
                {this.props.showAdminCommentBox ?
                    <Col xs={12}>
                        {this.state.showCommentBox ?
                            <Row className="py-2 admin-comment">
                                <Col xs={9}>
                                    <Form.Control as="textarea" rows="4"
                                                  id={"input" + this.props.commentType}
                                                  placeholder="Beschreiben Sie was das Model an der Eingabe ändern soll"
                                                  onChange={this.handleChangeMessage}
                                                  value={this.state.message}
                                                  className='input-foreground'
                                    />

                                </Col>
                                <Col xs={3} className="d-flex justify-content-end">
                                    <Badge
                                        variant="danger"
                                        className="ml-1 cursor-pointer p-2 align-self-end"
                                        onClick={this.handleDeleteMessage}>
                                        <Icofont icon='trash' size={2}/>
                                    </Badge>
                                </Col>
                                <AnimatePresence>
                                    {this.state.showSaved ?
                                        <motion.div
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            transition={{duration: 0.4}}
                                        >
                                            <Col xs={12}>
                                                <div className="d-flex justify-content-center mt-2 text-success">
                                                    <Icofont icon="check-circled" className="mr-2"/>
                                                    <div style={{lineHeight: '1em'}}>Gespeichert</div>
                                                </div>
                                            </Col>
                                        </motion.div>

                                        :
                                        null
                                    }
                                </AnimatePresence>
                            </Row>

                            :
                            <small
                                className="cursor-pointer text-danger"
                                onClick={() => this.setState({showCommentBox: !this.state.showCommentBox})}
                            >
                                Änderung vorschlagen <Icofont className="ml-2" icon='edit'/>
                            </small>
                        }
                    </Col>
                    :
                    null
                }
            </Row>
        )
    }
}
