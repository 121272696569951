import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Wrapper from "./Wrapper";
import {AnimatePresence} from "framer-motion";
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import createStore from '../../stores'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import {LocalizeProvider} from "react-localize-redux";
import LocalizeConsumer from "../../components/LocalizeConsumer/LocalizeConsumer";
import {Helmet} from "react-helmet";

const {store, persistor} = createStore();
export {store}

export default class Root extends Component {


    render() {
        return (
            <Provider store={store}>
                <LocalizeProvider store={store}>
                    <LocalizeConsumer>
                        <PersistGate persistor={persistor}>
                            <div style={{position: "relative"}}>
                                <AnimatePresence>
                                    <Router>
                                        <Route path="/" component={Wrapper}/>
                                    </Router>
                                </AnimatePresence>
                            </div>
                        </PersistGate>
                    </LocalizeConsumer>
                </LocalizeProvider>
            </Provider>
        )
    }
}
