/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import {createReducer} from 'reduxsauce';
import {INITIAL_STATE} from './initialState';
import {UserActionTypes} from './actions';

export const login = (state, {authKey, validUntil, memberID}) => {
    return {
        ...state,
        isLoggedIn: true,
        memberID: memberID,
        authKey: authKey,
        keyValidUntil: validUntil,
    };
};

export const logout = (state) => {
    return {
        ...state,
        isLoggedIn: false,
        memberID: 0,
        authKey: null,
        user: {},
        keyValidUntil: null,
    };
};

export const fetchUserLoading = (state) => ({
    ...state,
    userIsLoading: true,
    userErrorMessage: null,
});

export const fetchUserSuccess = (state, {user}) => ({
    ...state,
    user: user,
    userIsLoading: false,
    userErrorMessage: null,
});

export const fetchUserFailure = (state, {errorMessage}) => ({
    ...state,
    user: {},
    userIsLoading: false,
    userErrorMessage: errorMessage,
});


/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
    [UserActionTypes.LOGIN]: login,
    [UserActionTypes.LOGOUT]: logout,
    [UserActionTypes.FETCH_USER_LOADING]: fetchUserLoading,
    [UserActionTypes.FETCH_USER_SUCCESS]: fetchUserSuccess,
    [UserActionTypes.FETCH_USER_FAILURE]: fetchUserFailure,
});
