import React, {Component} from "react";
import LazyImage from "../../components/LazyImage/LazyImage";
import {Badge, Col, Form, Row} from "react-bootstrap";
import Icofont from "../../components/Icofont/Icofont";
import CropField from "../../components/CropField/CropField";
import './ApproveImageField.style.js.scss'
import {AnimatePresence, motion} from "framer-motion";

class ApproveImageField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: this.props.image ?? {},
            imgAbsoluteURL: '',
            oldFileID: 0,
            topModelImgID: this.props.topModelImageID ?? 0,
            showCropField: false,
            xs: this.props.xs ?? 6,
            md: this.props.md ?? 3,
            showCommentBox: this.props.showCommentBox ?? false,
            message: this.props.message ?? '',
            showSaved: false
        }
        this.updateFileList = this.updateFileList.bind(this)
        this.handleChangeMessage = this.handleChangeMessage.bind(this)
        this.handleSaveMessage = this.handleSaveMessage.bind(this)
        this.handleDeleteMessage = this.handleDeleteMessage.bind(this)
        this.toggleCommentBox = this.toggleCommentBox.bind(this)
        this.handleDeleteImage = this.handleDeleteImage.bind(this)
    }

    updateFileList(file) {
        let image = this.state.image;
        image.ImageURL = file.ImageURL;
        this.setState({
            image: image
        })
    }

    handleChangeMessage(e) {
        let val = e.target.value;
        this.setState({message: val, showSaved: true}, () => this.handleSaveMessage())
    }

    handleSaveMessage() {
        setTimeout(() => {
            this.setState({showSaved: false});
        }, 2000)
        if (typeof this.props.handleCommentChange === "function") {
            this.props.handleCommentChange(
                this.props.commentType,
                this.state.message,
                true,
                this.props.sortOrder ?? 0,
                this.props.labelString ?? '0',
            )
        }
    }

    handleDeleteMessage() {
        if (typeof this.props.handleCommentChange === "function") {
            this.props.handleCommentChange(this.props.commentType, "", false)
        }

        this.setState({message: '', showCommentBox: false})
    }

    toggleCommentBox() {
        if (this.state.showCommentBox) {
            this.setState({
                showCommentBox: this.props.showCommentBox ?? false,
            })
        } else {
            this.setState({
                showCommentBox: true,
            })
        }

    }

    handleDeleteImage() {
        if (typeof this.props.handleDeleteImage === "function") {
            this.props.handleDeleteImage(this.state.image.ID)
        }
    }

    render() {
        return (
            <Col xs={12} lg={this.props.size ?? 6} className={this.props.className}>
                <Row>
                    <Col xs={12} lg={12}>
                        <div className='approve-image shadow'>
                            <LazyImage fluid src={this.state.image.ImageURL ?? this.state.image.AbsoluteURL}/>
                            {typeof this.state.topModelImgID !== 'undefined' ?
                                <>
                                    <div className="delete-button"
                                         onClick={() => this.handleDeleteImage()}>
                                        <Icofont icon='trash'/>
                                    </div>
                                <div className="edit-button"
                                     onClick={() => this.setState({
                                         showCropField: true,
                                         imgAbsoluteURL: this.state.image.MainImage.AbsoluteURL,
                                         topModelImgID: this.state.image.ID,
                                         oldFileID: this.state.image.ID,
                                     })}>
                                    <Icofont icon='edit'/>
                                </div>
                                </>
                                :
                                null
                            }
                            {this.state.showCommentBox ?
                                null
                                :
                                <small
                                    className="cursor-pointer text-white p-2 d-block text-center bg-danger"
                                    onClick={this.toggleCommentBox}
                                >
                                    Änderung vorschlagen <Icofont className="ml-2" icon='edit'/>
                                </small>
                            }


                        </div>
                    </Col>
                    {this.state.showCommentBox ?
                        <Col xs={12} lg={12}>
                            <Row className="py-2 mt-3 admin-comment">
                                <Col xs={9}>
                                    <Form.Control as="textarea" rows="4"
                                                  id={"input" + this.props.commentType}
                                                  placeholder="Beschreiben Sie was das Model an der Eingabe ändern soll"
                                                  onChange={this.handleChangeMessage}
                                                  value={this.state.message}
                                                  className='input-foreground'
                                    />
                                </Col>
                                <Col xs={3} className="d-flex justify-content-end">
                                    <Badge
                                        variant="danger"
                                        className="ml-1 cursor-pointer p-2 align-self-end"
                                        onClick={this.handleDeleteMessage}>
                                        <Icofont icon='trash' size={2}/>
                                    </Badge>
                                </Col>
                                <AnimatePresence>
                                    {this.state.showSaved ?
                                        <motion.div
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            transition={{duration: 0.4}}
                                        >
                                            <Col xs={12}>
                                                <div className="d-flex justify-content-center mt-2 text-success">
                                                    <Icofont icon="check-circled" className="mr-2"/>
                                                    <div style={{lineHeight: '1em'}}>Gespeichert</div>
                                                </div>
                                            </Col>
                                        </motion.div>

                                        :
                                        null
                                    }
                                </AnimatePresence>
                            </Row>
                        </Col>
                        :
                        null
                    }
                </Row>
                {this.state.showCropField ?
                    <div className='crop-holder'>
                        <div className='crop-bg'>
                            <div className='close-crop'>
                                <Icofont icon='close-line' onClick={() => this.setState({
                                    showCropField: false,
                                    imgAbsoluteURL: '',
                                    topModelImgID: 0,
                                    oldFileID: 0,
                                })}/>
                            </div>
                            <CropField
                                oldID={this.state.oldFileID}
                                imgURL={this.state.imgAbsoluteURL}
                                topModelImgID={this.state.topModelImgID}
                                updateFileList={this.updateFileList}
                                closeFunction={() => this.setState({
                                    showCropField: false,
                                    imgAbsoluteURL: '',
                                    topModelImgID: 0,
                                    oldFileID: 0,
                                })}
                            />
                        </div>
                    </div>
                    :
                    null
                }
            </Col>

        )
    }

}

export default ApproveImageField
