import React, {Component} from "react";
import {Alert, Button, Col, Row} from "react-bootstrap";
import LazyImage from "../LazyImage/LazyImage";
import {Link} from "react-router-dom";
import './ModelList.style.scss'
import {Translate} from "react-localize-redux";


export default class ModelList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            models: this.props.models ?? [],
            showApproveLink: this.props.showApproveLink ?? false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props.models) !== JSON.stringify(this.state.models)) {
            this.setState({
                models: this.props.models
            })
        }
    }


    render() {
        return (
            this.state.models.length ?
                this.state.models.map((model, index) => {
                    return (
                        <div key={index} className='model-list-item d-flex align-items-center justify-content-between'>
                            <Row className="w-100">
                                <Col xs={6} lg={2}>
                                    {model.PreviewImage ?
                                        <LazyImage src={model.PreviewImage} className='img-fluid'/>
                                        :
                                        <LazyImage
                                            src={"https://gravatar.com/avatar/638947efd6e9c7ea8dbe14e8eb2bc8ff?s=400&d=mp&r=x"}
                                            className='img-fluid'/>
                                    }
                                </Col>
                                <Col xs={6} lg={7} className="d-flex justify-content-center flex-column">
                                    {this.state.showApproveLink ?
                                        <small className="text-muted">{model.SendToApprovalDate}</small>
                                        : null
                                    }
                                    {this.state.showApproveLink ?
                                        model.HasChangedSinceFeedBack ?
                                            <Alert variant="info" style={{padding:"10px",marginTop:"10px"}}><p style={{fontSize:"12px",lineHeight:1,margin:0}}>Änderungen seit letztem Feedback</p></Alert>
                                            :
                                            model.AlreadyGotFeedBack ?
                                                <Alert variant="success" style={{padding:"10px",marginTop:"10px"}}><p style={{fontSize:"12px",lineHeight:1,margin:0}}>Bereits gesendet</p></Alert>
                                                :
                                                null
                                        :
                                        null
                                    }
                                    <div className='model-name'>
                                        {model.FirstName ?? ''} {model.Surname ?? ''}
                                    </div>
                                    <div>
                                        {model.BookingCode}
                                    </div>
                                </Col>
                                <Col xs={12} lg={3}
                                     className='d-flex flex-column justify-content-center mt-3 mt-lg-0'
                                >
                                    <Button
                                        variant="secondary"
                                        as={Link}
                                        to={"/model/" + model.ID}
                                        className="mb-3"
                                    >
                                        <Translate id="modelList.showProfile"/>
                                    </Button>
                                    {this.state.showApproveLink ?
                                        <Button
                                            variant="danger"
                                            as={Link} to={"/modelapproval/" + model.ID}
                                        >
                                            <Translate id="modelList.verifyProfile"/>
                                        </Button>
                                        :
                                        null
                                    }

                                </Col>
                            </Row>
                        </div>
                    )
                })
                :
                <p><Translate id="modelList.noModels"/></p>

        )
    }


}
