import React, {Component} from "react";
import {Container} from "react-bootstrap";
import {Translate} from "react-localize-redux";

export default class PaymentComplete extends Component {
    render() {
        return (
            <Container>
                <div className="full-height-center d-flex align-items-center">
                    <section className="w-100">
                        <h3><Translate id="payment.completeTitle"/></h3>
                        <p><Translate id="payment.completeDesc"/></p>
                    </section>
                </div>
            </Container>
        )
    }
}
