import React, {Component} from "react";
import "./LanguageSwitcher.style.scss"

import {withLocalize} from "react-localize-redux";
import {Dropdown, DropdownButton} from "react-bootstrap";
import Icofont from "../Icofont/Icofont";
import LocaleActions from "../../stores/locale/actions";
import {connect} from "react-redux";
import {ApiService} from "../../services/ApiService";
import UserActions from "../../stores/user/actions";

class LanguageToggle extends Component {
    constructor(props) {
        super(props);

        this.switchLanguage = this.switchLanguage.bind(this)
    }

    switchLanguage(langCode) {
        let id = this.props.memberID;
        if (!isNaN(id) && id) {
            ApiService.authorizedClient(this.props.authKey).put(
                'Member/' + id,
                {Locale: langCode}
            ).then(
                () => {
                    this.props.setSetByUser(true);
                    this.props.setLocale(langCode);
                    this.props.fetchUser();
                },
                (error) => {
                    console.log(error)
                    this.props.setSetByUser(true);
                    this.props.setLocale(langCode);
                }
            );
        } else {
            this.props.setSetByUser(true);
            this.props.setLocale(langCode);
        }
    }

    render() {
        return (
            <DropdownButton
                id="langauge"
                title={
                    <>
                        <Icofont icon='globe'/>
                        <div
                            className="active-language-title d-inline-block ml-2">
                            {typeof this.props.activeLanguage !== "undefined" ? this.props.activeLanguage.name : ''}
                        </div>
                    </>
                }
                className="selector language-select">
                {this.props.languages.map(lang => (
                    <Dropdown.Item key={lang.code} onClick={() => {
                        this.switchLanguage(lang.code);
                    }}>
                        {lang.fullName}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        );
    }

}


const mapStateToProps = (state) => ({
    authKey: state.user.authKey,
    memberID: state.user.memberID,
});
const mapDispatchToProps = (dispatch) => ({
    setLocale: (localeCode) => dispatch(LocaleActions.setLocale(localeCode)),
    setSetByUser: (val) => dispatch(LocaleActions.setSetByUser(val)),
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withLocalize(LanguageToggle));
